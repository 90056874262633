import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import "components/DealersLocationsDropdown/DealersLocationsDropdown.scss";
import { DEALER_PREFERENCE_KEY, LOCATION_PREFERENCE_KEY, useDealersLocations, useUser } from "hooks";
import { ROLES } from "models";
import { isAdmin, setPreference } from "util/common";
import { ITranslation } from "util/interfaces";

export const DealersLocationsDropdown = (): ReactElement => {
  const { selectedDealer, selectedLocation, locationMutation, selectDealer, dealers, locationDropdownOptions } = useDealersLocations();

  const t = useTranslation().t as ITranslation;
  const user = useUser();

  const dealerOptions = dealers
    ?.filter(d => d.active)
    .map(dealer => ({
      key: dealer.id,
      value: dealer.id,
      text: dealer.name
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const onChangeLocation = (_e: React.SyntheticEvent<HTMLElement>, { value }: any) => {
    locationMutation.mutate(value);
    setPreference(LOCATION_PREFERENCE_KEY, value);
  };

  const onChangeDealer = (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: any) => {
    selectDealer(value);
    setPreference(DEALER_PREFERENCE_KEY, value);
  };

  const isDealerDropdownVisible = user ? [ROLES.Consultant, ROLES.Supervisor, ROLES.WarrantyManager].includes(user.role_id) || isAdmin(user.role_id) : false;

  return (
    <div className="DealerLocationDropdown-wrapper">
      <Dropdown
        button
        className={`LocationDropdown icon -scrollable-dropdown nav-bar-map`}
        floating
        labeled
        icon="location dot regular"
        noResultsMessage={t("v8_no_locations").message || "No locations"}
        placeholder={t("v8_select_location").message || "Select a location"}
        options={locationDropdownOptions}
        value={selectedLocation?.id}
        loading={locationMutation.isPending}
        multiple={false}
        upward={false}
        fluid={false}
        scrolling
        search
        onChange={onChangeLocation}
      />

      {isDealerDropdownVisible && (
        <Dropdown
          button
          className={`DealersLocationsDropdown icon -scrollable-dropdown nav-bar-map`}
          floating
          labeled
          icon="house regular"
          noResultsMessage={t("v8_no_dealers").message || "No dealers"}
          placeholder={t("v8_select_dealer").message || "Select a dealer"}
          options={dealerOptions}
          value={selectedDealer?.id}
          multiple={false}
          upward={false}
          fluid={false}
          scrolling
          search
          onChange={onChangeDealer}
        />
      )}
    </div>
  );
};

import ApiLoadable from "models/ApiLoadable";
import { CHECKLIST_TYPE } from "models/Checklist";

export enum STATUS_IDENTIFIER {
  // CanceledStatus is assigned when a NewCarStatus
  // appointment has been canceled
  CanceledStatus = -1,

  // NewCarStatus represents a status car gets
  // upon being created or imported to claire (default status)
  NewCarStatus = 1,

  // CarCheckStatus is assigned once check of
  // any kind is performed on the appointment car
  CarCheckStatus,

  // PricingOKStatus is assigned when pricing calculation is done
  PricingOKStatus,

  // CustomerOKStatus is assigned when customer approves
  // on suggested fixes
  CustomerOKStatus,

  // CarReadyStatus is assigned once the car is marked
  // as ready (fixed) by mechanic
  CarReadyStatus,

  // DiagnoseStatus is used by receptionists to require
  // an extra check from mechanics for some reason
  DiagnoseStatus,

  // InitialCheckDone is assigned after an initial check
  // has been done by receptionist
  AannameOKStatus,

  // CalculatingStatus is assigned when receptionists are
  // doing pricing calculations for found items
  CalculatingStatus,

  // ContactStatus is assigned to denote that the customer is being contacted
  ContactStatus,

  // CarCheckStartedStatus denotes that the check on the
  // appointment car has begun
  CarCheckStartedStatus,

  // QualityCheckStatus is assigned after quality control
  // check has been finished
  QualityCheckStatus,

  // CustomerAnsweredStatus is assigned to denote that the customer answered
  CustomerAnsweredStatus,

  BackOrderStatus,

  // CheckInCar is fake status, represents that checkIn is need to be done on this appointment by receptionist
  CheckInCar = 50,

  CheckInDone,

  CarInShop,

  CarOkPlusRepairOverview,

  QualityCheckPlusRepairOverview,

  CarOutOfShop
}

export enum STATUS_OVERRIDE {
  None,
  Canceled,
  Restored
}

export class AppointmentStatus extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  identifier: STATUS_IDENTIFIER = STATUS_IDENTIFIER.NewCarStatus;
  name: string = "";
  icon: string = "";
  color: string = "";
  order: number = 0;
  dealer_location_id: number = 0;
}

export class AppointmentStatusHistory extends ApiLoadable {
  id: number = 0;
  sa_remarks: string = "";
  timestamp: string = "";
  identifier: STATUS_IDENTIFIER = STATUS_IDENTIFIER.NewCarStatus;
  appointment_status_id: number = 0;
  appointment_id: number = 0;
  user_id: number = 0;
  last_user: string = "";
  date_time?: string;
  checklist_type: CHECKLIST_TYPE | null = null;
  checklist_name: string | null = null;
  is_dms: boolean = false;
}

export class DefaultAppointmentStatus {
  id: number = 0;
  color: string = "";
  icon: string = "";
  name: string = "";
  order: number = 0;
  identifier: number = 0;
}

import { useEffect, useState } from "react";
import ReactTextareaAutosize from "react-textarea-autosize";

import "components/TextareaEditable/TextareaEditable.scss";

type TextareaEditableProps = {
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  onSubmit: (t: string) => void;
};
export const TextareaEditable = ({ value = "", disabled, placeholder = "", onSubmit }: TextareaEditableProps) => {
  const [newValue, setNewValue] = useState<string>(value);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => setNewValue(value), [value]);

  const handleSave = () => {
    setIsEditing(false);
    if (newValue !== value) onSubmit(newValue);
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewValue(e.target.value);
  };

  const handleTextareaClick = () => {
    if (!disabled) setIsEditing(true);
  };

  return (
    <div className={`TextareaEditable ${!isEditing ? "showAsInfo" : ""} ${disabled ? "disabled" : ""} ${newValue ? "" : "placeholder"}`} onClick={handleTextareaClick}>
      <ReactTextareaAutosize value={newValue} disabled={disabled} rows={1} placeholder={placeholder} onChange={handleTextareaChange} onBlur={handleSave} />
    </div>
  );
};

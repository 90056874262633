import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { DeskCommunicationEvent } from "models";
import { DESK_COMMUNICATION_EVENTS } from "modules/AppointmentDetails/components/DeskCommunicationLog";
import { ITranslation } from "util/interfaces";

type SignatureProps = {
  events: DeskCommunicationEvent[];
};

export const Signature = ({ events }: SignatureProps) => {
  const event = events.find(e => (e.type as unknown as DESK_COMMUNICATION_EVENTS) === DESK_COMMUNICATION_EVENTS.CheckInAnswered);
  const t = useTranslation().t as ITranslation;

  if (!event?.customer_signature) return null;

  return (
    <div className="desk-comm-section-container">
      <div className="desk-comm-section-header">{t("v8_signature").message || "Signature"}</div>

      <Grid>
        {event.customer_name && (
          <Grid.Row>
            <Grid.Column width={16}>
              <span>
                <FontAwesomeIcon icon={faUser} className="i-green mr-5" />
                {event.customer_name}
              </span>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column width={4} className="signature-img-container">
            <img src={event.customer_signature} className="signature-img" alt="signature" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

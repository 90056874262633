import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { Appointment, DMS } from "models";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import ApiInstance from "util/Api";

// TODO: cleanup like other hooks
export const useUpdateAppointment = (appointment_id: number) => {
  const queryClient = useQueryClient();
  const { selectedLocation } = useDealersLocations();

  const appointmentDetailsViewKey = AppointmentDetailsKeys.view(appointment_id);

  const updateAppointment = (data: Partial<Appointment>) => {
    const AppointmentSnapshot = queryClient.getQueryData(appointmentDetailsViewKey);
    if (AppointmentSnapshot) {
      const updatedData = { ...(AppointmentSnapshot as Appointment), ...data };
      queryClient.setQueryData(appointmentDetailsViewKey, updatedData);
    }
    return { AppointmentSnapshot };
  };
  const handleError = (e: any, _variables: Partial<Appointment>, context: any) => {
    toast.error(e.message);
    if (context?.AppointmentSnapshot && context?.appointmentDetailsViewKey) {
      queryClient.setQueryData(appointmentDetailsViewKey, context.AppointmentSnapshot);
    }
  };

  const appointmentKeyloopDateMutation = useMutation({
    mutationFn: (data: Partial<Appointment>) => {
      const payload = { appointment_id, scheduled_in: data.time_car_app, scheduled_out: data.car_return_time };
      return ApiInstance.post("/appointments/update_schedule", payload, ENV.keyloopBaseURL);
    },
    onMutate: updateAppointment,
    onError: handleError
  });

  const appointmentUpdateDatesMutation = useMutation({
    mutationFn: (data: Partial<Appointment>) => ApiInstance.post("/appointments/update_dates", { appointment_id, ...data }, ENV.appointmentBaseURL),
    onMutate: updateAppointment,
    onError: handleError
  });

  const appointmentUpdateMileagesMutation = useMutation({
    mutationFn: (data: Partial<Appointment>) => ApiInstance.post("/appointments/update_mileages", { appointment_id, ...data }, ENV.appointmentBaseURL),
    onMutate: updateAppointment,
    onError: handleError
  });

  const appointmentCarInShopMutation = useMutation({
    mutationFn: (data: Partial<Appointment>) => ApiInstance.post("/appointments/set_car_in_shop", { appointment_id, ...data }, ENV.appointmentBaseURL),
    onMutate: updateAppointment,
    onError: handleError
  });

  const appointmentCarOutOfShopMutation = useMutation({
    mutationFn: (data: Partial<Appointment>) => ApiInstance.post("/appointments/set_car_out_of_shop", { appointment_id, ...data }, ENV.appointmentBaseURL),
    onMutate: updateAppointment,
    onError: handleError
  });

  const handleDMSRefresh = (data: Appointment) => {
    if (selectedLocation?.dms_id === DMS.KeyLoopJobs || selectedLocation?.dms_id === DMS.KeyLoopMenus) {
      return ApiInstance.post("/appointments/refresh", { appointment_id: data.id }, ENV.keyloopBaseURL);
    }
    if (selectedLocation?.dms_id === DMS.AutoFlex) {
      return ApiInstance.post("/appointments/refresh", { appointment_id: data.id }, ENV.autoflexBaseUrl);
    }
    throw new Error("Invalid DMS id");
  };

  const dmsRefreshMutation = useMutation({
    mutationFn: handleDMSRefresh,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointmentDetailsViewKey });
    },
    onError: e => {
      toast.error(e.message);
    }
  });

  return {
    dmsRefreshMutation,
    appointmentKeyloopDateMutation,
    appointmentUpdateDatesMutation,
    appointmentUpdateMileagesMutation,
    appointmentCarInShopMutation,
    appointmentCarOutOfShopMutation
  };
};

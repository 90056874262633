import { useQuery } from "@tanstack/react-query";

import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import ApiInstance from "util/Api";

export const useAppointmentCancellationReasons = () => {
  const getAppointmentCancellationReasons = async () => (await ApiInstance.get("/appointments/cancel_reasons")).data;

  return useQuery({ queryKey: AppointmentDetailsKeys.cancellationReasons, queryFn: getAppointmentCancellationReasons });
};

import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { WebSocketComponent } from "components";
import { useWebSocketStatus } from "components/WebSocket/useWebSocketStatus";
import { useAuth, useDealersLocations, useUser } from "hooks";
import { DealerLocation } from "models";
import Service from "modules/Auth/service";
import ZohoDeskASAP from "plugins/ZohoDeskASAP";
import ApiInstance from "util/Api";

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import packageJson from "../../package.json";

export const useApp = () => {
  const { logout } = useAuth();
  const user = useUser();
  const history = useHistory();

  const { dealerMutation, selectedLocation } = useDealersLocations();
  const isConnected = useWebSocketStatus();

  const checkUser = async () => {
    if (ApiInstance.authToken) {
      const user = await Service.getUser();
      if (!user) {
        logout();
        return;
      }

      ZohoDeskASAP.login();
    }
  };

  useEffect(() => {
    document.title = `Claire V${packageJson.version}`;
    ZohoDeskASAP.setZohoDeskASAP();
    checkUser();
  }, []);

  useEffect(() => {
    ApiInstance.setHistory(history);
  }, [history]);

  useEffect(() => {
    if (user) {
      dealerMutation.mutate();
    }
  }, [user]);

  useEffect(() => {
    if (!selectedLocation || !isConnected) return;
    WebSocketComponent.subscribeToLocationQueue((selectedLocation as DealerLocation).notifier_key);

    return () => {
      if (isConnected) {
        WebSocketComponent.unsubscribeFromLocationQueue((selectedLocation as DealerLocation).notifier_key);
      }
    };
  }, [selectedLocation, isConnected]);
};

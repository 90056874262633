import moment from "moment";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { Label, Loader } from "semantic-ui-react";

import { AppointmentSearchOption, AppointmentStatusCell, SEARCH_OPTION_TYPE } from "components";
import "components/NavBarSearchAppointments/SearchDropdown/SearchDropdown.scss";
import useSearchDropdown from "components/NavBarSearchAppointments/SearchDropdown/useSearchDropdown";
import { ITranslation } from "util/interfaces";

type SearchDropdownProp = {
  data: AppointmentSearchOption[];
  onSelect: (id: string) => void;
  onClose: () => void;
  t: ITranslation;
};

const SearchDropdown = ({ data = [], onSelect, onClose, t }: SearchDropdownProp) => {
  const { dropdownRef, importAppointment, isPending } = useSearchDropdown({ onClose, onSelect });
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleOnClick = (id: string, type: SEARCH_OPTION_TYPE) => {
    if (type === SEARCH_OPTION_TYPE.Claire) onSelect(id);
    else {
      setSelectedId(id);
      importAppointment(id);
    }
  };

  return (
    <div className="SearchDropdown-wrapper" ref={dropdownRef}>
      {data.length > 0 ? (
        <>
          {data.map(item => {
            const date = item.due_in ? `${moment(item.due_in).format("DD-MM-YYYY")} | ` : "";
            const woNr = item.wo_nr ? `${item.wo_nr} | ` : "";
            const regNr = item.reg_number ? `${item.reg_number} | ` : "";

            return (
              <div key={item.id} className="search-list" onClick={() => handleOnClick(item.id, item.type)}>
                {item.type !== SEARCH_OPTION_TYPE.Claire && (
                  <Label>
                    <span>{item.type === SEARCH_OPTION_TYPE.Keyloop ? "Keyloop" : "Nextlane"}</span>
                  </Label>
                )}

                <p className="search-title">{`${item.car_make} ${item.car_model}`.trim()}</p>

                <div className="search-row">
                  <p>{`${date}${woNr}${regNr} ${item.dealer_location?.name || ""}`}</p>
                  {isPending && selectedId === item.id ? (
                    <Loader active={isPending} inline size="small" />
                  ) : (
                    <AppointmentStatusCell
                      value={{
                        id: item.appointment_status_identifier,
                        customcom_status: item.customcom_status,
                        check_paused_at: item.check_paused_at,
                        car_out_of_shop: item.car_out_of_shop
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="no-result">
          <p>{t("v8_no_results_found").message || "No results found"}</p>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(SearchDropdown);

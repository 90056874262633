import { Appointment, Car, CommunicationAgreement, Customer, KeylockerRemark, User } from "models";
import ApiLoadable from "models/ApiLoadable";

export enum KEYLOCKER_COMMUNICATION_STATUS {
  KeylockerDropPinSent = 1,
  KeylockerCheckInOpened,
  KeylockerCheckInAnswered,
  KeylockerPickUpPinSent,
  KeylockerCheckInClosed
}

export enum KEYLOCKER_COMMUNICATION_EVENT_TYPE {
  KeylockerDropPinSent = 1,
  KeylockerCheckInOpened,
  KeylockerCheckInAnswered,
  KeylockerPickUpPinSent,
  KeylockerCheckInClosed
}

export enum KEYLOCKER_PIN_REQUEST_TYPES {
  Phone = 1,
  RegistrationNumber,
  TwoFaDisabled,
  OnlineCheckIn
}

export class KeylockerCommunicationEvent extends ApiLoadable {
  id: number = 0;
  keylocker_communication_id: number = 0;
  box_number?: number | null = null;
  correct_phone: string = "";
  pin_request_type: number = 0;
  user_id: number | null = null;
  type: KEYLOCKER_COMMUNICATION_EVENT_TYPE = KEYLOCKER_COMMUNICATION_EVENT_TYPE.KeylockerDropPinSent;
  user?: User | null = null;
  created_on: string = "";
  updated_on: string = "";

  private _receivers?: KeyLockerCommunicationReceiver[] | null = null;

  get receivers(): KeyLockerCommunicationReceiver[] {
    return this._receivers ? this._receivers : [];
  }

  set receivers(results: KeyLockerCommunicationReceiver[] | null | undefined) {
    this._receivers = results;
  }
}

export class KeyLockerCommunicationReceiver extends ApiLoadable {
  id: number = 0;
  communication_channel_id: number = 0;
  destination: string = "";
  status: number = 0;
  reason: string = "";
  sms_gateway_id: number | null = null;
  twilio_message_sid: number | null = null;
  mailgun_message_id: string | null = null;
  chatshipper_conversation_id: number | null = null;
  keylocker_communication_id: number = 0;
}

export interface KeylockerIntervention {
  id: number;
  title: string;
  description: string;
  price: number;
  vat?: number;
  keylocker_communication_id: number;
}

export class KeylockerCommunication extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  status: KEYLOCKER_COMMUNICATION_STATUS = KEYLOCKER_COMMUNICATION_STATUS.KeylockerDropPinSent;
  is_checking_out: boolean = false;
  customer_id: number | null = null;
  appointment_id: number = 0;
  car_id: number = 0;
  dealer_location_id: number = 0;
  customer?: Customer | null = null;
  appointment?: Appointment | null = null;
  car?: Car;
  user?: User;
  remark?: KeylockerRemark;
  events?: KeylockerCommunicationEvent[];
  agreed_interventions?: KeylockerIntervention[];
  declined_interventions?: KeylockerIntervention[];
  agreements?: CommunicationAgreement[];
}

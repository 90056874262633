import { useQuery, useQueryClient } from "@tanstack/react-query";

import { queryKeys } from "util/keyFactory";

export const useConnectedInterventionId = () => {
  const queryClient = useQueryClient();

  const { data: connectedInterventionId } = useQuery({
    queryKey: [queryKeys.appointments.connectedInterventionId],
    queryFn: () => null
  });

  const updateConnectedInterventionId = (id: number | null) => queryClient.setQueryData([queryKeys.appointments.connectedInterventionId], id);

  return { connectedInterventionId, updateConnectedInterventionId };
};

import ApiLoadable from "models/ApiLoadable";

export enum NOTE_TYPES {
  CallCustomer = 1,
  RemarkPin,
  WarrantyPin,
  RecallPin,
  Parking
}

export class NoteType extends ApiLoadable {
  note_type_id: NOTE_TYPES = NOTE_TYPES.CallCustomer;
  dealer_location_id: number = 0;
  active: boolean = false;
}

export class NoteSuggestion extends ApiLoadable {
  dealer_location_id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  note: string = "";
  order: number = 0;
  content: string = "";
  note_type_id: NOTE_TYPES = NOTE_TYPES.CallCustomer;
}

import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { KEYLOCKER_COMMUNICATION_EVENT_TYPE, KEYLOCKER_PIN_REQUEST_TYPES, KeylockerCommunication, KeylockerCommunicationEvent } from "models";
import { EventInfo } from "modules/AppointmentDetails/components/KeylockerCommunicationLog/components";
import { ITranslation } from "util/interfaces";

const getEventName = (event: KeylockerCommunicationEvent, t: ITranslation) => {
  switch (event.type as unknown as KEYLOCKER_COMMUNICATION_EVENT_TYPE) {
    case KEYLOCKER_COMMUNICATION_EVENT_TYPE.KeylockerDropPinSent:
      return t("v8_drop_pin_sent").message || "Drop pin sent";

    case KEYLOCKER_COMMUNICATION_EVENT_TYPE.KeylockerCheckInOpened:
      return t("v8_check_in_opened").message || "Check-in opened";

    case KEYLOCKER_COMMUNICATION_EVENT_TYPE.KeylockerCheckInAnswered:
      return t("v8_check_in_answered").message || "Check-in answered";

    case KEYLOCKER_COMMUNICATION_EVENT_TYPE.KeylockerPickUpPinSent:
      return t("v8_pick_up_pin_sent").message || "Pick up pin sent";

    default:
      return "";
  }
};

type CommunicationEventsProps = {
  comm: KeylockerCommunication;
  events?: KeylockerCommunicationEvent[];
};

export const CommunicationEvents = ({ events, comm }: CommunicationEventsProps) => {
  const t = useTranslation().t as ITranslation;

  if (!events?.length) return null;

  return (
    <div className="keylocker-comm-section-container">
      <div className="keylocker-comm-section-header">{t("v8_keylocker_communication_events").message || "Keylocker Communication Events"}</div>

      <Grid>
        {events.map((event, key) => {
          if (event.type === KEYLOCKER_COMMUNICATION_EVENT_TYPE.KeylockerDropPinSent && event.pin_request_type === KEYLOCKER_PIN_REQUEST_TYPES.TwoFaDisabled) return null;

          return (
            <Grid.Row key={key} className="section-rows">
              <Grid.Column width={12}>{getEventName(event, t)}</Grid.Column>

              <Grid.Column width={4} className="event-timestamp">
                {moment(event.created_on).format("HH:mm")} - {moment(event.created_on).format("DD-MM-YYYY")}
              </Grid.Column>

              <EventInfo comm={comm} event={event} />
            </Grid.Row>
          );
        })}
      </Grid>
    </div>
  );
};

import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, CheckboxProps, Dropdown, Form, Grid, GridColumn, Icon, Input, Modal, TextArea } from "semantic-ui-react";

import { PinLog, PinTypeIcon, useCan } from "components";
import "components/PinModal/PinModal.scss";
import { usePinOptions, useUser } from "hooks";
import { PIN_ORDER, PIN_STATUS, PIN_TYPE, PinModel, PinOptions } from "models";
import { PinType } from "modules/AppointmentDetails/components/Interventions/InterventionItem";
import { useIntervention } from "modules/AppointmentDetails/hooks";
import { ITranslation } from "util/interfaces";

interface PinModelWithHistory extends PinModel {
  history: PinModel[];
}

type PinProps = {
  pin: Partial<PinModelWithHistory>;
  isOpen: boolean;
  onClose: () => void;
};

export const getShopIconColor = (orderStatus: number) => {
  switch (orderStatus) {
    case PIN_ORDER.ToOrder:
      return "blue";
    case PIN_ORDER.SameDay:
      return "green";
    case PIN_ORDER.NextDay:
      return "orange";
    case PIN_ORDER.BackOrder:
      return "red";
    default:
      return "";
  }
};

export const PinModal = ({ pin, isOpen, onClose }: PinProps) => {
  if (pin.pin_status_id === PIN_STATUS.Deleted) return null;

  const t = useTranslation().t as ITranslation;
  const { addPinItem } = useIntervention(Number(pin.appointment_id));
  const user = useUser();
  const { data: pinOptions } = usePinOptions();

  const pinTypeData: PinType[] = pinOptions?.pin_types?.map((pinType: PinOptions) => {
    return { key: pinType.name, text: t(`${pinType.name}`).message, value: pinType.id, icon: <PinTypeIcon pin_type_id={pinType.id} /> };
  });

  const pinStatusData = pinOptions?.pin_statuses?.map((pinStatus: PinOptions) => {
    return { key: pinStatus.name, text: t(`${pinStatus.name}`).message, value: pinStatus.id };
  });

  const pinOrderData = pinOptions?.pin_order_statuses?.map((pinOrder: PinOptions) => {
    return {
      key: pinOrder.name,
      text: t(`${pinOrder.name}`).message,
      value: pinOrder.id,
      icon: <Icon className="shopping cart" color={getShopIconColor(pinOrder.id)} />
    };
  });

  const pinWarrantyData = pinOptions?.warranty_types?.map((pinWarranty: PinOptions) => {
    return { key: pinWarranty.name, text: t(`${pinWarranty.name}`).message, value: pinWarranty.id };
  });

  const [pinWarrantyID, setPinWarrantyID] = useState(pin?.warranty_type_id || null);
  const [supportNr, setSupportNr] = useState(pin?.support_nr || "");
  const [claimNr, setClaimNr] = useState(pin?.claim_nr || "");
  const [refNr, setRefNr] = useState(pin?.ref_nr || "");
  const [note, setNote] = useState("");
  const [visibleMechanic, setVisibleMechanic] = useState(pin?.visible_mechanic || false);
  const [keepParts, setKeepParts] = useState(pin?.keep_parts || false);

  const [status, setStatus] = useState(pin?.pin_status_id || null);
  const [orderStatus, setOrderStatus] = useState<number | null>(pin?.order_status || null);

  const [pinTypeID, setPinTypeID] = useState<PIN_TYPE>(pin.pin_type_id || 0);
  const canUpdatePin = useCan("pin", "update");

  const hasUpdated = () => {
    if (!canUpdatePin) return false;
    if (pin?.support_nr !== supportNr) return true;
    if (pin?.claim_nr !== claimNr) return true;
    if (pin?.ref_nr !== refNr) return true;
    if ((pin?.warranty_type_id || null) !== pinWarrantyID) return true;
    if ((pin?.pin_status_id || null) !== status) return true;
    if ((pin?.order_status || null) !== orderStatus) return true;
    if (note !== "") return true;
    if (pinTypeID !== pin.pin_type_id) return true;
    if (!supportNr && !claimNr && !refNr && !note) return false;
    return false;
  };

  const onDropdownChange = (name: string, value: string | number | boolean | (string | number | boolean)[] | undefined) => {
    switch (name) {
      case "warranty_type_id":
        setPinWarrantyID(Number(value));
        break;
      case "pin_status_id":
        setStatus(Number(value));
        break;
      case "order_status":
        setOrderStatus(Number(value));
        break;
      default:
        break;
    }
  };

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    switch (evt.target.name) {
      case "support_nr":
        setSupportNr(evt.target.value);
        break;
      case "claim_nr":
        setClaimNr(evt.target.value);
        break;
      case "ref_nr":
        setRefNr(evt.target.value);
        break;
      default:
        break;
    }
  };

  const onTextAreaChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    switch (evt.target.name) {
      case "note":
        setNote(evt.target.value);
        break;
      default:
        break;
    }
  };

  const onCheckboxChange = (_: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    switch (data.name) {
      case "visible_mechanic":
        setVisibleMechanic(!!data.checked);
        break;
      case "keep_parts":
        setKeepParts(!!data.checked);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (addPinItem.isSuccess) {
      setNote("");
    }
  }, [addPinItem.isSuccess]);

  const handleNewPinItem = async () => {
    const pindata: PinModel = {
      warranty_type_id: pinWarrantyID,
      support_nr: supportNr,
      pin_status_id: status,
      pin_type_id: pinTypeID,
      order_status: orderStatus,
      ref_nr: refNr,
      claim_nr: claimNr,
      intervention_id: pin?.intervention_id,
      appointment_id: pin?.appointment_id,
      user_id: user ? user.id : 0,
      question_result: null,
      question_result_id: pin?.question_result_id,
      note_attachments: [],
      note,
      keep_parts: keepParts,
      visible_mechanic: visibleMechanic
    };
    await addPinItem.mutate(pindata);
  };

  const handlePinType = (pinTypeID: PIN_TYPE) => {
    setPinTypeID(pinTypeID);
  };

  return (
    <Modal className="PinModal" size="large" open={isOpen} onClose={onClose}>
      <Modal.Header>
        <div className="modal-header">
          <p>
            <span>
              <Icon className="pin" />
            </span>
            {t("v8_pin_item").message || "Pin Item"}
          </p>

          <Button color="grey" className="-appointment-status">
            <Icon className="print black" />
            {t("v8_print_overview").message || "Print Overview"}
          </Button>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className="PinType">
          <Form>
            <Grid columns={2}>
              <GridColumn>
                <Form.Field className="label-dropdown-container" required>
                  <label>{t("v8_pin_type").message || "Pin Type"}</label>
                  <Dropdown
                    value={pinTypeID}
                    onChange={(_, { value }) => handlePinType(value as PIN_TYPE)}
                    name="pin_type_id"
                    placeholder={t("v8_select_pin_type").message || "Select pin type"}
                    fluid
                    selection
                    options={pinTypeData}
                    trigger={
                      pinTypeID ? (
                        <div>
                          <span>{pinTypeData?.find(orderData => orderData.value === pinTypeID)?.icon}</span>
                          <span className="orderStatusTitle">{pinTypeData?.find(orderData => orderData.value === pinTypeID)?.text}</span>
                        </div>
                      ) : null
                    }
                  />
                </Form.Field>
              </GridColumn>
              <GridColumn>
                <Form.Field>
                  <label>{t("v8_support").message || "Support"} #</label>
                  <Input value={supportNr} fluid name="support_nr" onChange={onInputChange} />
                </Form.Field>
              </GridColumn>
            </Grid>
            <Grid columns={2}>
              <GridColumn>
                <Form.Field>
                  <label>{t("v8_claim").message || "Claim"} #</label>
                  <Input value={claimNr} fluid name="claim_nr" onChange={onInputChange} />
                </Form.Field>
              </GridColumn>
              <GridColumn>
                <Form.Field>
                  <label>{t("v8_ref").message || "Ref"} #</label>
                  <Input value={refNr} fluid name="ref_nr" onChange={onInputChange} />
                </Form.Field>
              </GridColumn>
            </Grid>
            <Grid columns={2}>
              <GridColumn>
                <Form.Field>
                  <label>{t("v8_order_status").message || "Order status"}</label>
                  <Dropdown
                    value={Number(orderStatus)}
                    onChange={(_, { name, value }) => onDropdownChange(name, value)}
                    name="order_status"
                    placeholder={t("v8_select_order_status").message || "Select order status"}
                    fluid
                    selection
                    clearable={!!orderStatus}
                    options={pinOrderData}
                    trigger={
                      orderStatus ? (
                        <div>
                          <span>{pinOrderData?.find(orderData => orderData.value === orderStatus)?.icon}</span>
                          <span className="orderStatusTitle">{pinOrderData?.find(orderData => orderData.value === orderStatus)?.text}</span>
                        </div>
                      ) : null
                    }
                  />
                </Form.Field>
              </GridColumn>
              {pinTypeID === PIN_TYPE.Warranty && (
                <GridColumn>
                  <Form.Field>
                    <label>{t("v8_warranty_type").message || "Warranty type"}</label>
                    <Dropdown
                      value={pinWarrantyID || undefined}
                      onChange={(_, { name, value }) => onDropdownChange(name, value)}
                      name="warranty_type_id"
                      placeholder={t("v8_select_type").message || "Select type"}
                      fluid
                      selection
                      options={pinWarrantyData}
                    />
                  </Form.Field>
                </GridColumn>
              )}
            </Grid>

            <Grid columns={2}>
              {pinTypeID !== PIN_TYPE.Remarks && (
                <GridColumn>
                  <Form.Field>
                    <label>{t("v8_pin_status").message || "Pin Status"}</label>
                    <Dropdown
                      value={status || undefined}
                      onChange={(_, { name, value }) => onDropdownChange(name, value)}
                      name="pin_status_id"
                      placeholder={t("v8_select_status").message || "Select status"}
                      fluid
                      selection
                      options={pinStatusData}
                    />
                  </Form.Field>
                </GridColumn>
              )}
            </Grid>

            <Grid columns={1}>
              <GridColumn>
                <Form.Field>
                  <label>{t("v8_note").message || "Note"}</label>
                  <TextArea name="note" rows={2} placeholder={t("v8_note").message || "Note"} value={note} onChange={onTextAreaChange} />
                </Form.Field>
                <Form.Field>
                  <div className="items-center">
                    <div>
                      <span>
                        <Checkbox toggle disabled={!note} name="visible_mechanic" onChange={onCheckboxChange} checked={visibleMechanic} />
                      </span>
                      {t("v8_note_visible_for_mechanic").message || "Note Visible for mechanic"}
                    </div>
                    <div>
                      <span>
                        <Checkbox toggle disabled={!note} name="keep_parts" onChange={onCheckboxChange} checked={keepParts} />
                      </span>
                      {t("v8_keep_parts").message || "Keep Parts"}
                    </div>
                  </div>
                </Form.Field>
              </GridColumn>
            </Grid>

            <PinLog pinLog={pin.history} pinTypeData={pinTypeData} pinOrderData={pinOrderData} pinStatusData={pinStatusData} pinWarrantyData={pinWarrantyData} />
          </Form>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <div className="modal-footer">
          <Button onClick={onClose} basic>
            {t("v8_cancel").message || "Cancel"}
            <Icon className="xmark" />
          </Button>

          <Button color="green" disabled={!hasUpdated()} onClick={handleNewPinItem}>
            {t("v8_save").message || "Save"}
            <Icon className="check" />
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

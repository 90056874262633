import { useMemo } from "react";
import { Icon } from "semantic-ui-react";

import { ActionModalSelector, ActionModalSelectorData, AnswerStatusSelector } from "components";
import { Appointment, SnoozeAttachment } from "models";
import { QuestionResult } from "models/QuestionResult";
import { GenericAttachmentData } from "modules/AppointmentDetails/components/Interventions/components";

type SnoozeActionsProps = {
  data: QuestionResult;
  attachments?: SnoozeAttachment[];
  onSelect: (data: ActionModalSelectorData) => void;
  toggleShowMedia: () => void;
  appointment: Appointment;
};

const SnoozeActions = ({ data, appointment, attachments, onSelect, toggleShowMedia }: SnoozeActionsProps) => {
  const isConvertToInterventionButtonDisabled = useMemo(() => {
    return appointment?.interventions?.some(intervention => intervention.question_result_id === data.id);
  }, [appointment.interventions, data.id]);
  return (
    <div className="snooze-action">
      <div className="status-container">
        <AnswerStatusSelector data={data} />
        {!!attachments?.length && (
          <div onClick={toggleShowMedia}>
            <Icon className="images grey pointer" size="small" />
            <p>
              <small>{attachments.length}</small>
            </p>
          </div>
        )}
        <ActionModalSelector
          data={data}
          attachments={attachments as GenericAttachmentData[]}
          onSelect={onSelect}
          editSnooze
          isConvertToInterventionButtonDisabled={isConvertToInterventionButtonDisabled}
        />
      </div>
    </div>
  );
};

export default SnoozeActions;

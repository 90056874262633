import { useMutation, useQueryClient } from "@tanstack/react-query";

import ENV from "config/Env";
import { useAppointment } from "modules/AppointmentDetails/hooks";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import apiInstance from "util/Api";

interface CheckInRemarkMutationData {
  checkin_remark_id: number;
  handled: boolean;
}

interface DiagnoseOverviewRemarkMutationData {
  diagnose_overview_remark_id: number;
  handled_remark: boolean; // TODO: ask backend to be consistent and name this handled
}

interface KeylockerRemarkMutationData {
  keylocker_remark_id: number;
  handled_remark: boolean; // TODO: ask backend to be consistent and name this handled
}

export const useCommunicationRemarkMutation = (appointment_id: number) => {
  const queryClient = useQueryClient();
  const { appointment } = useAppointment(appointment_id);

  const appointmentQueryKey = AppointmentDetailsKeys.view(appointment_id);

  const checkInRemarkMutation = useMutation({
    mutationFn: async (data: CheckInRemarkMutationData) => {
      await apiInstance.post(`/check_in/remark/handle`, data, ENV.appointmentBaseURL);
    },
    onMutate: ({ checkin_remark_id, handled }) => {
      if (!appointment?.customer_communication?.check_in_remarks?.length) return;

      const checkInRemarkIdx = appointment.customer_communication.check_in_remarks.findIndex(remark => remark.id === checkin_remark_id);
      if (checkInRemarkIdx < 0) return;

      const customer_communication = {
        ...appointment.customer_communication,
        check_in_remarks: appointment.customer_communication.check_in_remarks.with(checkInRemarkIdx, {
          ...appointment.customer_communication.check_in_remarks[checkInRemarkIdx],
          receptionist_handled: handled
        })
      };

      queryClient.setQueryData(appointmentQueryKey, { ...appointment, customer_communication });

      return appointment;
    },
    onError: (_, __, appointment) => queryClient.setQueryData(appointmentQueryKey, appointment)
  });

  const diagnoseOverviewRemarkMutation = useMutation({
    mutationFn: async (data: DiagnoseOverviewRemarkMutationData) => {
      await apiInstance.post(`/diagnose_overview/remark/handle`, data, ENV.appointmentBaseURL);
    },
    onMutate: ({ diagnose_overview_remark_id, handled_remark }) => {
      if (!appointment?.customer_communication?.diagnose_overview_remarks?.length) return;

      const checkInRemarkIdx = appointment.customer_communication.diagnose_overview_remarks.findIndex(remark => remark.id === diagnose_overview_remark_id);
      if (checkInRemarkIdx < 0) return;

      const customer_communication = {
        ...appointment.customer_communication,
        diagnose_overview_remarks: appointment.customer_communication.diagnose_overview_remarks.with(checkInRemarkIdx, {
          ...appointment.customer_communication.diagnose_overview_remarks[checkInRemarkIdx],
          receptionist_handled: handled_remark
        })
      };

      queryClient.setQueryData(appointmentQueryKey, { ...appointment, customer_communication });

      return appointment;
    },
    onError: (_, __, appointment) => queryClient.setQueryData(appointmentQueryKey, appointment)
  });

  const keylockerRemarkMutation = (keylocker_id: number) => {
    return useMutation({
      mutationFn: async (data: KeylockerRemarkMutationData) => {
        await apiInstance.post(`/dashboard/remark/handle`, data, ENV.keylockerBaseURL);
      },
      onMutate: ({ keylocker_remark_id, handled_remark }) => {
        if (!appointment?.keylocker_communications?.length) return;

        const keylockerCommIdx = appointment.keylocker_communications.findIndex(kc => kc.id === keylocker_id);
        if (keylockerCommIdx < 0 || appointment.keylocker_communications[keylockerCommIdx].remark?.id !== keylocker_remark_id) return;

        const keylocker_communications = appointment.keylocker_communications.with(keylockerCommIdx, {
          ...appointment.keylocker_communications[keylockerCommIdx],
          remark: {
            ...appointment.keylocker_communications[keylockerCommIdx].remark,
            receptionist_handled: handled_remark
          }
        });

        queryClient.setQueryData(appointmentQueryKey, { ...appointment, keylocker_communications });

        return appointment;
      },
      onError: (_, __, appointment) => queryClient.setQueryData(appointmentQueryKey, appointment)
    });
  };

  return { checkInRemarkMutation, diagnoseOverviewRemarkMutation, keylockerRemarkMutation };
};

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Icon, TextArea } from "semantic-ui-react";

import { useCan } from "components";
import { Intervention } from "models/Intervention";
import "modules/AppointmentDetails/components/Interventions/components/Notes/Notes.scss";
import { ITranslation } from "util/interfaces";

type NotesProps = {
  data: Intervention;
};

export const Notes = ({ data }: NotesProps) => {
  const [showDesktopNote, setShowDesktopNote] = useState(false);
  const canUpdateAppointments = useCan("update", "appointments");
  const t = useTranslation().t as ITranslation;

  return (
    <div className="Notes">
      <Form>
        <Form.Field width={16}>
          <label>{t("v8_mechanic_note").message || "Mechanic Note"}</label>
          <div className="mechanic_notes">
            <span>{data.mechanic_notes}</span>
          </div>
        </Form.Field>

        {showDesktopNote && (
          <div className="desktop-note">
            <div className="note-input">
              <label>{t("v8_desktop_note").message || "Desktop Note"}</label>
              <TextArea rows={1} value={data.description} placeholder="Write note" fluid name="desktop_notes" disabled={!canUpdateAppointments} />
            </div>
            <div className="delete-note" onClick={() => setShowDesktopNote(false)}>
              <Icon className="trash grey" />
            </div>
          </div>
        )}

        {/* <div className="add-note">
          <Button color="green" type="submit">
            {t("v8_save").message || "Save"}
            <Icon className="check" />
          </Button>
          {!showDesktopNote && (
            <Button color="green" type="submit" className="-appointment-statu left-icon" onClick={() => setShowDesktopNote(true)}>
              <Icon className="plus" />
              {t("v8_add_note").message || "Add Note"}
            </Button>
          )}
        </div> */}
      </Form>
    </div>
  );
};

import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Label } from "semantic-ui-react";

import { CommunicationAgreement } from "models";
import { AgreementsModal } from "modules/AppointmentDetails/components/KioskCommunicationLog/components";
import { ITranslation } from "util/interfaces";

export type KioskCommAgreement = {
  name: string;
  text: string;
};

type AgreementsProps = {
  agreements?: CommunicationAgreement[];
};

export const Agreements = ({ agreements }: AgreementsProps) => {
  const t = useTranslation().t as ITranslation;
  const [selectedAgreement, setSelectedAgreement] = useState<KioskCommAgreement | null>(null);

  if (!agreements?.length) return null;

  return (
    <div className="kiosk-comm-section-container">
      <div className="kiosk-comm-section-header">{t("v8_agreements").message || "Agreements"}</div>

      <Grid>
        <Grid.Column width={16}>
          {agreements?.map((item, key) => (
            <div className="agreements-row" key={key} onClick={() => setSelectedAgreement(item)}>
              <Label>
                <FontAwesomeIcon icon={faSquareCheck} />
              </Label>
              <span className="-margin-left-10">{item.name}</span>
            </div>
          ))}
        </Grid.Column>
      </Grid>

      {!!selectedAgreement && <AgreementsModal agreement={selectedAgreement} onCloseAgreementModal={() => setSelectedAgreement(null)} />}
    </div>
  );
};

import { Icon } from "semantic-ui-react";

import { useWebSocketStatus } from "components/WebSocket/useWebSocketStatus";
import "components/WebsocketStatus/WebsocketStatus.scss";

export const WebsocketStatus = () => {
  const isConnected = useWebSocketStatus();

  return (
    <div className="WebsocketStatus">
      <Icon className={`screencast i-${isConnected ? "green" : "maroon"}`} style={{ fontSize: 10 }} />
    </div>
  );
};

import { useTranslation } from "react-i18next";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { ReportColor } from "models";
import "modules/LocationReports/components/StackedBarChart/StackedBarChart.scss";
import { REPORTS_COLORS, extractAllNameFields } from "util/common";
import { ITranslation } from "util/interfaces";

type StackedBarChartProps = {
  data: Record<string, string>[];
  colors: ReportColor[];
  unit: string;
};

const StackedBarChart = ({ data, colors, unit }: StackedBarChartProps) => {
  const t = useTranslation().t as ITranslation;
  return (
    <div className="StackedBarChart">
      <ResponsiveContainer width="100%" height={450} className="margin-auto">
        <BarChart data={data} margin={{ top: 20, right: 30, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis unit={unit} domain={unit === "%" ? [0, 100] : ["auto", "auto"]} />
          <Tooltip />
          <Legend />

          {extractAllNameFields(data)
            .filter(k => k !== "name")
            .map((k, i) => {
              const item = colors.find(col => (t(col.name).message || col.name) === k);
              const color = item?.color || null;

              let stack = "stack_a";
              let name = k;

              if (k.startsWith("stack_a.")) {
                name = k.replace("stack_a.", "");
              } else if (k.startsWith("stack_b.")) {
                stack = "stack_b";
                name = k.replace("stack_b.", "");
              }

              return (
                <Bar stackId={stack} name={name} key={i} dataKey={k} fill={color || REPORTS_COLORS[i % REPORTS_COLORS.length]} unit={unit} isAnimationActive={false} />
              );
            })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StackedBarChart;

import { Appointment, KeyloopImportStatus, KeyloopRepairOrder, NextLaneRepairOrder, STATUS_IDENTIFIER } from "models";

import { AppointmentSearchOption, SEARCH_OPTION_TYPE } from ".";

export interface AppointmentSearchResults {
  claire: Appointment[];
  keyloop?: KeyloopRepairOrder[];
  nextlane?: NextLaneRepairOrder[];
}

const keyloopStatusMapping: { [key in KeyloopImportStatus]: STATUS_IDENTIFIER } = {
  CREATED: STATUS_IDENTIFIER.NewCarStatus,
  ONHOLD: STATUS_IDENTIFIER.NewCarStatus,
  CHECKEDIN: STATUS_IDENTIFIER.CarInShop,
  WORKSTARTED: STATUS_IDENTIFIER.CarCheckStartedStatus,
  WORKCOMPLETED: STATUS_IDENTIFIER.CarCheckStatus,
  CHECKEDOUT: STATUS_IDENTIFIER.CarReadyStatus,
  CLOSED: STATUS_IDENTIFIER.CarReadyStatus
};

const nextlaneStatusMapping = (repairOrder: NextLaneRepairOrder): STATUS_IDENTIFIER => {
  if (repairOrder.IsVehHandedBack) return STATUS_IDENTIFIER.CarOutOfShop;
  if (repairOrder.IsFinished) return STATUS_IDENTIFIER.CarReadyStatus;
  if (repairOrder.VehicleMileageIn > 0) return STATUS_IDENTIFIER.CarInShop;

  return STATUS_IDENTIFIER.NewCarStatus;
};

export const getFormattedAndSortedSearchResults = (data: AppointmentSearchResults) => {
  const options: AppointmentSearchOption[] = [];

  if (data.keyloop) {
    options.push(
      ...data.keyloop.map(item => ({
        id: item.repairOrderId,
        car_make: "",
        car_model: item.vehicle.description,
        due_in: item.appointment.dueInDateTime,
        wo_nr: item.repairOrderId,
        reg_number: item.vehicle.licensePlate,
        appointment_status_identifier: keyloopStatusMapping[item.status] || 0,
        type: SEARCH_OPTION_TYPE.Keyloop
      }))
    );

    options.sort((a, b) => new Date(a.due_in).valueOf() - new Date(b.due_in).valueOf());
  }

  if (data.nextlane) {
    const nextlaneOptions: AppointmentSearchOption[] = data.nextlane.map(item => ({
      id: item.InternalFolderID,
      car_make: item?.Vehicle?.BrandDescr || "",
      car_model: "",
      due_in: item?.AppointmentDate || "",
      wo_nr: item?.InternalFolderID || "",
      reg_number: item?.Vehicle?.RegistrationNumber || "",
      appointment_status_identifier: nextlaneStatusMapping(item),
      type: SEARCH_OPTION_TYPE.Nextlane
    }));

    nextlaneOptions.sort((a, b) => new Date(a.due_in).valueOf() - new Date(b.due_in).valueOf());
    options.push(...nextlaneOptions);
  }

  const claireOptions: AppointmentSearchOption[] = data.claire.map(item => ({
    id: item.id + "",
    car_make: item.car_make,
    car_model: item.car_model,
    due_in: item.due_in || item.time_car_app,
    wo_nr: item.wo_nr,
    reg_number: item.reg_number,
    appointment_status_identifier: item.appointment_status_identifier,
    dealer_location: item.dealer_location,
    customcom_status: item.customer_communication?.status,
    check_paused_at: item.check_paused_at,
    car_out_of_shop: item.car_out_of_shop,
    type: SEARCH_OPTION_TYPE.Claire
  }));

  claireOptions.sort((a, b) => new Date(a.due_in).valueOf() - new Date(b.due_in).valueOf());
  options.push(...claireOptions);

  return options;
};

import { useQuery } from "@tanstack/react-query";
import { Modal, ModalProps } from "semantic-ui-react";

import { Login } from "modules/Auth/components/Login";
import "modules/Auth/components/LoginModal/LoginModal.scss";
import { queryKeys } from "util/keyFactory";

const LoginModal = ({ onClose }: ModalProps) => {
  const { data: isTokenExpired }: { data: boolean | undefined } = useQuery<boolean>({ queryKey: [queryKeys.common.isTokenExpired] });

  return (
    <Modal size="tiny" open={!!isTokenExpired} onClose={onClose} className="LoginModal">
      <Modal.Content>
        <Login isModalOpen={true} />
      </Modal.Content>
    </Modal>
  );
};

export default LoginModal;

import React from "react";
import { Button, Icon } from "semantic-ui-react";

import FaceIcon from "assets/svg/face.svg";
import { getAppointmentStatus } from "components";
import { useDealersLocations } from "hooks";
import { STATUS_IDENTIFIER } from "models";
import "modules/Appointments/components/StatusFilters/StatusFilters.scss";
import { StatusData, useGetAppointmentsSelectedIdentifiers } from "modules/Appointments/hooks";

interface StatusFiltersProps {
  onChange?: (e: STATUS_IDENTIFIER[]) => void;
}

export const StatusFilters: React.FC<StatusFiltersProps> = ({ onChange }: StatusFiltersProps) => {
  const selectedStatusIdentifiersFilters = useGetAppointmentsSelectedIdentifiers();
  const { selectedLocation } = useDealersLocations();

  const handleClick = (index: number) => {
    const statuses = [...selectedStatusIdentifiersFilters];
    statuses[index].isActive = !statuses[index].isActive;

    if (onChange) {
      const selectedValues = statuses.filter((item: StatusData) => !item.isActive).map(s => s.value);
      if (selectedValues.includes(STATUS_IDENTIFIER.CarReadyStatus)) {
        selectedValues.push(STATUS_IDENTIFIER.CarOkPlusRepairOverview);
      }
      if (selectedValues.includes(STATUS_IDENTIFIER.QualityCheckStatus)) {
        selectedValues.push(STATUS_IDENTIFIER.QualityCheckPlusRepairOverview);
      }
      onChange(selectedValues);
    }
  };

  if (!selectedLocation?.statuses) return null;

  return (
    <div className="status-filters-wrapper">
      {selectedStatusIdentifiersFilters.map((item: StatusData, index: number) => {
        return (
          <div key={`filter-status-${index}`} className="StatusFilters-buttons" onClick={() => handleClick(index)}>
            <Button
              color="orange"
              className="af"
              style={{ backgroundColor: getAppointmentStatus(item.value, selectedLocation?.statuses)?.color }}
              disabled={!item.isActive}
            >
              {item.icon === "bo" ? (
                "BO"
              ) : item.icon === "face" ? (
                <div className="filter-status-face-icon">
                  <img src={FaceIcon} />
                </div>
              ) : (
                <Icon className={item.icon} />
              )}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { Appointment } from "models";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import ApiInstance from "util/Api";

// TODO: cleanup like other hooks
export const useIndicator = (appointment_id: number) => {
  const queryClient = useQueryClient();

  const appointmentDetailsViewKey = AppointmentDetailsKeys.view(appointment_id);

  const updateAppointment = (data: Partial<Appointment>) => {
    const AppointmentSnapshot = queryClient.getQueryData(appointmentDetailsViewKey);
    if (AppointmentSnapshot) {
      const updatedData = { ...(AppointmentSnapshot as Appointment), ...data };
      queryClient.setQueryData(appointmentDetailsViewKey, updatedData);
    }
    return { AppointmentSnapshot, appointmentDetailsViewKey };
  };

  const handleError = (e: any, context: any) => {
    toast.error(e.message);
    if (context?.AppointmentSnapshot && context?.appointmentDetailsViewKey) {
      queryClient.setQueryData(context.appointmentDetailsViewKey, context.AppointmentSnapshot);
    }
  };

  const appointmentMoneyIndicatorMutation = useMutation({
    mutationFn: ({ is_money }: { is_money: boolean }) => ApiInstance.post("/appointments/update_billing", { appointment_id, is_money }, ENV.appointmentBaseURL),
    onMutate: updateAppointment,
    onError: handleError
  });

  const appointmentStarIndicatorMutation = useMutation({
    mutationFn: ({ is_star, is_star_color }: { is_star: boolean; is_star_color: string }) =>
      ApiInstance.post("/appointments/update_star", { appointment_id, is_star, is_star_color }, ENV.appointmentBaseURL),
    onMutate: updateAppointment,
    onError: handleError
  });

  const appointmentShopIndicatorMutation = useMutation({
    mutationFn: ({ is_shop, is_shop_color }: { is_shop: boolean; is_shop_color: string }) =>
      ApiInstance.post("/appointments/update_shopping_cart", { appointment_id, is_shop, is_shop_color }, ENV.appointmentBaseURL),
    onMutate: updateAppointment,
    onError: handleError
  });

  return { appointmentMoneyIndicatorMutation, appointmentStarIndicatorMutation, appointmentShopIndicatorMutation };
};

import { DefaultError, useQuery } from "@tanstack/react-query";

import ENV from "config/Env";
import { STATUS_IDENTIFIER } from "models";
import ApiInstance from "util/Api";
import { queryKeys } from "util/keyFactory";

export type Checklist = {
  name: string;
  photo_count: number;
  n_count: number;
  n_count_sold: number;
  a_count: number;
  a_count_sold: number;
};

export type Appointment = {
  date: string;
  location_name: string;
  dealer_location_id: number;
  next_date: Date;
  current_km: number;
  next_km: number | null;
  reg_number: string;
  checklists: Checklist[];
  id: number;
  appointment_status_identifier: STATUS_IDENTIFIER;
  is_money: boolean;
  wo_nr: number;
  time_car_app: string;
};
type CheckListDataProps = {
  loading: boolean;
  data?: Appointment[];
  error: DefaultError | null;
};

export const useAppointmentHistory = (id: string): CheckListDataProps => {
  const query = useQuery({
    queryKey: ["realtime", { ...queryKeys.car.appintmentHistory, id }],
    queryFn: () => ApiInstance.post("/cars/checks/list", { id: Number(id) }, ENV.carBaseUrl)
  });

  return { data: query.data?.data?.appointments, loading: query.isFetching, error: query.error };
};

import { QueryFunctionContext, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { WebSocketMessage, WebSocketMessageListener } from "components";
import { useDealersLocations, useRealTimeQuery } from "hooks";
import { Appointment, AppointmentNote, User } from "models";
import { DayplannerAppointment } from "modules/Dayplanner/interfaces";
import { DayplannerKeys } from "modules/Dayplanner/queryKeys";
import ApiInstance from "util/Api";
import { IBackendQueryKey } from "util/keyFactory";

export const useDayplanner = () => {
  const { selectedLocation } = useDealersLocations();
  const queryClient = useQueryClient();

  const mechanicsQuery = useQuery({
    queryKey: DayplannerKeys.mechanics,
    queryFn: async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
      if (!selectedLocation?.id) throw new Error("invalid location");

      const { baseUrl, endpoint } = queryKey[0];
      const response = await ApiInstance.post(endpoint, { dealer_location_id: selectedLocation.id }, baseUrl);

      return response?.data as User[];
    },
    enabled: !!selectedLocation?.id
  });

  const appointmentsQueryKey = DayplannerKeys.appointments;
  const appointmentsQueryListeners = useMemo((): WebSocketMessageListener[] => {
    if (!selectedLocation?.id) return [];

    return [
      {
        model: "Appointment",
        filter: { dealer_location_id: selectedLocation.id },
        action: "create",
        callback: (message: WebSocketMessage) => {
          const appointments = queryClient.getQueryData<DayplannerAppointment[]>(appointmentsQueryKey);

          // TODO: we must have setQueryData (and getQueryData too) typed with a generic everywhere, let's make a wrapper that forces it
          queryClient.setQueryData<DayplannerAppointment[]>(appointmentsQueryKey, [...(appointments ?? []), message.data as Appointment]);
        }
      },
      {
        model: "Appointment",
        filter: { dealer_location_id: selectedLocation.id },
        action: "update",
        callback: (message: WebSocketMessage) => {
          const appointments = queryClient.getQueryData<DayplannerAppointment[]>(appointmentsQueryKey);

          if (!appointments?.length) return;

          const appointmentIdx = appointments.findIndex(appointment => appointment.id === message.id);
          if (appointmentIdx < 0) return;

          const appointment = appointments[appointmentIdx];

          const update = message.data as DayplannerAppointment;
          const mechanicId = appointment.assigned_mechanic;
          const mechanicOrder = appointment.assigned_mechanic_order;
          const newMechanicId = update.assigned_mechanic;
          const newMechanicOrder = update.assigned_mechanic_order;

          if (mechanicId === newMechanicId && mechanicOrder === newMechanicOrder) {
            queryClient.setQueryData<DayplannerAppointment[]>(appointmentsQueryKey, appointments.with(appointmentIdx, { ...appointment, ...update }));
            return;
          }

          const updatedAppointments = appointments.map(appointment => {
            if (appointment.id === message.id) return { ...appointment, ...update };

            if (mechanicId === newMechanicId && mechanicId && mechanicOrder && newMechanicOrder) {
              if (newMechanicOrder > mechanicOrder) {
                if (
                  appointment.assigned_mechanic === mechanicId &&
                  appointment.assigned_mechanic_order &&
                  appointment.assigned_mechanic_order > mechanicOrder &&
                  appointment.assigned_mechanic_order <= newMechanicOrder
                ) {
                  return { ...appointment, assigned_mechanic_order: appointment.assigned_mechanic_order - 1 };
                }
              } else if (newMechanicOrder < mechanicOrder) {
                if (
                  appointment.assigned_mechanic === mechanicId &&
                  appointment.assigned_mechanic_order &&
                  appointment.assigned_mechanic_order >= newMechanicOrder &&
                  appointment.assigned_mechanic_order < mechanicOrder
                ) {
                  return { ...appointment, assigned_mechanic_order: appointment.assigned_mechanic_order + 1 };
                }
              }
            }

            if (mechanicId !== newMechanicId && mechanicId && newMechanicId && mechanicOrder && newMechanicOrder) {
              if (appointment.assigned_mechanic === mechanicId && appointment.assigned_mechanic_order && appointment.assigned_mechanic_order > mechanicOrder) {
                return { ...appointment, assigned_mechanic_order: appointment.assigned_mechanic_order - 1 };
              }

              if (appointment.assigned_mechanic === newMechanicId && appointment.assigned_mechanic_order && appointment.assigned_mechanic_order >= newMechanicOrder) {
                return { ...appointment, assigned_mechanic_order: appointment.assigned_mechanic_order + 1 };
              }
            }

            if (!mechanicId && newMechanicId && newMechanicOrder) {
              if (appointment.assigned_mechanic === newMechanicId && appointment.assigned_mechanic_order && appointment.assigned_mechanic_order >= newMechanicOrder) {
                return { ...appointment, assigned_mechanic_order: appointment.assigned_mechanic_order + 1 };
              }
            }

            if (mechanicId && !newMechanicId && mechanicOrder) {
              if (appointment.assigned_mechanic === mechanicId && appointment.assigned_mechanic_order && appointment.assigned_mechanic_order > mechanicOrder) {
                return { ...appointment, assigned_mechanic_order: appointment.assigned_mechanic_order - 1 };
              }
            }

            return appointment;
          });

          queryClient.setQueryData<DayplannerAppointment[]>(appointmentsQueryKey, updatedAppointments);
        }
      },
      {
        model: "AppointmentNote",
        action: "create",
        callback: (message: WebSocketMessage) => {
          const appointments = queryClient.getQueryData<DayplannerAppointment[]>(appointmentsQueryKey);

          if (!appointments?.length) return;

          const appointmentIdx = appointments.findIndex(appointment => appointment.id === message.id);

          if (appointmentIdx < 0) return;

          const appointment = appointments[appointmentIdx];
          queryClient.setQueryData<DayplannerAppointment[]>(
            appointmentsQueryKey,
            appointments.with(appointmentIdx, { ...appointment, notes: [...(appointment.notes ?? []), message.data as AppointmentNote] })
          );
        }
      },
      {
        model: "AppointmentNote",
        action: "update",
        callback: (message: WebSocketMessage) => {
          const appointments = queryClient.getQueryData<DayplannerAppointment[]>(appointmentsQueryKey);

          if (!appointments?.length) return;

          const appointmentIdx = appointments.findIndex(appointment => appointment.id === message.id);

          if (appointmentIdx < 0) return;

          const appointment = appointments[appointmentIdx];
          if (!appointment.notes?.length) return;

          const update = message.data as AppointmentNote;
          const noteIdx = appointment.notes.findIndex(n => n.id === update.id);

          if (noteIdx < 0) return;

          queryClient.setQueryData<DayplannerAppointment[]>(
            appointmentsQueryKey,
            appointments.with(appointmentIdx, { ...appointment, notes: appointment.notes.with(noteIdx, { ...appointment.notes[noteIdx], ...update }) })
          );
        }
      },
      {
        model: "AppointmentNote",
        action: "delete",
        callback: (message: WebSocketMessage) => {
          const appointments = queryClient.getQueryData<DayplannerAppointment[]>(appointmentsQueryKey);

          if (!appointments?.length) return;

          const appointmentIdx = appointments.findIndex(appointment => appointment.id === message.id);

          if (appointmentIdx < 0) return;

          const appointment = appointments[appointmentIdx];
          if (!appointment.notes?.length) return;

          const update = message.data as AppointmentNote;
          queryClient.setQueryData<DayplannerAppointment[]>(
            appointmentsQueryKey,
            appointments.with(appointmentIdx, { ...appointment, notes: appointment.notes.filter(n => n.id !== update.id) })
          );
        }
      }
    ];
  }, [queryClient, selectedLocation?.id]);

  const realTimeAppointmentsQuery = useRealTimeQuery({
    queryKey: appointmentsQueryKey,
    queryFn: async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
      if (!selectedLocation?.id) throw new Error("invalid location");

      const { baseUrl, endpoint } = queryKey[0];
      const response = await ApiInstance.post(endpoint, { dealer_location_id: selectedLocation.id }, baseUrl);

      return response?.data as Appointment[];
    },
    enabled: !!selectedLocation?.id,
    listeners: appointmentsQueryListeners
  });

  const loading = mechanicsQuery.isFetching || realTimeAppointmentsQuery.isFetching;
  const error = mechanicsQuery.error || realTimeAppointmentsQuery.error;

  return {
    appointments: realTimeAppointmentsQuery.data,
    mechanics: mechanicsQuery.data,
    loading,
    error
  };
};

import { Icon } from "semantic-ui-react";

import { APPOINTMENT_NOTE_TYPES } from "models";
import "modules/AppointmentDetails/components/AppointmentNotes/components/AppointmentNoteEditBtn/AppointmentNoteEditBtn.scss";

type AppointmentNoteEditBtnProps = {
  type: number;
  onSelectNote?: () => void;
};

export const AppointmentNoteEditBtn = ({ type, onSelectNote }: AppointmentNoteEditBtnProps) => {
  switch (type) {
    case APPOINTMENT_NOTE_TYPES.Wo:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "#2D85D033" }} onClick={onSelectNote}>
          <Icon className="pen to square" style={{ color: "#4283CA" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.Main:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "#FF020233" }} onClick={onSelectNote}>
          <Icon className="pen to square" color="red" />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.CallCustomer:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "#21BA4533" }} onClick={onSelectNote}>
          <Icon className="pen to square" style={{ color: "#21BA45" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.BillNote:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "#21BA4533" }} onClick={onSelectNote}>
          <Icon className="pen to square" style={{ color: "#21BA45" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.TemporaryDriverNote:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }} onClick={onSelectNote}>
          <Icon className="pen to square" color="black" />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.RecurringCar:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "rgba(200, 54, 40, 0.2)" }} onClick={onSelectNote}>
          <Icon className="pen to square" style={{ color: "#C83628" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.Attachment:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "#21A1D033" }} onClick={onSelectNote}>
          <Icon className="pen to square" style={{ color: "#4283CA" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.Parking:
      return (
        <div className="AppointmentNoteEditBtn" style={{ backgroundColor: "#21A1D033" }} onClick={onSelectNote}>
          <Icon className="pen to square" style={{ color: "#4283CA" }} />
        </div>
      );
    default:
      return null;
  }
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";

import { ReportColor } from "models";
import "modules/LocationReports/components/SimpleBarChart/SimpleBarChart.scss";
import { REPORTS_COLORS, defaultFormatter, extractAllNameFields, isNumOrStr } from "util/common";
import { ITranslation } from "util/interfaces";

type PercentStackedBarChartProps = {
  data: Record<string, string>[];
  colors: ReportColor[];
  unit: string;
};

const VerticalOffset = 5;

const PercentStackedBarChart = ({ data, colors, unit }: PercentStackedBarChartProps) => {
  const t = useTranslation().t as ITranslation;
  const [percents, setPrecents] = useState([...data]);

  const renderCustomTooltip = ({ active, payload, label, formatter, separator = ":" }: TooltipProps<number, string>) => {
    if (!active) return null;

    return (
      <div
        className="recharts-default-tooltip"
        style={{ margin: 0, padding: 10, backgroundColor: "white", border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap" }}
      >
        <p className="recharts-tooltip-label" style={{ margin: 0 }}>
          {label}
        </p>
        <ul className="recharts-tooltip-item-list" style={{ padding: 0, margin: 0 }}>
          {payload?.reverse().map((entry: any, i: number) => {
            if (entry.type === "none") return null;

            const itemStyle = {
              display: "block",
              paddingTop: 4,
              paddingBottom: 4,
              color: entry.color || "#000"
            };

            const formatData = entry.formatter || formatter || defaultFormatter;
            let { name, value } = entry;
            if (formatData) {
              const formatted = formatData(value, name, entry, i, payload);
              if (Array.isArray(formatted)) [value, name] = formatted;
              else value = formatted;
            }

            const valueTooltip = entry.payload && entry.payload["value." + entry.name] ? entry.name + ": " + entry.payload["value." + entry.name] : null;

            return (
              <React.Fragment key={`tooltip-item-${i}`}>
                <li className="recharts-tooltip-item" style={itemStyle}>
                  {isNumOrStr(name) ? (
                    <>
                      <span className="recharts-tooltip-item-name">{name}</span>
                      <span className="recharts-tooltip-item-separator">{separator}</span>
                    </>
                  ) : null}
                  <span className="recharts-tooltip-item-value">{value}</span>
                  <span className="recharts-tooltip-item-unit">{entry.unit || ""}</span>
                </li>
                {valueTooltip && (
                  <li className="recharts-tooltip-item" style={itemStyle}>
                    <span className="recharts-tooltip-item-value">{valueTooltip}</span>
                  </li>
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    );
  };

  const renderCustomBarLabel = (params: any) => {
    const { x, y, width, height, value } = params;

    if (height < 14) return null;

    return (
      <text x={x + width / 2} y={y + height / 2} fill="#000000" textAnchor="middle" dy={VerticalOffset}>
        {value}
      </text>
    );
  };

  useEffect(() => {
    const totals: any = [];
    data.forEach((item: Record<string, string>) => {
      Object.keys(item).forEach(key => {
        if (key !== "name") totals[item.name] = (Number(totals[item.name]) || 0) + (Number(item[key]) || 0);
      });
    });

    const clonedData: Record<string, string>[] = JSON.parse(JSON.stringify(data));
    clonedData.forEach((item: Record<string, string>, index: number) => {
      Object.keys(item).forEach((key: string) => {
        if (key === "name") return;
        clonedData[index]["value." + key] = clonedData[index][key];
        clonedData[index][key] = ((Number(clonedData[index][key]) / Number(totals[item.name])) * 100).toFixed(2);
      });
    });
    setPrecents(clonedData);
  }, []);

  return (
    <div className="PercentStackedBarChart">
      <ResponsiveContainer width="100%" height={450} className="margin-auto">
        <BarChart data={percents} margin={{ top: 20, right: 30, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis unit={unit} allowDecimals={false} allowDataOverflow={true} domain={[0, 100]} />
          <Tooltip content={renderCustomTooltip} />
          <Legend />

          {extractAllNameFields(percents)
            .filter(k => k !== "name" && !k.startsWith("value."))
            .map((k, i) => {
              const item = colors.find(col => (t(col.name).message || col.name) === k);
              const color = item?.color || null;

              return (
                <Bar stackId="a" key={i} dataKey={k} fill={color || REPORTS_COLORS[i % REPORTS_COLORS.length]} unit={unit} isAnimationActive={false}>
                  <LabelList dataKey={(val: any) => val["value." + k] || null} content={renderCustomBarLabel} />
                </Bar>
              );
            })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PercentStackedBarChart;

import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { Appointment, Customer } from "models";
import { Appointment as AppointmentHistory, useItemsNotRepaired } from "modules/CarDetails/hooks";
import ApiInstance from "util/Api";

export type CustomerInfoProps = {
  data: Record<string, Customer>;
};

type Attachment = {
  appointment_id: number;
  appointment_note_id: number;
  name: string;
  type: string;
  url: string;
  user_id: string;
  userame: string;
};

export type Note = {
  created_on: string;
  note: string;
  id: number;
  appointment_id: number;
  user: {
    first_name: string;
    last_name: string;
  };
  attachments: Attachment[];
};

type ReqData = {
  id: number;
};

export const useCustomerInfo = () => {
  const [data, setData] = useState<{ [key: string]: Partial<Customer> }>({});
  const [latestAppointmentId, setLatestAppointmentId] = useState(0);
  const { data: itemsNotRepaired, fetchItemsNotRepaired } = useItemsNotRepaired();
  const [notes, setNotes] = useState<Note[]>([]);

  const getAppointmentsView = async (data: ReqData) => {
    const res = await ApiInstance.post("/appointments/view", { embed: "customer_owner,customer_driver,customer_contract", ...data });
    return res.data;
  };

  const appointmentsMutation = useMutation({
    mutationFn: getAppointmentsView,
    onSuccess: (response: Appointment) => {
      const customersData = {
        Owner: {
          ...response.customer_owner
        },
        Driver: {
          ...response.customer_driver
        },
        Contractor: {
          ...response.customer_contract
        }
      };
      setData(customersData);
      setNotes((response?.notes as unknown as Note[]) || []);
    },
    onError: e => {
      console.warn("useCustomerInfo", e);
    }
  });

  const fetchCustomerInfo = async (data?: AppointmentHistory[]) => {
    const sortedAppointments = data?.map((a: AppointmentHistory) => ({ ...a, date: new Date(a.date).getTime() })).sort((a: any, b: any) => b.date - a.date);
    if (sortedAppointments?.length) {
      const { id } = sortedAppointments[0];
      appointmentsMutation.mutate({ id });
      setLatestAppointmentId(id);
      fetchItemsNotRepaired({ appointment_id: id });
    }
  };

  return { data, fetchCustomerInfo, latestAppointmentId, itemsNotRepaired, notes };
};

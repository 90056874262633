import { useDroppable } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { ReactNode, useEffect, useState } from "react";
import { Icon, Label, Popup } from "semantic-ui-react";

import { DAYPLANNER_STATUSES, MechanicAppointmentCard } from "modules/Dayplanner/components";
import "modules/Dayplanner/components/MechanicBox/MechanicBox.scss";
import { DAYPLANNER_COLUMNS, MechanicWithAppointments } from "modules/Dayplanner/interfaces";
import { classNames } from "util/common";

type MechanicBoxProps = {
  mechanic: MechanicWithAppointments;
  numberOfCardsPerRow: number;
  activeFilters: DAYPLANNER_STATUSES[];
  searchTerm?: string;
  onOver: (mechanicId: number) => void;
  autoExpand: boolean;
  mechanicsPinned: number[];
  onToggleMechanicPin: (mechamic_id: number) => void;
  draggedOverMechanicId: number | null;
};

type MechanicAppointmentCardsProps = {
  mechanic: MechanicWithAppointments;
  numberOfCardsPerRow: number;
  expanded: boolean;
  autoExpanded: boolean;
  onOver: (mechanicId: number) => void;
  draggedOverMechanicId: number | null;
};

type MechanicPlaceholderProps = {
  mechanic: MechanicWithAppointments;
  order: number;
  onOver: (mechanicId: number) => void;
};

const MAX_APPOINTMENTS = 12;

const MechanicPlaceholder = ({ mechanic, order, onOver }: MechanicPlaceholderProps) => {
  const { setNodeRef, isOver } = useDroppable({
    id: `${mechanic.id}-placeholder-${order}`,
    data: { mechanicId: mechanic.id, order: Math.min(order, mechanic.appointments.length + 1), placeholder: true }
  });

  useEffect(() => {
    if (isOver) onOver(mechanic.id);
  }, [isOver]);

  return (
    <div ref={setNodeRef} className={"MechanicBox-placeholder"}>
      <div className="MechanicBox-placeholder__number">{order}</div>
    </div>
  );
};

const MechanicAppointmentCards = ({ mechanic, numberOfCardsPerRow = 3, expanded, autoExpanded, onOver, draggedOverMechanicId }: MechanicAppointmentCardsProps) => {
  const items: number[] = [];
  const cards: ReactNode[] = [];
  const placeholders: ReactNode[] = [];

  const roundNumber = mechanic.appointments.length % numberOfCardsPerRow === 0;
  const extraSlot = autoExpanded && draggedOverMechanicId && draggedOverMechanicId !== mechanic.id && roundNumber ? 1 : 0;
  const endIndex =
    expanded || autoExpanded
      ? Math.min(MAX_APPOINTMENTS, Math.ceil((mechanic.appointments.length + extraSlot) / numberOfCardsPerRow) * numberOfCardsPerRow)
      : numberOfCardsPerRow;

  for (let i = 1; i <= endIndex; i++) {
    const app = mechanic.appointments.find(app => app.assigned_mechanic_order === i);
    if (app) {
      items.push(app.id);
      cards.push(<MechanicAppointmentCard key={app.id} appointment={app} mechanic={mechanic} column={DAYPLANNER_COLUMNS.Mechanics} onOver={onOver} />);
    } else placeholders.push(<MechanicPlaceholder key={`${mechanic.id}-placeholder-${i}`} mechanic={mechanic} order={i} onOver={onOver} />);
  }

  return (
    <div className="MechanicBox-appointmentCards">
      <SortableContext items={items} strategy={rectSortingStrategy}>
        {cards}
        {placeholders}
      </SortableContext>
    </div>
  );
};

export const MechanicBox = ({
  mechanic,
  mechanicsPinned,
  onToggleMechanicPin,
  numberOfCardsPerRow,
  activeFilters,
  searchTerm,
  onOver,
  autoExpand,
  draggedOverMechanicId
}: MechanicBoxProps) => {
  const [expanded, setIsExpanded] = useState(false);
  const { setNodeRef, isOver } = useDroppable({ id: mechanic.id, data: { mechanicId: mechanic.id, order: mechanic.appointments.length > 0 ? null : 1 } });

  useEffect(() => {
    if (isOver) {
      onOver(mechanic.id);
    }
  }, [isOver]);

  const mechanicHasAppointments = !!mechanic.appointments.length;

  const handlePinnedMechanic = (mechanic: MechanicWithAppointments) => {
    onToggleMechanicPin(mechanic.id);
  };

  const hasActiveCollapsedAppointments =
    (!!activeFilters.length || !!searchTerm) &&
    !expanded &&
    mechanic.appointments.some(app => !app.inactive && Number(app.assigned_mechanic_order) > numberOfCardsPerRow);

  const extendClasses = classNames({
    "-disabled": !mechanicHasAppointments,
    "-no-pointer": mechanicHasAppointments && mechanic.appointments.length < numberOfCardsPerRow,
    "-border-green": hasActiveCollapsedAppointments
  });

  return (
    <div ref={setNodeRef} className={`MechanicBox ${!mechanicHasAppointments && !isOver && !autoExpand ? "-no-appointments" : ""}`}>
      {(mechanicHasAppointments || autoExpand) && (
        <MechanicAppointmentCards
          mechanic={mechanic}
          numberOfCardsPerRow={numberOfCardsPerRow}
          expanded={expanded}
          autoExpanded={autoExpand}
          onOver={onOver}
          draggedOverMechanicId={draggedOverMechanicId}
        />
      )}

      <div className="MechanicBox-side">
        <div className="MechanicBox-profile">
          <Popup
            trigger={
              mechanic.profile_picture ? (
                <img alt={`${mechanic.first_name?.charAt(0).toUpperCase() || ""} ${mechanic.last_name?.charAt(0).toUpperCase() || ""}`} src={mechanic.profile_picture} />
              ) : (
                <div>
                  {mechanic.first_name?.charAt(0).toUpperCase() || ""}
                  {mechanic.last_name?.charAt(0).toUpperCase() || ""}
                </div>
              )
            }
            content={
              <span className="MechanicBox-name">
                {mechanic.first_name} {mechanic.last_name}
              </span>
            }
          />
        </div>

        {!mechanicHasAppointments && !isOver && !autoExpand && (
          <>
            <span className="mechanic-name">
              {mechanic.first_name || ""} {mechanic.last_name || ""}
            </span>
          </>
        )}

        <div className="MechanicBox-appointments">
          <Label
            className={`extend-appointments-button ${extendClasses}`}
            basic
            onClick={() => mechanic.appointments.length >= numberOfCardsPerRow && setIsExpanded(!expanded)}
          >
            <span>{mechanic.appointments.length}</span>
            {mechanic.appointments.length >= numberOfCardsPerRow && (
              <span className="extend-appointments">
                <Icon className="ellipsis green" />
              </span>
            )}
          </Label>
        </div>
        <div className="MechanicBox-toggle">
          <div className="MechanicBox-pinned">
            <Icon className={mechanicsPinned.includes(mechanic.id) ? "pin" : "circle regular"} onClick={() => handlePinnedMechanic(mechanic)} />
          </div>
        </div>
      </div>
    </div>
  );
};

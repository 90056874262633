import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Icon, Modal, ModalContent, ModalHeader } from "semantic-ui-react";

import { PinLog } from "components";
import printJS from "lib/print-js";
import { Appointment, Intervention, PinModel } from "models";
import {
  ChecklistSection,
  HeaderSection,
  ImportantNotes,
  InterventionSection,
  PinSection
} from "modules/AppointmentDetails/components/Interventions/components/PinOverview/components";
import "modules/AppointmentDetails/components/Interventions/components/PinOverview/PinOverview.scss";
import { PinType } from "modules/AppointmentDetails/components/Interventions/InterventionItem";
import { ITranslation } from "util/interfaces";

export type PinOptions = {
  key: string;
  text: string | undefined;
  value: number;
};

type Props = {
  showPrintOverview: boolean;
  pin?: PinModel;
  pinLog?: PinModel[];
  appointment: Appointment | null;
  intervention: Intervention;
  pinStatusOptions: PinOptions[];
  pinTypeData: PinType[];
  pinOrderData: PinOptions[];
  pinWarrantyData: PinOptions[];
  onClose: () => void;
};

export const PinOverview = ({
  showPrintOverview,
  pin,
  onClose,
  appointment,
  intervention,
  pinLog,
  pinOrderData,
  pinStatusOptions,
  pinTypeData,
  pinWarrantyData
}: Props) => {
  const [showCustomerInformation, setShowCustomerInformation] = useState(true);
  const t = useTranslation().t as ITranslation;

  const onClickHandler = () =>
    printJS({
      printable: "pin-overview-content",
      type: "html",
      copyStyles: true,
      css: ["./PinOverview.css"],
      scanStyles: false,
      targetStyles: ["*"]
    });
  const handleToggleCustomerInformation = () => setShowCustomerInformation(!showCustomerInformation);

  return (
    <Modal size="large" open={showPrintOverview} onClose={onClose} className="PinOverviewContent">
      <ModalHeader>
        <div className="modal-header-container">
          <Checkbox
            label={t("v8_show_customer_information").message || "Show customer information"}
            checked={showCustomerInformation}
            onChange={handleToggleCustomerInformation}
            toggle
          />
          <div className="buttons-container">
            <Button color="grey" className="-appointment-status" onClick={onClickHandler}>
              <Icon className="print black" />
              {t("v8_print").message || "Print"}
            </Button>
            <Button icon size="medium" onClick={onClose} color="light">
              <Icon name="cancel" size="large" className="cancel-icon" />
            </Button>
          </div>
        </div>
      </ModalHeader>
      <ModalContent scrolling id="pin-overview-content">
        <HeaderSection showCustomerInformation={showCustomerInformation} appointment={appointment} />
        <PinSection pin={pin} pinStatusOptions={pinStatusOptions} />
        <InterventionSection intervention={intervention} />
        <ChecklistSection appointment={appointment} intervention={intervention} />
        <ImportantNotes pinLog={pinLog} />
        {pinLog && (
          <div className="log-section">
            <h2 className="title"> Logs</h2>
            <PinLog
              pinLog={pinLog}
              pinTypeData={pinTypeData}
              pinOrderData={pinOrderData}
              pinStatusData={pinStatusOptions}
              pinWarrantyData={pinWarrantyData}
              showActionButtons={false}
              showTitle={false}
            />
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};

import { useMutation, useQueryClient } from "@tanstack/react-query";
import _isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";

import ENV from "config/Env";
import { usePrevious, useUser } from "hooks";
import { DealerDocumentation, ROLES } from "models";
import { useDealerCategories, useDealerLocations, useDealers, useGetDealersDocumentations } from "modules/DealerDocumentations/hooks";
import { addSelectAllOption, getNamesById } from "modules/DealerDocumentations/utils";
import apiInstance from "util/Api";
import { ITranslation } from "util/interfaces";
import { queryKeys } from "util/keyFactory";

type DealerDocumentationsProps = {
  t: ITranslation;
};

const allowedRoles = [ROLES.Consultant, ROLES.Supervisor, ROLES.WarrantyManager, ROLES.SupportAdmin, ROLES.ClaireAdmin, ROLES.SuperAdmin];

export const useDealerDocumentations = ({ t }: DealerDocumentationsProps) => {
  const [visibleToMechanic, setVisibleToMechanic] = useState(true);
  const { categoryId, categoryOptions, getCategoryName, setCategoryId } = useDealerCategories(t);
  const [addEditDealerDocumentationModal, setAddEditDealerDocumentationModal] = useState<DealerDocumentation | null>(null);
  const { dealers, dealerIds, dealersDropdownOptions, setDealerIds } = useDealers();
  const { dealerLocationIds, setDealerLocationIds, locations } = useDealerLocations();
  const { dealersDocumentations } = useGetDealersDocumentations({ dealerIds, dealerLocationIds, visibleToMechanic });
  const [modalDocumentation, setModalDocumentation] = useState<DealerDocumentation | null>(null);

  const user = useUser();
  const queryClient = useQueryClient();
  const prevDealerLocations = usePrevious(dealerLocationIds);
  const prevDealers = usePrevious(dealerIds);
  const prevVisibleToMechanic = usePrevious(visibleToMechanic);
  const dealersNames = getNamesById({ items: dealers, itemIds: dealerIds, defaultMessage: "All dealers", translation: "v8_all_dealers", t });
  const locationsNames = getNamesById({ items: locations, itemIds: dealerLocationIds, translation: "v8_all_locations", defaultMessage: "All locations", t });

  const onCloseDocumentation = () => setModalDocumentation(null);
  const isDealerDropdownVisible = () => allowedRoles.includes(user?.role_id as number);
  const handleDocumentation = (documentation: DealerDocumentation | null) => setModalDocumentation(documentation);
  const onSetDealerDocumentation = (documentation: DealerDocumentation | null) => setAddEditDealerDocumentationModal(documentation);

  const getCurrentLocationsOptions = () => {
    const selectedDealersLocations = locations.filter(location => dealerIds.includes(location.dealer_id));
    return selectedDealersLocations.map(location => ({
      key: location.id,
      value: location.id,
      text: location.name,
      icon: "location dot"
    }));
  };
  const filterDealerDocumentationByCategory = () =>
    !categoryId ? dealersDocumentations : dealersDocumentations?.filter(category => category.category_id === categoryId);

  useEffect(() => {
    if (_isEqual(prevDealerLocations, dealerLocationIds) || _isEqual(prevDealers, dealerIds) || prevVisibleToMechanic !== visibleToMechanic) {
      queryClient.invalidateQueries({ queryKey: [{ ...queryKeys.dealer.documentation }] });
    }

    if (dealerLocationIds.length === 0 || dealerIds.length === 0) {
      queryClient.resetQueries({ queryKey: [{ ...queryKeys.dealer.documentation }] });
    }
  }, [visibleToMechanic, dealerLocationIds, dealerIds]);

  return {
    dealersDocumentations: filterDealerDocumentationByCategory(),
    dealersDropdownOptions: addSelectAllOption(dealersDropdownOptions, t),
    categoryOptions,
    dealersNames,
    locationsNames,
    locationDropdownOptions: addSelectAllOption(getCurrentLocationsOptions(), t),
    onSetDealerDocumentation,
    categoryId,
    setCategoryId,
    dealerIds,
    setDealerIds,
    dealerLocationIds,
    setDealerLocationIds,
    visibleToMechanic,
    setVisibleToMechanic,
    addEditDealerDocumentationModal,
    modalDocumentation,
    handleDocumentation,
    getCategoryName,
    onCloseDocumentation,
    isDealerDropdownVisible
  };
};

export const uploadDocumentationFile = async (formData: FormData) => {
  const result = await apiInstance.post(`/documentations/upload`, formData, ENV.dealerBaseUrl);
  return result.data?.url;
};

export async function uploadFile(file: File) {
  const formData = new FormData();
  formData.append("file", file);
  const result = await uploadDocumentationFile(formData);
  return result;
}

export const createDocumentationMtn = () => {
  const queryClient = useQueryClient();
  const user = useUser();

  const { mutate, isPending } = useMutation({
    mutationFn: async (documentation: DealerDocumentation) => {
      const response = await apiInstance.post(`/documentations/create`, documentation, ENV.dealerBaseUrl);
      return response.data?.dealer_documentation_id;
    },

    onMutate: (documentation: DealerDocumentation) => {
      const previousQueryData = queryClient.getQueryData([{ ...queryKeys.dealer.documentation }]);
      const newQueryData = (old: DealerDocumentation[]) => [documentation, ...old];
      queryClient.setQueryData([{ ...queryKeys.dealer.documentation }], newQueryData);
      return { previousQueryData };
    },

    onError: (_, __, context) => {
      queryClient.setQueryData([{ ...queryKeys.dealer.documentation }], () => context?.previousQueryData);
    },

    onSuccess: res => {
      const newQueryData = (old: DealerDocumentation[]) => old.map(item => (item.id === 0 ? { ...item, id: res, created_by: user, created_by_id: user?.id } : item));
      queryClient.setQueryData([{ ...queryKeys.dealer.documentation }], newQueryData);
    }
  });

  return { mutate, isPending };
};

export const updateDocumentationMtn = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: async (documentation: DealerDocumentation) => {
      await apiInstance.post(`/documentations/update`, documentation, ENV.dealerBaseUrl);
    },
    onMutate: (documentation: DealerDocumentation) => {
      const previousQueryData = queryClient.getQueryData([{ ...queryKeys.dealer.documentation }]);
      const newQueryData = (old: DealerDocumentation[]) => old.map(item => (item.id === documentation.id ? { ...item, ...documentation } : item));
      queryClient.setQueryData([{ ...queryKeys.dealer.documentation }], newQueryData);

      return { previousQueryData };
    },

    onError: (_, __, context) => {
      queryClient.setQueryData([{ ...queryKeys.dealer.documentation }], () => context?.previousQueryData);
    }
  });

  return { mutate, isPending };
};

export const deleteDocumentationMtn = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: async (id: number) => {
      const formData = new FormData();
      formData.append("id", id.toString());
      await apiInstance.post(`/documentations/delete`, { id: id }, ENV.dealerBaseUrl);
    },

    onMutate: (id: number) => {
      const previousQueryData = queryClient.getQueryData([{ ...queryKeys.dealer.documentation }]);
      const newQueryData = (old: DealerDocumentation[]) => old.filter(item => item.id !== id);
      queryClient.setQueryData([{ ...queryKeys.dealer.documentation }], newQueryData);

      return { previousQueryData };
    },

    onError: (_, __, context) => {
      queryClient.setQueryData([{ ...queryKeys.dealer.documentation }], () => context?.previousQueryData);
    }
  });

  return { mutate, isPending };
};

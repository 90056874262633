import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Input } from "semantic-ui-react";

import Service from "components/UploadImage/service";
import "components/UploadImage/UploadImage.scss";
import { ITranslation } from "util/interfaces";

interface Props {
  path: string;
  onSuccess?: (url: string) => void;
  onError?: (reason: string) => void;
  formId: string;
}

export const UploadImage = ({ path, onSuccess, onError, formId }: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const t = useTranslation().t as ITranslation;

  const handleChangeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const fd = new FormData();
    fd.append(formId, event.target.files[0]);
    setFile(event.target.files[0]);
    try {
      const response = await Service.uploadImage(path, fd);

      if (response?.data?.URL) {
        if (onSuccess) onSuccess(response.data.URL);
      } else throw new Error(t("v8_something_went_wrong").message || "Something went wrong, please contact the administrator.");
    } catch (e) {
      let errMessage = "";
      if (e instanceof Error) {
        errMessage = e.message;
      } else {
        errMessage = t("v8_something_went_wrong").message || "Something went wrong, please contact the administrator.";
      }
      if (onError) onError(errMessage);
    }
  };

  const handleUpload = () => {
    (document as Document).getElementById(formId)?.click();
  };

  const handleDelete = () => {
    const fd = new FormData();
    fd.delete(formId);
    setFile(null);
  };

  return (
    <div className="upload-container">
      <div className="UploadImage new">
        <div className="button" onClick={handleUpload}>
          <p className="btn-text">{t("v8_choose_file").message || "Choose File"}</p>
        </div>
        <p className="fileInput-ellipsis">{file?.name || t("v8_choose_file").message || "Choose File"}</p>
      </div>
      <Input id={formId} accept="image/png, image/gif, image/jpeg" type="file" onChange={handleChangeFile} />

      <div className="upload-view">
        {file ? <img alt="preview image" src={URL.createObjectURL(file)} /> : <Icon className="image i-grey" size="huge" />}
        <div className="delete-container" onClick={handleDelete}>
          <Icon className="trash i-grey" />
        </div>
      </div>
    </div>
  );
};

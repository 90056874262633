import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownItem, DropdownMenu, Icon } from "semantic-ui-react";

import { SNOOZE_DEPARTMENT, SNOOZE_STATUS, SNOOZE_TYPE, useCan } from "components";
import "components/CheckList/CheckList.scss";
import { useDealersLocations } from "hooks";
import { ChecklistTemplate, QuestionResult, QuestionResultImage, SnoozeItem } from "models";
import { GenericAttachmentData } from "modules/AppointmentDetails/components/Interventions/components";
import { InterventionData } from "modules/AppointmentDetails/hooks";
import { ITranslation } from "util/interfaces";

export enum MODAL_TYPE {
  Intervention = "intervention",
  Snooze = "snooze",
  EditSnooze = "editsnooze"
}

export interface ExtendedInterventionData extends InterventionData {
  modalType: MODAL_TYPE;
}

export type QuestionResultData = {
  modalType: MODAL_TYPE;
  title: string;
  description: string;
  solution: string;
  question_result_id: number;
  id?: number;
  images?: QuestionResultImage[];
  appointment_id?: number;
  snoozed?: boolean;
  snooze_history?: SnoozeItem[];
  snooze_type_id?: SNOOZE_TYPE;
  snooze_status_id?: SNOOZE_STATUS;
  snooze_department_id?: SNOOZE_DEPARTMENT;
  snooze_date?: Date;
  note?: string;
  attachments?: GenericAttachmentData[];
  checkin_remark_id?: number;
};

export type ActionModalSelectorData = ExtendedInterventionData | QuestionResultData;

type ActionModalSelectorProps = {
  onSelect: (data: ActionModalSelectorData) => void;
  data: Partial<QuestionResult>;
  attachments?: GenericAttachmentData[];
  template?: ChecklistTemplate | null;
  editSnooze?: boolean;
  onlyIntervention?: boolean;
  isConvertToInterventionButtonDisabled?: boolean;
};

interface ActionSelectorOption {
  icon: JSX.Element;
  text: string;
  value: string;
  disabled?: boolean;
}

export const ActionModalSelector = ({
  data,
  attachments = [],
  template,
  onSelect,
  editSnooze,
  onlyIntervention,
  isConvertToInterventionButtonDisabled = false
}: ActionModalSelectorProps) => {
  const { selectedLocation } = useDealersLocations();
  const canAddSnooze = useCan("add", "snooze");
  const canAddIntervention = useCan("add", "interventions");
  const t = useTranslation().t as ITranslation;

  const snoozeOption = editSnooze
    ? { icon: <Icon className="pen" />, text: t("v8_edit").message || "Edit", value: MODAL_TYPE.EditSnooze }
    : { icon: <Icon className="clock" />, text: t("v8_snooze").message || "Snooze", value: MODAL_TYPE.Snooze };

  const initOptions: ActionSelectorOption[] = !canAddIntervention
    ? []
    : [
        {
          icon: <Icon className="screwdriver wrench" />,
          text: t("v8_convert_to_intervention").message || "Convert to Intervention",
          value: "intervention",
          disabled: isConvertToInterventionButtonDisabled
        }
      ];

  const [options, setOptions] = useState(initOptions);

  const readOptions = () => {
    if (!canAddSnooze) return;

    const optionsList = [...initOptions];
    if (
      !onlyIntervention &&
      selectedLocation?.schedule_enabled &&
      (data.question_options?.find(option => option.name === "snooze_enabled")?.value || !data.question_options)
    ) {
      if (!template || template?.snooze_enabled) {
        optionsList.push(snoozeOption);
      }
    }
    setOptions(optionsList);
  };

  useEffect(() => {
    readOptions();
  }, [selectedLocation, data]);

  const handleSelect = (value: string) => {
    if (value === MODAL_TYPE.Intervention) {
      onSelect({
        modalType: value,
        title: data.title || "",
        description: data.raw || "",
        solution: data.solution || "",
        customer_ok: false,
        appointment_id: data.appointment_id,
        question_result_id: data.id,
        attachments,
        price: data.price || 0,
        checkin_remark_id: data.checkin_remark_id,
        checkin_result_id: data.checkin_result_id
      });
    } else if (value === MODAL_TYPE.Snooze || value === MODAL_TYPE.EditSnooze) {
      onSelect({
        modalType: value,
        title: data.title || "",
        description: data.raw || "",
        solution: data.solution || "",
        question_result_id: data.id || 0,
        appointment_id: data.appointment_id,
        snooze_history: data.snooze_history,
        attachments: value === MODAL_TYPE.Snooze ? attachments : []
      });
    }
  };
  if (!options.length) return null;

  return (
    <Dropdown className="action-dropdown" floating icon={null} trigger={<Icon className="plus grey" />}>
      <DropdownMenu className="dropdown-menu">
        {options.map(option => (
          <DropdownItem key={option.value} {...option} onClick={() => handleSelect(String(option.value))} />
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

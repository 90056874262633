import { useTranslation } from "react-i18next";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";

import { PinModel } from "models";
import { PinOptions } from "modules/AppointmentDetails/components/Interventions/components/PinOverview";
import "modules/AppointmentDetails/components/Interventions/components/PinOverview/components/PinSection/PinSection.scss";
import { ITranslation } from "util/interfaces";

type Props = {
  pin?: PinModel;
  pinStatusOptions: PinOptions[];
};

export const PinSection = ({ pin, pinStatusOptions }: Props) => {
  if (!pinStatusOptions || !pin) return null;
  const t = useTranslation().t as ITranslation;
  const pinStatus = pinStatusOptions.find(pinElement => pinElement.value === pin.pin_status_id);

  return (
    <div className="PinSectionContent">
      <div className="pin-section">
        <Grid columns="equal" padded="horizontally" className="grid-header-container">
          <GridRow verticalAlign="middle">
            <GridColumn>
              <span className="grid-title-text"> {`${t("v8_support").message} #` || "Support #"} </span>
            </GridColumn>
            <GridColumn>
              <span className="grid-title-text"> {`${t("v8_claim").message} #` || "Claim #"}</span>
            </GridColumn>
            <GridColumn>
              <span className="grid-title-text"> {t("v8_reference").message || "Reference"} </span>
            </GridColumn>
            <GridColumn>
              <span className="grid-title-text">{t("v8_status").message || "Status"}</span>
            </GridColumn>
          </GridRow>
        </Grid>
        <Grid columns="4" padded="horizontally" className="results-grid">
          <GridRow verticalAlign="middle">
            <GridColumn>
              <span className="grid-field-text">{pin.support_nr} </span>
            </GridColumn>
            <GridColumn>
              <span className="grid-field-text">{pin.claim_nr} </span>
            </GridColumn>
            <GridColumn>
              <span className="grid-field-text">{pin.ref_nr}</span>
            </GridColumn>
            <GridColumn>{pinStatus && <span className="grid-field-text">{pinStatus.text}</span>}</GridColumn>
          </GridRow>
        </Grid>
      </div>
    </div>
  );
};

import moment from "moment";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import "components/UploadedFilesPreview/UploadedFilesPreview.scss";
import { useUser } from "hooks";
import { AppointmentNoteAttachment, SnoozeAttachment } from "models";
import { getNameFromURL } from "util/common";
import { ITranslation } from "util/interfaces";

type UploadedFilesPreviewProps = {
  attachments: SnoozeAttachment[] | Partial<AppointmentNoteAttachment>[] | null;
  onDeleteAttachment: (url: string) => void;
};

export const UploadedFilesPreview = ({ attachments, onDeleteAttachment }: UploadedFilesPreviewProps) => {
  if (!attachments?.length) return null;
  const t = useTranslation().t as ITranslation;
  const user = useUser();

  return (
    <>
      {attachments.map((attachment, idx) => (
        <div className="add-update-attachments-container" key={idx}>
          <div>
            <Icon className="image" />
            <span className="attachment-name">{"name" in attachment ? attachment.name : getNameFromURL(attachment.url as string)}</span>
          </div>
          <div>
            <span className="attachment-uploaded-by">
              {t("v8_uploaded_by").message || "Uploaded by"} {"username" in attachment ? attachment.username : user?.username}
            </span>
            <span className="attachment-uploaded-date">{moment(attachment.created_on).format("DD-MM-YYYY HH:mm")}</span>
            <Icon className="trash" onClick={() => onDeleteAttachment(attachment.url as string)} />
          </div>
        </div>
      ))}
    </>
  );
};

import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Popup } from "semantic-ui-react";

import { CUSTOM_CONFIRM_TYPES, CheckList, CustomConfirm, DetailsCards } from "components";
import { Appointment } from "models";
import { OrderedChecklist } from "modules/AppointmentDetails";
import { ChecklistPDF } from "modules/AppointmentDetails/components";
import "modules/AppointmentDetails/components/StandardList/StandardList.scss";
import { useChecklist } from "modules/AppointmentDetails/hooks";
import { useConnectedInterventionId } from "modules/AppointmentDetails/hooks/index";
import { ITranslation } from "util/interfaces";

type StandardListProps = {
  isExpanded: boolean;
  data: OrderedChecklist;
  appointment: Appointment;
  id?: string;
  icon?: string;
  latestChecklist: number;
};

export const StandardList = ({ id, isExpanded, data, appointment, icon, latestChecklist }: StandardListProps) => {
  const { deleteCheckList, canDeleteCheck } = useChecklist(appointment.id);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const t = useTranslation().t as ITranslation;

  const { connectedInterventionId } = useConnectedInterventionId();

  if (!data.question_items || (connectedInterventionId && connectedInterventionId !== data.intervention_id)) return null;

  const isLatestCheckList = latestChecklist === data.id;

  const standardListTitle = (
    <div className="StandardList-title">
      {data.purpose ? (
        <Popup
          wide
          basic
          content={<span>{data.purpose}</span>}
          trigger={<span className="ellipsis">{`#${data.order} ${data.checklist.name} - ${data.purpose}`} </span>}
        />
      ) : (
        <span className="ellipsis">{`#${data.order} ${data.checklist.name}`}</span>
      )}

      {!data.checklist.template?.hide_timestamp && data.mechanic && (
        <span className="mechanic-info">
          <span>{` - ${data.mechanic.first_name} ${data.mechanic.last_name}`}</span>
          <span className="separator">-</span>
          <span className="first-date">{`${moment(data.created_on).subtract(data.duration, "seconds").format("HH:mm DD-MM-YY")}`}</span>
          <Icon className="arrow right" />
          <span className="second-date">{`${moment(data.created_on).format("HH:mm DD-MM-YY")}`}</span>
        </span>
      )}
    </div>
  );

  const toggleShowAll = () => setShowAll(prev => !prev);

  return (
    <DetailsCards
      id={id}
      title={standardListTitle}
      icon={icon || "list"}
      isExpanded={isExpanded}
      isChecklistTitle={true}
      backgroundColor={data.checklist?.template?.primary_color || ""}
      borderColor={data.checklist?.template?.text_color || ""}
      textColor={data.checklist?.template?.text_color || ""}
      rightContent={
        <div className="full end auto-width">
          {isLatestCheckList && canDeleteCheck && (
            <Button className="-appointment-status icon" size="small" onClick={() => setConfirmDeleteIsOpen(true)}>
              <Icon className="trash" color="green" />
            </Button>
          )}

          {data.checklist?.template?.total_pdf_enabled && (
            <ChecklistPDF appointment={appointment} checkId={data.id} isPreviewable={data.checklist.template?.is_previewable} />
          )}

          <Button className="-appointment-status icon" size="small" onClick={toggleShowAll}>
            <Icon className="bars" color={showAll ? "green" : "grey"} />
          </Button>

          <CustomConfirm
            isOpen={confirmDeleteIsOpen}
            handleCancel={() => setConfirmDeleteIsOpen(false)}
            handleConfirm={() => deleteCheckList.mutate(data.appointment_id)}
            confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action?"}
            isLoading={deleteCheckList.isPending}
            type={CUSTOM_CONFIRM_TYPES.Warning}
          />
        </div>
      }
    >
      <CheckList data={data.question_items} template={data.checklist?.template} showAll={showAll} appointment={appointment} />
    </DetailsCards>
  );
};

import moment from "moment";

import { Check } from "models";
import "modules/AppointmentDetails/components/Interventions/components/InterventionChecklist/InterventionChecklist.scss";

interface InterventionChecklistPropTypes {
  checklist: Check[];
}

export default function InterventionChecklist({ checklist }: InterventionChecklistPropTypes) {
  return (
    <div className="connected-checklists-container">
      {checklist.map(checklist => (
        <div className="connected-checklist" key={checklist.id}>
          <b>{checklist.checklist?.name}</b>
          {checklist.mechanic && (
            <span>
              - {checklist.mechanic.first_name} {checklist.mechanic.last_name} -
            </span>
          )}
          <span>
            {moment(checklist.created_on).format("HH:mm DD-MM-YYYY")} {">"} {moment(checklist.updated_on).format("HH:mm DD-MM-YYYY")}{" "}
          </span>
        </div>
      ))}
    </div>
  );
}

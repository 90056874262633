import { User } from "models";
import ApiLoadable from "models/ApiLoadable";

export enum APPOINTMENT_NOTE_TYPES {
  Wo = 1,
  Main,
  CallCustomer,
  BackOrder,
  Info,
  Attachment,
  BillNote,
  TireScanner,
  TemporaryDriverNote,
  RecurringCar,
  Parking,
  DmsNote
}

export class AppointmentNoteAttachment {
  id: number = 0;
  name: string = "";
  url: string = "";
  type: string = "";
  user_id: number = 0;
  appointment_id: number = 0;
  appointment_note_id: number = 0;
  username: string | null = null;
  created_on?: string | null = null;
  updated_on?: string | null = null;
}

export class AppointmentNote extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  appointment_id: number = 0;
  user_id: number = 0;
  note: string = "";
  visible_for_mechanic: boolean = false;
  user?: User;
  updated_by?: User;
  appointment_note_type_id: number = 0;
  phone_nr: string = "";
  name?: string;
  email?: string;
  backorder_date?: string;
  attachments?: AppointmentNoteAttachment[];
}

import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";

import { PinTypeIcon } from "components";
import { PinModel } from "models";
import "modules/AppointmentDetails/components/Interventions/components/PinOverview/components/ImportantNotes/ImportantNotes.scss";
import { ITranslation } from "util/interfaces";

type Props = {
  pinLog?: PinModel[];
};

export const ImportantNotes = ({ pinLog }: Props) => {
  if (!pinLog || !pinLog.length || !pinLog.some(pin => pin.visible_important_items)) return null;
  const visiblePinLogs = pinLog.filter(pin => pin.visible_important_items);
  const t = useTranslation().t as ITranslation;

  return (
    <div className="ImportantNotes">
      <div className="title" style={{ margin: "10px 0px" }}>
        {t("v8_important_notes").message || "Important notes"}
      </div>

      {visiblePinLogs.map(item => (
        <div key={item.id} className="icon-text-container">
          <Label>
            <PinTypeIcon pin_type_id={item.pin_type_id} key={item.pin_type_id} isPrint />
          </Label>
          {item.keep_parts ? (
            <span>
              {t("v8_keep_parts_prefix").message || "KEEP PARTS"} {item.note}
            </span>
          ) : (
            item.note
          )}
        </div>
      ))}
    </div>
  );
};

import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form, Grid, GridColumn, GridRow } from "semantic-ui-react";

import { FileDropdown, SNOOZE_STATUS } from "components";
import InitialLogValues from "components/SnoozeModal/components/InitialLogValues";
import LogUpdates from "components/SnoozeModal/components/LogUpdates";
import "components/SnoozeModal/LogHistory.scss";
import { SnoozeItem } from "models/SnoozeItem";
import { getAllSnoozeAttachments } from "modules/AppointmentDetails/utils";
import { ITranslation } from "util/interfaces";

export type DropdownData = {
  key: number;
  value: number;
  text: string;
};

type LogHistoryProps = {
  data?: SnoozeItem[];
  snoozeTypeData: DropdownData[];
  snoozeStatusData: DropdownData[];
  snoozeDepartmentData: DropdownData[];
};

export const LogHistory = ({ data = [], snoozeTypeData, snoozeStatusData, snoozeDepartmentData }: LogHistoryProps) => {
  const t = useTranslation().t as ITranslation;

  const attachments = useMemo(() => {
    return getAllSnoozeAttachments(data);
  }, [data]);

  return (
    <Form.Group className="LogHistory-Container">
      <Form.Field width={16}>
        <div className="log-history-heading-container">
          <span className="log-history-title-container">{t("v8_log_history").message || "Log history"}</span>
          {attachments.length > 0 && (
            <div className="log-history-attachments-container">
              <FileDropdown attachments={attachments} iconColor="#000000" />
            </div>
          )}
        </div>
        <div className={`LogHistory ${data.length === 0 ? "empty" : ""}`}>
          {data.length ? (
            data?.map((snoozeItem, i) => (
              <div className="inner-row" key={snoozeItem.id}>
                <Grid>
                  <GridRow>
                    <GridColumn width={12}>
                      <GridRow>
                        <strong>
                          {snoozeItem.user?.first_name} {snoozeItem.user?.last_name}
                        </strong>
                        {" - "}
                        {moment(snoozeItem.created_on).format("HH:mm DD-MM-YYYY")}
                        {snoozeItem.snooze_status_id === SNOOZE_STATUS.Deleted && (
                          <>
                            {" - "}
                            {<strong style={{ color: "red" }}>{t("v8_deleted").message || "Deleted"}</strong>}
                          </>
                        )}
                        {snoozeItem.mechanic_fixed && (
                          <>
                            {" - "}
                            {<strong style={{ color: "blue" }}>{t("v8_marked_as_fixed").message || "Marked as fixed"}</strong>}
                          </>
                        )}
                      </GridRow>
                      {i === data.length - 1 || data[i - 1]?.snooze_status_id === SNOOZE_STATUS.Deleted ? (
                        <InitialLogValues
                          snoozeItem={snoozeItem}
                          snoozeTypeData={snoozeTypeData}
                          snoozeDepartmentData={snoozeDepartmentData}
                          snoozeStatusData={snoozeStatusData}
                        />
                      ) : (
                        <LogUpdates
                          snoozeItem={snoozeItem}
                          prevSnoozeItem={data[i + 1]}
                          snoozeTypeData={snoozeTypeData}
                          snoozeDepartmentData={snoozeDepartmentData}
                          snoozeStatusData={snoozeStatusData}
                        />
                      )}
                      {!snoozeItem.mechanic_fixed && snoozeItem.note && <GridRow>{snoozeItem.note}</GridRow>}
                    </GridColumn>
                  </GridRow>
                </Grid>
              </div>
            ))
          ) : (
            <p>{t("v8_no_log_history").message || "No log history"}</p>
          )}
        </div>
      </Form.Field>
    </Form.Group>
  );
};

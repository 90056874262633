import { useState } from "react";
import { Icon } from "semantic-ui-react";

import "components/AttachmentPreview/AttachmentPreview.scss";
import { FilePreview } from "components/FilePreview";
import { getNameFromURL } from "util/common";

type GenericData = {
  id?: number;
  url: string;
  name?: string;
};

type GenericListProps<T extends GenericData> = {
  attachments?: T[];
  onDeleteRequest?: (data: T, index: number) => void;
  canDelete?: boolean;
};

const fileNameMaxLength = 14;

export const AttachmentPreview = <T extends GenericData>({ attachments, onDeleteRequest, canDelete }: GenericListProps<T>) => {
  if (!attachments?.length) return null;
  const [showPreview, setShowPreview] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);

  const togglePreview = () => {
    setShowPreview((prev: boolean) => !prev);
  };

  const handlePreview = (url: string) => {
    setPreviewIndex(attachments.findIndex(attachment => attachment.url === url));
    togglePreview();
  };

  const handleDeleteAttachment = (e: React.MouseEvent, item: T, index: number) => {
    e.stopPropagation();
    onDeleteRequest && onDeleteRequest(item, index);
  };

  const shortenFileName = (text: string, delimiter: number) => {
    if (text.length > delimiter) return `...${text.slice(-delimiter)}`;
    return text;
  };

  const images = attachments.filter(attachment => attachment.url.match(/\.(jpeg|jpg|gif|png)/));
  const videos = attachments.filter(attachment => attachment.url.match(/\.(mp4|mov|mpeg|mkv|webm)/));
  const files = attachments.filter(attachment => !attachment.url.match(/\.(jpeg|jpg|gif|png)/) && !attachment.url.match(/\.(mp4|mov|mpeg|mkv|webm)/));

  return (
    <div className="AttachmentPreview-Container">
      {images.map((item, index) => {
        return (
          <div key={item.url} className="media-view" onClick={() => handlePreview(item.url)}>
            <img src={item.url} />
            <div className="media-download">
              <div className="media-wrapper">
                <a href={item.url} target="_blank" rel="noreferrer">
                  <Icon className="download blue" />
                </a>
              </div>
              {onDeleteRequest && canDelete && item.id && (
                <div className="media-wrapper" onClick={e => handleDeleteAttachment(e, item, index)}>
                  <Icon className="trash grey" />
                </div>
              )}
            </div>
          </div>
        );
      })}
      {videos.map((item, index) => {
        return (
          <div key={item.url} className="media-view" onClick={() => handlePreview(item.url)}>
            <video controls key={item.url}>
              <source src={item.url} type="video/mp4" />
              <source src={item.url} type="video/quicktime" />
            </video>
            <div className="media-download">
              <div className="media-wrapper">
                <a href={item.url} target="_blank" rel="noreferrer">
                  <Icon className="download blue" />
                </a>
              </div>
              {onDeleteRequest && canDelete && item.id && (
                <div className="media-wrapper" onClick={e => handleDeleteAttachment(e, item, index)}>
                  <Icon className="trash grey" />
                </div>
              )}
            </div>
          </div>
        );
      })}
      {files.map((item, index) => {
        const name = item.name || getNameFromURL(item.url);
        return (
          <div key={item.url} className="media-view file-view" onClick={() => handlePreview(item.url)}>
            <div className="media-other">
              <Icon size="huge" className="file" />
              <p>{shortenFileName(name, fileNameMaxLength)}</p>
            </div>
            <div className="media-download">
              <div className="media-wrapper">
                <a href={item.url} target="_blank" rel="noreferrer">
                  <Icon className="download blue" />
                </a>
              </div>
              {onDeleteRequest && canDelete && item.id && (
                <div className="media-wrapper" onClick={e => handleDeleteAttachment(e, item, index)}>
                  <Icon className="trash grey" />
                </div>
              )}
            </div>
          </div>
        );
      })}
      <FilePreview attachments={attachments} currentIndex={previewIndex} open={showPreview} onClose={togglePreview} />
    </div>
  );
};

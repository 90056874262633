import { useEffect, useState } from "react";

import ENV from "config/Env";
import apiInstance from "util/Api";

type LandingPage = {
  id: number;
  name: string;
};

export const useAccountLandingPages = () => {
  const [landingPagesList, setLandingPagesList] = useState<LandingPage[]>([]);

  useEffect(() => {
    const fetchLandingPages = async () => {
      const data = await apiInstance.get("/iam/landing_pages", ENV.apiBase);
      setLandingPagesList((data?.data as LandingPage[]) || []);
    };

    fetchLandingPages();
  }, []);

  return { landingPagesList };
};

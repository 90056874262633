import { DefaultError, useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { QUESTION_STATUSES } from "models";
import ApiInstance from "util/Api";

type CriticalHistoryReq = {
  appointment_id: number;
  page?: number;
};

type QuestionOptions = {
  id: number;
  name: string;
  value: boolean;
};

export type CriticalHistory = {
  wo_nr: string;
  created_on: string;
  mechanic_fixed: boolean;
  title: string;
  status: number;
  price: number;
  customer_approved: boolean;
  completed: boolean;
  question_options: QuestionOptions[];
  raw: string;
  snoozed: boolean;
  pinned: boolean;
};

type UseItemsNotRepairedType = {
  loading: boolean;
  data: CriticalHistory[];
  error: DefaultError | null;
  fetchItemsNotRepaired: (data: CriticalHistoryReq) => void;
};

export const useItemsNotRepaired = (): UseItemsNotRepairedType => {
  const [data, setData] = useState<CriticalHistory[]>([]);

  const fetchCriticalHistory = async ({ appointment_id, page = 1 }: CriticalHistoryReq) => {
    const res = await ApiInstance.post("/leads/advised_critical_history", { appointment_id, page });
    return res.data.question_results;
  };

  const mutation = useMutation({
    mutationFn: fetchCriticalHistory,
    onSuccess: (response: CriticalHistory[]) => {
      const itemsNotRepaired = response.filter(
        (question: any) => !question.mechanic_fixed && [QUESTION_STATUSES.Advice, QUESTION_STATUSES.Necessary].includes(question.status)
      );

      setData(itemsNotRepaired);
    }
  });

  return { data, loading: mutation.isPending, error: mutation.error, fetchItemsNotRepaired: mutation.mutate };
};

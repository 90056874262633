import { faTag, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Label } from "semantic-ui-react";

import { KIOSK_COMMUNICATION_EVENT_TYPE, KioskCommunication, KioskCommunicationEvent } from "models";

type EventInfoProps = {
  comm: KioskCommunication;
  event: KioskCommunicationEvent;
};

export const EventInfo = ({ event, comm }: EventInfoProps) => {
  const fullname = comm.customer && `${comm.customer.firstname} ${comm.customer.surname}`;

  return (
    <Grid.Row width={16} className="event-info-rows">
      {event.type === KIOSK_COMMUNICATION_EVENT_TYPE.KioskCheckInAnswered && (
        <div className="kiosk-comm-answered-event">
          <Label className="kiosk-label-icon" color="green">
            <FontAwesomeIcon icon={faTag} className="mr-5" />
            <span>{event.kiosk_label_number}</span>
          </Label>
          <span>{event.kiosk_name}</span>
        </div>
      )}
      {event.type === KIOSK_COMMUNICATION_EVENT_TYPE.LabelDetached && event.user && (
        <span>
          <FontAwesomeIcon icon={faUser} className="i-green mr-5" />
          {`${event.user.first_name} ${event.user.last_name}`.trim()}
        </span>
      )}
      {event.type === KIOSK_COMMUNICATION_EVENT_TYPE.KioskCheckInOpened && fullname && (
        <span>
          <FontAwesomeIcon icon={faUser} className="i-green mr-5" />
          {fullname}
        </span>
      )}
    </Grid.Row>
  );
};

import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ApiInstance from "util/Api";

export const useRestoreAppointmentMutation = (appointment_id: number) => {
  const restoreAppointment = async () => {
    await ApiInstance.post("/appointments/restore", { appointment_id });
  };

  // TODO: make an abstraction for useMutation that always does the toast error for us so we don't need to set it every time
  // TODO: also make it force you to have optimistic update in place
  return useMutation({ mutationFn: restoreAppointment, onError: e => toast.error(e.message) });
};

import { Ref, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Popup } from "semantic-ui-react";

import "components/Editable/Editable.scss";
import { useHover } from "hooks";

type EditableProps = {
  value: string;
  isNumberInput?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onSubmit?: (t: string) => void;
  hoverable?: boolean;
  valueClassName?: string;
  onValidation?: (value: string) => boolean;
  errorMessage?: string | null;
};
export const Editable = ({ value, isNumberInput, disabled, onSubmit, onValidation, placeholder, hoverable, valueClassName = "", errorMessage = null }: EditableProps) => {
  const [text, setText] = useState(value);
  const [canEdit, setCanEdit] = useState(false);
  const ref = useRef(null);
  const popupRef: Ref<HTMLParagraphElement> | undefined = useRef(null);

  useEffect(() => {
    setText(value);
  }, [value]);

  const handleCanEdit = () => {
    if (!disabled) {
      setCanEdit(true);
    }
  };

  const handleFinishEditing = () => {
    setCanEdit(false);
    if (value !== text) {
      if (onValidation?.(text)) {
        onSubmit?.(text);
        return;
      } else if (onValidation && !onValidation(text)) {
        setText(value);
        toast.error(errorMessage);
        return;
      }
      onSubmit?.(text);
    }
  };

  useEffect(() => {
    if (canEdit && ref?.current) {
      const textInput: HTMLInputElement = ref.current;
      if (textInput) {
        const len = textInput.value.length;

        textInput.setSelectionRange(len, len);
        textInput.focus();
      }
    }
  }, [canEdit, ref]);

  const { canHover: textOverflow } = useHover(popupRef);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNumberInput && isNaN(Number(e.target.value))) return;

    setText(e.target.value);
  };

  return (
    <div className={`Editable ${disabled ? "disabled" : ""} ${text ? "" : "placeholder"}`}>
      {canEdit ? (
        <input type="text" ref={ref} value={text} onChange={handleChange} onBlur={handleFinishEditing} />
      ) : (
        <Popup
          disabled={!hoverable || textOverflow}
          hoverable
          content={text}
          trigger={
            <p ref={popupRef} onClick={handleCanEdit} className={valueClassName}>
              {text || placeholder}
            </p>
          }
        />
      )}
    </div>
  );
};

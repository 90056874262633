import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "semantic-ui-react";

import { KIOSK_COMMUNICATION_STATUS, KioskCommunication } from "models";
import { Content } from "modules/AppointmentDetails/components/KioskCommunicationLog/components";
import { ITranslation } from "util/interfaces";

type CommunicationsProps = {
  kiosk_communications: KioskCommunication[];
};

type CommunicationPane = {
  menuItem: string;
  render: () => ReactNode;
};

export const Communications = ({ kiosk_communications }: CommunicationsProps) => {
  const t = useTranslation().t as ITranslation;
  const panes: CommunicationPane[] = [];

  kiosk_communications
    .sort((comm1, comm2) => comm2.created_on.localeCompare(comm1.created_on))
    .forEach((comm: KioskCommunication) => {
      panes.push({
        menuItem: comm.status === KIOSK_COMMUNICATION_STATUS.KioskCheckInOpened ? t("v8_opened").message || "Opened" : t("v8_answered").message || "Answered",
        render: () => <Content comm={comm} />
      });
    });

  return panes.length > 0 ? <Tab menu={{ secondary: true }} panes={panes} /> : null;
};

import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ApiInstance from "util/Api";

export interface AppointmentStatusMutationData {
  new_status_identifier?: number;
  car_in_shop?: string | null;
  car_out_of_shop?: string | null;
}

export const useAppointmentStatusMutation = (appointment_id: number) => {
  const updateAppointmentStatus = async (data: AppointmentStatusMutationData) => {
    await ApiInstance.post("/appointments/update", { id: appointment_id, ...data });
  };

  return useMutation({ mutationFn: updateAppointmentStatus, onError: e => toast.error(e.message) });
};

import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "semantic-ui-react";

import "components/UploadFilesZone/UploadFilesZone.scss";
import { ITranslation } from "util/interfaces";

type UploadFilesZoneProps = {
  display: boolean;
  onFileInputChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onFileDragOver: (evt: React.DragEvent<HTMLInputElement>) => void;
  onFileDrop: (evt: React.DragEvent<HTMLInputElement>) => void;
  hasError: boolean;
};

export const UploadFilesZone = ({ display, onFileDragOver, onFileDrop, onFileInputChange, hasError }: UploadFilesZoneProps) => {
  if (!display) return null;

  const t = useTranslation().t as ITranslation;

  const triggerOnChangeOnFileInput = () => {
    document.getElementById("file-input")?.click();
  };

  return (
    <div className={`UploadFilesZone ${hasError ? "error" : ""}`} onDragOver={onFileDragOver} onDrop={onFileDrop}>
      <div>
        <span>
          {t("v8_drag_files").message || "Drag files"} {t("v8_or").message || "or"}
        </span>{" "}
        <span className="browse-text" onClick={triggerOnChangeOnFileInput}>
          {t("v8_browse").message || "Browse"}
        </span>
      </div>
      <div className="max-file-size">{t("v8_maximum_file_size").message || "Maximum file size"} 32MB</div>
      <Input type="file" id="file-input" onChange={onFileInputChange} placeholder="Browse" required multiple />
    </div>
  );
};

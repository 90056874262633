import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { getPriceForDisplay } from "modules/AppointmentDetails/utils";
import { ITranslation } from "util/interfaces";

export type KioskCommService = {
  title: string;
  price: number;
};

type ServicesProps = {
  title: string;
  services?: KioskCommService[];
  vat?: number;
};

export const Services = ({ title, services, vat }: ServicesProps) => {
  if (!services?.length) return null;

  const t = useTranslation().t as ITranslation;

  return (
    <div className="kiosk-comm-section-container">
      <div className="kiosk-comm-section-header">{title}</div>

      <Grid>
        <Grid.Column width={16}>
          <div className="services-header">
            <span>{t("v8_title").message || "Title"}</span>
            <span>{vat ? t("v8_price_inc_vat").message || "Price (incl. VAT)" : t("v8_price_excl_vat").message || "Price (excl. VAT)"}</span>
          </div>
          {services.map((s, key) => (
            <div className="service" key={key}>
              <span>{s.title}</span>
              <span>{vat ? getPriceForDisplay(s.price * (1 + vat / 100)) : getPriceForDisplay(s.price)} €</span>
            </div>
          ))}
        </Grid.Column>
      </Grid>
    </div>
  );
};

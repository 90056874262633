import moment from "moment";
import { useTranslation } from "react-i18next";

import { FileDropdown } from "components";
import { AppointmentNote } from "models/AppointmentNote";
import { AppointmentNoteEditBtn, AppointmentNoteTypeIconLabel } from "modules/AppointmentDetails/components/AppointmentNotes/components";
import "modules/AppointmentDetails/components/AppointmentNotes/components/AppointmentNoteRow/AppointmentNoteRow.scss";
import { ITranslation } from "util/interfaces";

type AppointmentNoteRowPorps = {
  appointmentNote: AppointmentNote;
  onSelectNote?: () => void;
};

export const AppointmentNoteRow = ({ appointmentNote, onSelectNote }: AppointmentNoteRowPorps) => {
  const {
    appointment_note_type_id: appointmentNoteType,
    attachments,
    note,
    user_id: userId,
    updated_by: updatedBy,
    updated_on: updatedOn,
    created_on: createdOn,
    user,
    phone_nr: phoneNr
  } = appointmentNote;
  const t = useTranslation().t as ITranslation;

  return (
    <div className="AppointmentNoteRow">
      <div className="note-info-container">
        <div className="icon-btn-container">
          <AppointmentNoteTypeIconLabel type={appointmentNoteType} />
          <AppointmentNoteEditBtn type={appointmentNoteType} onSelectNote={onSelectNote} />
        </div>
        {phoneNr && <div className="appointment-note-phone-number">{phoneNr}</div>}
        {!!attachments?.length && (
          <div className="appointment-note-attachments-container">
            <FileDropdown attachments={attachments} />
          </div>
        )}
        <div className="appointment-note-text">{note}</div>
      </div>
      <div className="note-user-date-info-container">
        <div className="note-user-date-info">
          {updatedBy ? (
            userId !== 0 ? (
              <>
                <span className="user-name">{`${updatedBy.first_name} ${updatedBy.last_name} ${t("v8_on").message || "on"}`}</span>
                <span>{moment(updatedOn).format("DD-MM-YYYY HH:mm")}</span>
              </>
            ) : (
              `${t("v8_updated_on").message || "Updated on"} ${moment(updatedOn).format("DD-MM-YYYY HH:mm")}`
            )
          ) : userId !== 0 && user ? (
            <>
              <span className="user-name">{`${user.first_name} ${user.last_name} ${t("v8_on").message || "on"}`}</span>
              <span>{moment(createdOn).format("DD-MM-YYYY HH:mm")}</span>
            </>
          ) : (
            `${t("v8_added_on").message || "Added on"} ${moment(createdOn).format("DD-MM-YYYY HH:mm")}`
          )}
        </div>
      </div>
    </div>
  );
};

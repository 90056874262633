import { faRectangleList, faShieldHalved, faShop, faThumbTack, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid, GridColumn, Icon, Label } from "semantic-ui-react";

import { getShopIconColor } from "components";
import "components/PinLog/PinLog.scss";
import { PIN_STATUS, PinModel } from "models";
import { ITranslation } from "util/interfaces";

type PinOptions = {
  key: string;
  text: string | undefined;
  value: number;
};

type PinLogProps = {
  pinTypeData: PinOptions[];
  pinWarrantyData: PinOptions[];
  pinStatusData: PinOptions[];
  pinOrderData: PinOptions[];
  pinLog?: PinModel[];
  showActionButtons?: boolean;
  showMechanic?: boolean;
  showTitle?: boolean;
};

export const PinLog = ({ pinTypeData, pinWarrantyData, pinStatusData, pinOrderData, pinLog, showMechanic, showTitle = true, showActionButtons = true }: PinLogProps) => {
  const t = useTranslation().t as ITranslation;

  if (!pinLog?.length) return;

  const sortedPinLog = pinLog.sort((a, b) => moment(b.created_on).diff(moment(a.created_on)));

  return (
    <div className="PinLog">
      {showTitle && (
        <Grid className="PinLogHistoryHeading">
          <GridColumn width={14}>
            <p>{t("v8_log_history").message || "Log history"}</p>
          </GridColumn>
          {showMechanic && (
            <GridColumn width={2} className="right-align">
              <p>{t("v8_mechanic").message || "Mechanic"}</p>
            </GridColumn>
          )}
        </Grid>
      )}
      <div className="PinLogHistory">
        {sortedPinLog.map((currentPin: PinModel, i: number) => {
          const previousPin = sortedPinLog[i + 1];
          const pinType = currentPin.pin_type_id !== previousPin?.pin_type_id && pinTypeData?.find(item => item.value === currentPin.pin_type_id)?.text;
          const pinWarrantyType =
            currentPin.warranty_type_id !== previousPin?.warranty_type_id && pinWarrantyData?.find(item => item.value === currentPin.warranty_type_id)?.text;
          const pinStatusType = currentPin.pin_status_id !== previousPin?.pin_status_id && pinStatusData?.find(item => item.value === currentPin.pin_status_id)?.text;
          const pinOrderType = currentPin.order_status !== previousPin?.order_status && pinOrderData?.find(item => item.value === currentPin.order_status)?.text;

          return (
            <Grid key={currentPin.id}>
              <GridColumn width={14}>
                <p>
                  <strong>
                    {t("v8_edited_by").message || "Edited by"} {currentPin.is_dms ? "DMS " : `${currentPin.user?.first_name} ${currentPin.user?.last_name} on `}
                  </strong>{" "}
                  {`${moment(currentPin.updated_on).format("DD-MM-YYYY HH:mm")}`}
                </p>

                <div className="logInfo">
                  <>
                    {currentPin.manufacturer_id && currentPin.manufacturer_id > 0 && currentPin.manufacturer_id !== previousPin?.manufacturer_id && (
                      <Label basic className="log-label">
                        <FontAwesomeIcon icon={faUser} className="i-grey mr-5" />
                        <strong>
                          {t("v8_granted_access_to_the_manufacturer").message || "granted access to the manufacturer"} {currentPin.manufacturer?.first_name}{" "}
                          {currentPin.manufacturer?.last_name}
                        </strong>
                      </Label>
                    )}
                    {currentPin.visible_mechanic && currentPin.visible_mechanic !== previousPin?.visible_mechanic && (
                      <Label basic className="log-label">
                        <FontAwesomeIcon icon={faUser} className="i-grey mr-5" />
                        <strong>{t("v8_visible_mechanic_text").message || "Visible for mechanic"}</strong>
                      </Label>
                    )}
                    {currentPin.pin_status_id === PIN_STATUS.Deleted && <>{<strong style={{ color: "red" }}>{t("v8_deleted").message || "Deleted"}</strong>}</>}
                    {currentPin.mechanic_fixed && currentPin.mechanic_fixed !== previousPin?.mechanic_fixed && (
                      <Label basic className="log-label">
                        <FontAwesomeIcon icon={faUser} className="i-grey mr-5" />
                        <strong style={{ color: "blue" }}>{t("v8_marked_as_fixed").message || "Marked as fixed"}</strong>
                      </Label>
                    )}
                    {currentPin.info && currentPin.info !== previousPin?.info && (
                      <Label basic className="log-label">
                        <FontAwesomeIcon icon={faUser} className="i-grey mr-5" />
                        <strong style={{ color: "blue" }}>{t("v8_marked_as_info").message || "Marked as info"}</strong>
                      </Label>
                    )}
                  </>

                  {(pinWarrantyType || pinType) && (
                    <Label basic className="log-label">
                      <FontAwesomeIcon icon={pinWarrantyType ? faShieldHalved : faThumbTack} className="i-grey mr-5" />
                      {pinWarrantyType || pinType}
                    </Label>
                  )}

                  {pinOrderType && (
                    <Label basic className="log-label">
                      <FontAwesomeIcon icon={faShop} color={getShopIconColor(currentPin.order_status as number)} className="i-grey mr-5" />
                      {pinOrderType}
                    </Label>
                  )}
                  {pinStatusType && (
                    <Label basic className="log-label">
                      <FontAwesomeIcon icon={faRectangleList} className="i-grey mr-5" />
                      {pinStatusType}
                    </Label>
                  )}
                  {currentPin.claim_nr && currentPin.claim_nr !== previousPin?.claim_nr && (
                    <Label basic className="log-label">
                      # {t("v8_claim").message || "Claim"}: {currentPin.claim_nr}
                    </Label>
                  )}
                  {currentPin.support_nr && currentPin.support_nr !== previousPin?.support_nr && (
                    <Label basic className="log-label">
                      # {t("v8_support").message || "Support"}: {currentPin.support_nr}
                    </Label>
                  )}
                  {currentPin.ref_nr && currentPin.ref_nr !== previousPin?.ref_nr && (
                    <Label basic className="log-label">
                      # {t("v8_reference").message || "Reference"}: {currentPin.ref_nr}
                    </Label>
                  )}
                  <p className="logHistoryParagraph">
                    {currentPin.pin_status_id === PIN_STATUS.Deleted
                      ? t("v8_deleted").message || "Deleted"
                      : currentPin.keep_parts && currentPin.pin_status_id !== PIN_STATUS.Deleted
                        ? `${t("v8_keep_parts").message || "Keep parts"}: ${currentPin.note}`
                        : currentPin.note}
                  </p>
                </div>
              </GridColumn>
              {showActionButtons && (
                <GridColumn width={2}>
                  {currentPin.note && (
                    <div className="icons-right">
                      <Icon className={`eye ${currentPin.visible_mechanic ? "green" : "red"}`} />
                    </div>
                  )}
                </GridColumn>
              )}
            </Grid>
          );
        })}
      </div>
    </div>
  );
};

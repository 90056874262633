import { useTranslation } from "react-i18next";
import { Button, GridColumn, GridRow, Icon, Popup } from "semantic-ui-react";

import { useCan } from "components";
import { Appointment, Tyre, TyreReplacement } from "models";
import useTyre from "modules/AppointmentDetails/components/Tyre/hooks/useTyre";
import { getTyreDescription, size } from "modules/AppointmentDetails/components/Tyre/TyreTable";
import { getTyreSeasonIcon, isCarReadyOrQualityCheckDoneOrCanceled } from "util/common";
import { ITranslation } from "util/interfaces";

type TyreTableItemProps = {
  data: TyreReplacement;
  appointment: Appointment;
};

export const TyreReplacementTableItem = ({ data, appointment }: TyreTableItemProps) => {
  const t = useTranslation().t as ITranslation;
  const { tyreReplacementDeleteMutation } = useTyre(appointment.id);
  const canUpdateTyreReplacements = useCan("update", "appointments") && !isCarReadyOrQualityCheckDoneOrCanceled(appointment);

  return (
    <GridRow>
      <GridColumn width={1}>
        <p>
          <strong>{t("v8_replace_with").message || "Replace with"}:</strong>
        </p>
      </GridColumn>
      <GridColumn width={2}>
        <Popup
          hoverable
          content={size(data.tyre as Tyre)}
          trigger={
            <div className="tyre-season-container">
              <Icon className={getTyreSeasonIcon(Number(data.tyre?.season))} />
              <p>{size(data.tyre as Tyre)}</p>
            </div>
          }
        />
      </GridColumn>
      <GridColumn width={3}>
        <Popup hoverable content={getTyreDescription(data.tyre as Tyre)} trigger={<p>{getTyreDescription(data.tyre as Tyre)}</p>} />
      </GridColumn>
      <GridColumn width={2}>
        <Popup hoverable content={data.tyre?.manufacturer} trigger={<p>{data.tyre?.manufacturer}</p>} />
      </GridColumn>
      <GridColumn width={1} />

      <GridColumn width={4}>
        <Popup hoverable content={data.remark ? data.remark.trim() : ""} trigger={<p>{data.remark ? data.remark.trim() : ""}</p>} />
      </GridColumn>
      {canUpdateTyreReplacements && (
        <GridColumn width={2}>
          <div className="flex end">
            <Button className="tiGreenBg off af rounded" onClick={() => tyreReplacementDeleteMutation.mutate(data)}>
              <Icon className="trash grey" />
            </Button>
          </div>
        </GridColumn>
      )}
    </GridRow>
  );
};

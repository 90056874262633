import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { CUSTOMER_COMMUNICATIONS, Receivers } from "modules/AppointmentDetails/components";
import ApiInstance from "util/Api";

type CustomerCommunicationMutationArgs = {
  appointment_id: number;
  customer_id: number | null;
  receivers: Receivers[];
  note?: string;
  send?: CUSTOMER_COMMUNICATIONS;
  showPreviewURL?: string;
};

type PdfPreviewArgs = {
  appointment_id: number;
  receivers: Receivers[];
  customer_id: number | null;
  locale: string | null;
};

export const useCustomerCommunication = () => {
  const queryClient = useQueryClient();

  const handleError = (e: any, context: any) => {
    toast.error(e.message);
    if (context?.AppointmentSnapshot && context?.appointmentDetailsViewKey) {
      queryClient.setQueryData(context.appointmentDetailsViewKey, context.AppointmentSnapshot);
    }
  };

  const createCommunication = async (data: CustomerCommunicationMutationArgs) => {
    const send = data.send;
    const showPreviewURL = data.showPreviewURL;
    let url = "/repair_overview/preview/url";
    if (showPreviewURL) {
      url = "/repair_overview/create";
    }
    if (send === CUSTOMER_COMMUNICATIONS.DiagnoseOverview) {
      url = "/diagnose_overview/create";
    }
    delete data.send;
    delete data.showPreviewURL;
    const res = await ApiInstance.post(url, data, ENV.appointmentBaseURLv2);
    return {
      communication: send as CUSTOMER_COMMUNICATIONS,
      receivers: data.receivers,
      customer_id: data.customer_id,
      receptionistKey: res.data.key || "",
      showPreviewURL: res.data.url || ""
    };
  };

  const saveCustomerCommunicationMutation = useMutation({
    mutationFn: createCommunication,
    onError: handleError
  });

  const getRepairOverviewPDF = async (receptionistKey: string) => {
    const config = { responseType: "blob" };
    const payload = { communication_key: receptionistKey };
    return ApiInstance.post("/repair_overview/download", payload, ENV.customComBaseURL, config);
  };

  const downloadPdfPreview = async (requestData: PdfPreviewArgs) => {
    const config = { responseType: "blob" };
    const res = await ApiInstance.post("/repair_overview/preview/download", requestData, ENV.appointmentBaseURLv2, config);
    return res;
  };

  return { saveCustomerCommunicationMutation, getRepairOverviewPDF, downloadPdfPreview };
};

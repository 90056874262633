import { useTranslation } from "react-i18next";
import { Cell, Customized, Legend, Pie, PieChart, Tooltip } from "recharts";

import { ReportColor } from "models";
import "modules/LocationReports/components/TotalPieChart/TotalPieChart.scss";
import { REPORTS_COLORS } from "util/common";
import { ITranslation } from "util/interfaces";

type TotalPieChartProps = {
  data: Record<string, string>[];
  colors: ReportColor[];
};

const TotalPieChart = ({ data, colors }: TotalPieChartProps) => {
  const t = useTranslation().t as ITranslation;

  const renderCustomLabel = () => (
    <text x={155} y={165} dy={11} textAnchor="middle" fontSize="22" fontWeight="bold" fill="#595959">
      <tspan x="405">
        {t("v8_total").message || "Total"} {data.reduce((a, b) => a + (Number(b.value) || 0), 0)}
      </tspan>
      {data.length > 0 && data.at(0)?.total && (
        <tspan x="405" dy="30">
          {t("v8_WO").message || "WO"} {data.at(0)?.total}
        </tspan>
      )}
    </text>
  );

  const renderLegend = () => {
    const numberOfColumns = data.length <= 20 ? 1 : 2;

    return (
      <ul className="total-pie-chart-legend" style={{ gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)` }}>
        {data.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{ color: colors.find(col => (t(col.name).message || col.name) === entry.name)?.color || REPORTS_COLORS[index % REPORTS_COLORS.length] }}
          >
            {entry.name}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <PieChart width={1300} height={400} className="TotalPieChart">
      <Pie data={data} cx={400} cy={175} innerRadius={80} outerRadius={120} fill="#8884d8" dataKey="value" style={{ outline: "none" }}>
        {data.map((entry, index) => (
          <Cell key={index} fill={colors.find(col => (t(col.name).message || col.name) === entry.name)?.color || REPORTS_COLORS[index % REPORTS_COLORS.length]} />
        ))}
      </Pie>

      <Legend verticalAlign="middle" align="right" layout="vertical" content={renderLegend} />
      <Customized component={renderCustomLabel} />
      <Tooltip />
    </PieChart>
  );
};

export default TotalPieChart;

import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Icon } from "semantic-ui-react";

import "modules/AppointmentDetails/components/CopyContent/CopyContent.scss";
import { copyToClipboard } from "util/common";
import { ITranslation } from "util/interfaces";

type CopyContentProps = {
  children: ReactNode;
  content: string;
};

export const CopyContent = ({ children, content }: CopyContentProps) => {
  const [hover, setHover] = useState(false);
  const t = useTranslation().t as ITranslation;

  const handleCopyClick = () => {
    copyToClipboard(content);
    toast.success(t("v8_copy_to_clipboard").message || "Copied to clipboard");
  };

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className="CopyContent container">
      {hover && (
        <span onClick={handleCopyClick} className="CopyContent icon">
          <Icon name="copy" />
        </span>
      )}
      {children}
    </div>
  );
};

import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { KIOSK_COMMUNICATION_EVENT_TYPE, KioskCommunication, KioskCommunicationEvent } from "models";
import { EventInfo } from "modules/AppointmentDetails/components/KioskCommunicationLog/components";
import { ITranslation } from "util/interfaces";

const getEventName = (event: KioskCommunicationEvent, t: ITranslation) => {
  switch (event.type as unknown as KIOSK_COMMUNICATION_EVENT_TYPE) {
    case KIOSK_COMMUNICATION_EVENT_TYPE.KioskCheckInOpened:
      return t("v8_opened").message || "Opened";

    case KIOSK_COMMUNICATION_EVENT_TYPE.KioskCheckInAnswered:
      return t("v8_answered").message || "Answered";

    case KIOSK_COMMUNICATION_EVENT_TYPE.LabelDetached:
      return t("v8_kiosk_key_label_detached").message || "Kiosk key label detached";

    default:
      return "";
  }
};

type CommunicationEventsProps = {
  comm: KioskCommunication;
  events?: KioskCommunicationEvent[];
};

export const CommunicationEvents = ({ events, comm }: CommunicationEventsProps) => {
  const t = useTranslation().t as ITranslation;

  if (!events?.length) return null;

  return (
    <div className="kiosk-comm-section-container">
      <div className="kiosk-comm-section-header">{t("v8_kiosk_communication_events").message || "Kiosk Communication Events"}</div>

      <Grid>
        {events.map((event, key) => {
          return (
            <Grid.Row key={key} className="section-rows">
              <Grid.Column width={12}>{getEventName(event, t)}</Grid.Column>

              <Grid.Column width={4} className="event-timestamp">
                {moment(event.created_on).format("HH:mm")} - {moment(event.created_on).format("DD-MM-YYYY")}
              </Grid.Column>

              <EventInfo comm={comm} event={event} />
            </Grid.Row>
          );
        })}
      </Grid>
    </div>
  );
};

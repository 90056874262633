import { useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { ActionModalSelector, type ActionModalSelectorData, AnswerStatusSelector, QuestionResultMedia, TextareaEditable, useCan } from "components";
import "components/CheckList/CheckList.scss";
import { Appointment, ChecklistTemplate, QuestionResult } from "models";
import { GenericAttachmentData } from "modules/AppointmentDetails/components/Interventions/components";
import { useChecklist } from "modules/AppointmentDetails/hooks";
import { getExtensionFromURL, getNameFromURL, isCarReadyOrQualityCheckDoneOrCanceled } from "util/common";
import { ITranslation } from "util/interfaces";

type CheckListItemProps = {
  questionResult: QuestionResult;
  template?: ChecklistTemplate | null;
  onSelect: (data: ActionModalSelectorData) => void;
  t: ITranslation;
  showDropdownActions?: boolean;
  appointment: Appointment;
};

const CheckListItem = ({ questionResult, template, onSelect, t, showDropdownActions = true, appointment }: CheckListItemProps) => {
  const [showMedia, setShowMedia] = useState(false);
  const { updateQuestionItemRemark } = useChecklist(questionResult.appointment_id);
  const canUpdateCheckListItems = useCan("update_question_results_remark", "car_checks");
  const isConvertToInterventionButtonDisabled = useMemo(() => {
    return appointment.interventions?.some(i => i.question_result_id === questionResult.id);
  }, [appointment.interventions, questionResult.id]);

  const images = questionResult?.images?.filter(item => item.active)?.map(item => ({ ...item, type: `image/${getExtensionFromURL(item.url)}` })) || [];
  const videos = questionResult?.video?.filter(item => item.active)?.map(item => ({ ...item, type: `video/${getExtensionFromURL(item.url)}` })) || [];

  const toggleShowMedia = () => setShowMedia(prev => !prev);
  const handleUpdateNote = (raw: string) => updateQuestionItemRemark.mutate({ id: questionResult.id, remark: raw });

  const attachments: GenericAttachmentData[] = [...images, ...videos].map(attachment => {
    return {
      id: attachment.id,
      created_on: attachment.created_on,
      updated_on: attachment.updated_on,
      url: attachment.url,
      name: getNameFromURL(attachment.url),
      type: attachment.type
    };
  });

  return (
    <>
      <div className="CheckListItem">
        <div className="title-container">
          <p>{questionResult.title}</p>
          <div className="status-container">
            <AnswerStatusSelector data={questionResult} />
            {(images.length > 0 || videos.length > 0) && (
              <div onClick={toggleShowMedia}>
                <Icon className="images grey pointer" size="small" />
                <p>
                  <small>{images.length + videos.length}</small>
                </p>
              </div>
            )}
            {showDropdownActions && (
              <ActionModalSelector
                template={template}
                attachments={attachments}
                data={questionResult}
                onSelect={onSelect}
                isConvertToInterventionButtonDisabled={isConvertToInterventionButtonDisabled}
              />
            )}
          </div>
        </div>
        <div className="title-row">
          <p>
            <strong>{t("v8_remarks").message || "Remarks"}:</strong>
          </p>
          <TextareaEditable
            value={questionResult.raw}
            disabled={questionResult.is_quality || !canUpdateCheckListItems || isCarReadyOrQualityCheckDoneOrCanceled(appointment)}
            onSubmit={handleUpdateNote}
          />
        </div>

        {showMedia && <QuestionResultMedia questionResult={questionResult} canUpdate={canUpdateCheckListItems} appointment={appointment} />}
      </div>
    </>
  );
};

export default withTranslation()(CheckListItem);

import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { QUESTION_STATUSES } from "models";
import { QualityCheckReportData } from "modules/DealerReports/components/DealerQualityChecksTabular";
import "modules/DealerReports/components/DealerQualityChecksTabular/DealerQualityChecksTabular.scss";
import { useDealerQualityChecksFilterOptions } from "modules/DealerReports/components/DealerQualityChecksTabular/hooks";
import { ITranslation } from "util/interfaces";

type Props = {
  reportData: QualityCheckReportData[];
  mechanics: number | undefined;
  locations: string | undefined;
  qcOfficers: number | undefined;
  qcStatuses: QUESTION_STATUSES | undefined;
  handleMechanicsFilter: (mechanic: number) => void;
  handleLocationsFilter: (location: string) => void;
  handleQcOfficersFilter: (qcOfficer: number) => void;
  handleQcStatusesFilter: (qcStatus: QUESTION_STATUSES) => void;
};

export const DealerQualityChecksTabularFilters = ({
  locations,
  mechanics,
  qcOfficers,
  qcStatuses,
  reportData,
  handleLocationsFilter,
  handleMechanicsFilter,
  handleQcOfficersFilter,
  handleQcStatusesFilter
}: Props) => {
  const t = useTranslation().t as ITranslation;

  const { mechanicsDropdownOptions, locationsDropdownOptions, qcOfficersDropdownOptions, qcStatusesDropdownOptions } = useDealerQualityChecksFilterOptions({
    reportData
  });

  return (
    <div className="details-card-filters-container">
      <Dropdown
        noResultsMessage={t("v8_no_qc_statuses").message || "No QC statuses"}
        placeholder={t("v8_select_qc_status").message || "Select QC status"}
        options={qcStatusesDropdownOptions}
        onChange={(_e, { value }) => handleQcStatusesFilter(value as QUESTION_STATUSES)}
        selectOnBlur={false}
        value={qcStatuses}
        fluid
        selection
        clearable
      />
      <Dropdown
        noResultsMessage={t("v8_no_mechanics").message || "No mechanics"}
        placeholder={t("v8_select_mechanic").message || "Select Mechanic"}
        options={mechanicsDropdownOptions}
        onChange={(_e, { value }) => handleMechanicsFilter(value as number)}
        selectOnBlur={false}
        value={mechanics}
        fluid
        selection
        clearable
      />
      <Dropdown
        noResultsMessage={t("v8_no_qc_officers").message || "No QC Officers"}
        placeholder={t("v8_select_qc_officer").message || "Select QC Officer"}
        options={qcOfficersDropdownOptions}
        onChange={(_e, { value }) => handleQcOfficersFilter(value as number)}
        selectOnBlur={false}
        value={qcOfficers}
        fluid
        selection
        clearable
      />
      <Dropdown
        noResultsMessage={t("v8_no_locations").message || "No locations"}
        placeholder={t("v8_select_location").message || "Select Location"}
        options={locationsDropdownOptions}
        onChange={(_e, { value }) => handleLocationsFilter(value as string)}
        selectOnBlur={false}
        value={locations}
        fluid
        selection
        clearable
      />
    </div>
  );
};

import { faRegistered, faShieldHalved, faThumbTack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "semantic-ui-react";

import { PIN_TYPE } from "models";

type PinTypeIconProps = {
  pin_type_id: number;
  isPrint?: boolean;
};

export const PinTypeIcon = ({ pin_type_id, isPrint }: PinTypeIconProps) => {
  if (pin_type_id === PIN_TYPE.Warranty) return isPrint ? <FontAwesomeIcon icon={faShieldHalved} /> : <Icon className="shield halved" />;
  if (pin_type_id === PIN_TYPE.Recall) return isPrint ? <FontAwesomeIcon icon={faRegistered} /> : <Icon className="registered" />;
  if (pin_type_id === PIN_TYPE.Remarks) return isPrint ? <FontAwesomeIcon icon={faThumbTack} /> : <Icon className="thumb tack" />;
  return isPrint ? <FontAwesomeIcon icon={faThumbTack} /> : <Icon className="thumb tack" />;
};

import ENV from "config/Env";
import { UPDATE_TYPE, backendQueryKey } from "util/keyFactory";

const component = "LocationReports";

export const LocationReportQueryKeys = {
  list_groups: (dealer_location_id: number) => [
    backendQueryKey({ updateType: UPDATE_TYPE.Static, component, baseUrl: ENV.reportsBaseURL, endpoint: "/locations/list_groups", params: { dealer_location_id } })
  ],
  list_reports: (dealer_location_id: number, group: number) => [
    backendQueryKey({ updateType: UPDATE_TYPE.Static, component, baseUrl: ENV.reportsBaseURL, endpoint: "/locations/list", params: { dealer_location_id, group } })
  ],
  location_report: (index: number, dealer_location_id: number, report_id: number, date_from: string, date_to: string) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.Static,
      component,
      baseUrl: ENV.reportsBaseURL,
      endpoint: "/locations/view",
      params: { index, dealer_location_id, report_id, date_from, date_to }
    })
  ]
};

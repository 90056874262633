import { useDealersLocations } from "hooks";
import { NOTE_TYPES } from "models";

export const useGetNotes = (noteTypeId: NOTE_TYPES | null) => {
  const { selectedLocation } = useDealersLocations();

  if (!noteTypeId || !selectedLocation?.note_suggestions) return [];

  return selectedLocation.note_suggestions
    .filter(note => note.note_type_id === noteTypeId)
    .map(suggestion => ({ text: suggestion.content, value: suggestion.order, key: suggestion.order }));
};

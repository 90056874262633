import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ApiInstance from "util/Api";

// TODO: use the CancellationReason model instead of any
export const useCancelAppointmentMutation = (appointment_id: number) => {
  const cancelAppointment = async (reason: any) => {
    await ApiInstance.post("/appointments/cancel", { appointment_id, ...reason });
  };

  return useMutation({ mutationFn: cancelAppointment, onError: e => toast.error(e.message) });
};

import { QueryKey, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { WebSocketMessageListener } from "components";
import ENV from "config/Env";
import { useRealTimeQuery } from "hooks";
import { PinModel } from "models";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

export const usePinItems = (car_id: string) => {
  const queryClient = useQueryClient();

  const carPinItemsQueryKey = ["realtime", { ...queryKeys.car.pinItems, car_id }];

  const listeners: WebSocketMessageListener[] = [
    {
      model: "Pin",
      action: "append",
      filter: {
        car_id: Number(car_id)
      },
      callback: message => {
        const pinData = message.data as PinModel;
        const pinItems: PinModel[] | undefined = queryClient.getQueryData(carPinItemsQueryKey);
        let updatedPinItemsData = [];
        if (pinItems?.length) {
          updatedPinItemsData = pinItems.concat(pinData);
        } else {
          updatedPinItemsData = [pinData];
        }
        queryClient.setQueryData(carPinItemsQueryKey, updatedPinItemsData);
      }
    },
    {
      model: "Pin",
      action: "delete",
      filter: {
        car_id: Number(car_id)
      },
      callback: message => {
        const pinData = message.data as PinModel;
        const pinItems: PinModel[] | undefined = queryClient.getQueryData(carPinItemsQueryKey);
        if (!pinItems || !pinItems?.length) {
          return;
        }
        const updatedPinItemsData = pinItems.filter(pinItem => pinItem.id !== pinData.id);
        queryClient.setQueryData(carPinItemsQueryKey, updatedPinItemsData);
      }
    }
  ];

  const getPinItems = async ({ queryKey }: { queryKey: QueryKey }) => {
    const { endpoint } = queryKey[1] as BackendQueryKey;
    const res = await ApiInstance.post(endpoint, { car_id: Number(car_id) }, ENV.appointmentBaseURL);
    return res.data.items;
  };

  const clearCarPinItemsQueryCache = () => {
    queryClient.removeQueries({ queryKey: carPinItemsQueryKey });
  };

  useEffect(() => {
    return () => clearCarPinItemsQueryCache();
  }, []);

  return useRealTimeQuery({ queryKey: carPinItemsQueryKey, queryFn: getPinItems, listeners });
};

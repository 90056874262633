import ApiLoadable from "models/ApiLoadable";

export class Auth extends ApiLoadable {
  token: string = "";
  zoho_asap_jwt: string = "";
  was_logged_in: boolean = false;
  expire_at: string = "";
}

export enum ROLES {
  SuperAdmin = 10,
  ClaireAdmin = 20,
  SupportAdmin = 25,
  Consultant = 30,
  DealerAdmin = 40,
  Receptionist = 50,
  Mechanic = 60,
  CallCenter = 70,
  Supervisor = 80,
  WarrantyManager = 90,
  CounterTable = 100,
  Manufacturer = 110
}

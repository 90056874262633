import { useTranslation } from "react-i18next";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";

import { ReportColor } from "models";
import "modules/LocationReports/components/SimplePieChart/SimplePieChart.scss";
import { REPORTS_COLORS } from "util/common";
import { ITranslation } from "util/interfaces";

type SimplePieChartProps = {
  data: Record<string, string>[];
  colors: ReportColor[];
};

const SimplePieChart = ({ data, colors }: SimplePieChartProps) => {
  const t = useTranslation().t as ITranslation;

  const renderLegend = () => {
    const numberOfColumns = data.length <= 20 ? 1 : 2;

    return (
      <ul className="simple-pie-chart-legend" style={{ gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)` }}>
        {data.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{ color: colors.find(col => (t(col.name).message || col.name) === entry.name)?.color || REPORTS_COLORS[index % REPORTS_COLORS.length] }}
          >
            {entry.name}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <PieChart width={1300} height={400} className="SimplePieChart">
      <Pie data={data} cx={400} cy={175} outerRadius={120} label fill="#8884d8" dataKey="value" style={{ outline: "none" }}>
        {data.map((entry, index) => (
          <Cell key={index} fill={colors.find(col => (t(col.name).message || col.name) === entry.name)?.color || REPORTS_COLORS[index % REPORTS_COLORS.length]} />
        ))}
      </Pie>
      <Legend verticalAlign="middle" align="right" layout="vertical" content={renderLegend} />
      <Tooltip />
    </PieChart>
  );
};

export default SimplePieChart;

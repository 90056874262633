import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Modal } from "semantic-ui-react";

import { DetailsCards } from "components";
import printJS from "lib/print-js";
import { Appointment } from "models";
import { AppointmentDetails, Communications } from "modules/AppointmentDetails/components/KioskCommunicationLog/components";
import "modules/AppointmentDetails/components/KioskCommunicationLog/KioskCommunicationLog.scss";
import { ITranslation } from "util/interfaces";

type KioskCommunicationLogProps = {
  appointment: Appointment;
};

export const KioskCommunicationLog = ({ appointment }: KioskCommunicationLogProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const t = useTranslation().t as ITranslation;

  const handleToggleIsModalOpen = (evt: React.MouseEvent) => {
    evt.stopPropagation();

    setIsModalOpen(!isModalOpen);
  };

  const handlePrint = () =>
    printJS({
      printable: "kiosk-communication-log",
      type: "html",
      copyStyles: true,
      css: ["./KioskCommunicationLog.css"],
      scanStyles: false,
      targetStyles: ["*"]
    });

  const { kiosk_communications } = appointment;

  if (!kiosk_communications?.length) return null;

  return (
    <>
      <div className="KioskCommunicationLog" onClick={handleToggleIsModalOpen}>
        <DetailsCards title={t("v8_kiosk_comm_log").message || "Kiosk Communication Log"} icon="user" rightContent={null} showExpandArrow={false} button />
      </div>
      <Modal open={isModalOpen}>
        <Modal.Header className="KioskCommunicationLogHeader">
          <div className="header-title">
            <Icon name="list" />
            <div>{t("v8_kiosk_comm_log").message || "Kiosk communication log"}</div>
          </div>
          <Button onClick={handlePrint} className="action-btn" color="green">
            {t("v8_print").message || "Print"}
            <Icon className="print" />
          </Button>
        </Modal.Header>

        <Modal.Content className="KioskCommunicationLogContent" id="kiosk-communication-log" scrolling>
          <AppointmentDetails appointment={appointment} />
          <Communications kiosk_communications={kiosk_communications} />
        </Modal.Content>

        <Modal.Actions className="KioskCommunicationLogActions">
          <Button className="action-btn" color="grey" onClick={handleToggleIsModalOpen} floated="right">
            {t("v8_close").message || "Close"}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

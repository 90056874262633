import { useTranslation } from "react-i18next";
import { Button, Icon, Input, Modal } from "semantic-ui-react";

import "modules/AppointmentDetails/components/AppointmentStatus/AppointmentStatus.scss";
import { ITranslation } from "util/interfaces";

interface CarMileageModalPropTypes {
  showCarMileageModal: boolean;
  onSetCurrentMileage: (val: number) => void;
  onResetCarMileageModal: () => void;
  currentMileage: number | null;
  onHandleCarMileageConfirm: () => void;
}

export default function CarMileageModal({
  showCarMileageModal,
  onSetCurrentMileage,
  onResetCarMileageModal,
  currentMileage,
  onHandleCarMileageConfirm
}: CarMileageModalPropTypes) {
  const t = useTranslation().t as ITranslation;
  return (
    <Modal size="tiny" open={!!showCarMileageModal}>
      <Modal.Header>{t("v8_missing_mileage").message || "Missing mileage"}</Modal.Header>
      <Modal.Content>
        <p>{t("v8_current_mileage").message || "Current Mileage"}</p>
        <Input type="number" onChange={e => onSetCurrentMileage(+e.target.value)} className="car-mileage-input" />
      </Modal.Content>
      <Modal.Actions className="car-mileage-action-container">
        <Button onClick={onResetCarMileageModal}>{t("v8_cancel").message || "Cancel"}</Button>
        <Button color="green" disabled={!currentMileage || currentMileage <= 0} onClick={onHandleCarMileageConfirm}>
          {t("v8_save").message || "Save"} <Icon className="check" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

import ENV from "config/Env";
import { UPDATE_TYPE, backendQueryKey } from "util/keyFactory";

const component = "DealerReports";

export const DealerReportQueryKeys = {
  list_groups: (dealer_id: number) => [
    backendQueryKey({ updateType: UPDATE_TYPE.Static, component, baseUrl: ENV.reportsBaseURL, endpoint: "/dealers/list_groups", params: { dealer_id } })
  ],
  list_reports: (dealer_id: number, group: number) => [
    backendQueryKey({ updateType: UPDATE_TYPE.Static, component, baseUrl: ENV.reportsBaseURL, endpoint: "/dealers/list", params: { dealer_id, group } })
  ],
  dealer_report: (index: number, dealer_id: number, report_id: number, date_from: string, date_to: string) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.Static,
      component,
      baseUrl: ENV.reportsBaseURL,
      endpoint: "/dealers/view",
      params: { index, dealer_id, report_id, date_from, date_to }
    })
  ]
};

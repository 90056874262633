import moment from "moment";

import { AnswerStatusSelector } from "components";
import { Appointment, Intervention, QUESTION_STATUSES } from "models";
import { ImageGallery, ImageLetters } from "modules/AppointmentDetails/components/Interventions/components/PinOverview/components";
import "modules/AppointmentDetails/components/Interventions/components/PinOverview/components/ChecklistSection/ChecklistSection.scss";
import TyreSection from "modules/AppointmentDetails/components/Interventions/components/PinOverview/components/ChecklistSection/components/TyreSection";
import {
  isInStorage,
  isOnCar,
  isOnTruck
} from "modules/AppointmentDetails/components/Interventions/components/PinOverview/components/ChecklistSection/components/useTyreSection";
import useChecklistSection from "modules/AppointmentDetails/components/Interventions/components/PinOverview/components/ChecklistSection/useChecklistSection";

type Props = {
  appointment: Appointment | null;
  intervention: Intervention;
};

export const ChecklistSection = ({ appointment, intervention }: Props) => {
  const { filteredChecklist, questionImages } = useChecklistSection({ appointment, intervention });
  if (!filteredChecklist.length) return null;

  return (
    <div className="ChecklistSectionContent">
      {filteredChecklist.map(checklist => {
        const questionItemsImages = questionImages.find(image => image.checklistId === checklist.id)?.questionImages || [];

        return (
          <div className="checklist-notes" key={checklist.checklist_id}>
            <div className="section-header">
              {checklist.checklist.name || ""} - <span>{moment(checklist.created_on).format("DD-MM-YYYY HH:mm")}</span>
            </div>

            <ImageGallery lettersMap={questionItemsImages} />
            {checklist.question_items?.map(questionItem => {
              if (questionItem.status === QUESTION_STATUSES.NVT) return null;

              if (
                questionItem.tyre_position &&
                (isOnCar(questionItem.tyre_position) || isOnTruck(questionItem.tyre_position) || isInStorage(questionItem.tyre_position))
              ) {
                return <TyreSection key={questionItem.id} appointment={appointment} intervention={intervention} item={questionItem} checklistId={checklist.id} />;
              }

              return (
                <div className="checklist-item" key={questionItem.id}>
                  <div>
                    <div className="checklist-item-text-image-letter">
                      <ImageLetters images={questionImages} questionItemId={questionItem.id} checklistId={checklist.id} />
                      <div className="checklist-item-title">{questionItem.title}</div>
                    </div>
                    <p className="checklist-item-description">{questionItem.raw}</p>
                  </div>
                  <AnswerStatusSelector data={questionItem} disabled={true} />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

import i18n from "i18next";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import "modules/Auth/Auth.scss";
import { Login, ResetPassword, ResetPasswordRequest } from "modules/Auth/components";
import RepairImg from "modules/Auth/img/repair.svg";
import { AuthProps } from "modules/Auth/interfaces";
import { PATHS } from "router/paths";
import { getBrowserLanguage, languages } from "util/common";

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import packageJson from "../../../package.json";

const Auth = ({ t }: AuthProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState(getBrowserLanguage());

  const handleLanguageChange = (_e: React.SyntheticEvent<HTMLElement, Event>, data: any) => {
    const selectedLanguage = localStorage.getItem("locale");

    if (selectedLanguage !== data.value) {
      i18n.changeLanguage(data.value, () => {
        i18n.reloadResources([data.value], ["translations"], () => {
          localStorage.setItem("locale", data.value);
          setSelectedLanguage(data.value);
        });
      });
    }
  };

  return (
    <div className="Auth">
      <div className="auth__overlay">
        <div className="auth_container">
          <img className="repair_img" src={RepairImg} />
          <div className="auth_rhs">
            <h1>
              <span>C</span>
              laire
            </h1>
            <h3>Automotive Support</h3>
            <div className="rhs_auth_box">
              <Route exact path={[PATHS.HOME, PATHS.LOGIN]} render={() => <Login />}></Route>
              <Route exact path={PATHS.RESET_PASSWORD} render={() => <ResetPassword />}></Route>
              <Route exact path={PATHS.RESET_PASSWORD_REQUEST} render={() => <ResetPasswordRequest />}></Route>
              <div className="mt-15">
                <p>{t("v8_select_language").message || "Select language"}</p>
              </div>
              <div className="mt-15">
                <Dropdown selectOnBlur={false} fluid selection options={languages} value={selectedLanguage} onChange={handleLanguageChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="auth_footer">
        <p>{packageJson.version}</p>
        <a href="https://www.claireautomotive.com/" target="blank">
          clairesoftware.nl
        </a>
        <a href="https://www.claireautomotive.com/support" target="blank">
          {t("v8_support").message || "Support"}
        </a>
        <p>{"+31 (0) 85 002 0211"}</p>
      </div>
    </div>
  );
};

export default withTranslation()(Auth);

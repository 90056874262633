import { useMutation, useQueryClient } from "@tanstack/react-query";

import ENV from "config/Env";
import { useAppointment } from "modules/AppointmentDetails/hooks";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import ApiInstance from "util/Api";

interface CheckInResultMutationData {
  checkin_result_id: number;
  handled: boolean;
}

export const useCheckInResultMutation = (appointment_id: number) => {
  const queryClient = useQueryClient();
  const { appointment } = useAppointment(appointment_id);

  const appointmentQueryKey = AppointmentDetailsKeys.view(appointment_id);

  return useMutation({
    mutationFn: async (data: CheckInResultMutationData) => {
      await ApiInstance.post(`/check_in/result/handle`, data, ENV.appointmentBaseURL);
    },
    onMutate: ({ checkin_result_id, handled }) => {
      if (!appointment?.customer_communication?.check_in_results?.length) return;

      const checkInResultIdx = appointment.customer_communication.check_in_results.findIndex(result => result.id === checkin_result_id);
      if (checkInResultIdx < 0) return;

      const customer_communication = {
        ...appointment.customer_communication,
        check_in_results: appointment.customer_communication.check_in_results.with(checkInResultIdx, {
          ...appointment.customer_communication.check_in_results[checkInResultIdx],
          receptionist_handled: handled
        })
      };

      queryClient.setQueryData(appointmentQueryKey, { ...appointment, customer_communication });

      return appointment;
    },
    onError: (_, __, appointment) => queryClient.setQueryData(appointmentQueryKey, appointment)
  });
};

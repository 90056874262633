import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Appointment, COMMUNICATION_EVENT_TYPE, COMMUNICATION_RESULT_STATUS, CommunicationAgreement, CommunicationEvent } from "models";
import { COMMUNICATION_LOG_TAB } from "modules/AppointmentDetails/components/CommunicationLog";
import { Agreements, CheckinResults, CommunicationEvents, Services } from "modules/AppointmentDetails/components/CommunicationLog/components";
import { ITranslation } from "util/interfaces";

type TabContentType = {
  id: number;
  tab: COMMUNICATION_LOG_TAB;
  appointment: Appointment;
  onSelectAgreement?: (agreement: CommunicationAgreement) => void;
};

const onlineCheckinEventTypes = [
  COMMUNICATION_EVENT_TYPE.OnlineCheckInSent,
  COMMUNICATION_EVENT_TYPE.OnlineCheckInOpened,
  COMMUNICATION_EVENT_TYPE.OnlineCheckInAnswered
];

const repairOverviewEventTypes = [
  COMMUNICATION_EVENT_TYPE.RepairOverviewSent,
  COMMUNICATION_EVENT_TYPE.RepairOverviewResent,
  COMMUNICATION_EVENT_TYPE.RepairOverviewOpened
];

const diagnoseOverviewEventTypes = [
  COMMUNICATION_EVENT_TYPE.DiagnoseOverviewSent,
  COMMUNICATION_EVENT_TYPE.DiagnoseOverviewOpened,
  COMMUNICATION_EVENT_TYPE.DiagnoseOverviewResent,
  COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered
];

export const TabContent = ({ tab, id, appointment, onSelectAgreement }: TabContentType) => {
  const [events, setEvents] = useState<CommunicationEvent[]>([]);
  const [agreements, setAgreements] = useState<CommunicationAgreement[]>([]);
  const t = useTranslation().t as ITranslation;

  useEffect(() => {
    let tempEvents: CommunicationEvent[] = [];

    if (tab === COMMUNICATION_LOG_TAB.OnlineCheckin) {
      tempEvents = appointment?.customer_communication?.events?.filter(e => onlineCheckinEventTypes.includes(e.type)) || [];
    } else if (tab === COMMUNICATION_LOG_TAB.RepairOverview) {
      tempEvents = appointment?.customer_communication?.events?.filter(e => repairOverviewEventTypes.includes(e.type)) || [];
    } else {
      const diagnoseEvents = appointment?.customer_communication?.events?.filter(e => diagnoseOverviewEventTypes.includes(e.type)) || [];

      let eventIndex = diagnoseEvents.findIndex(e => e.id === id);

      if (eventIndex > -1) {
        do {
          tempEvents.push(diagnoseEvents[eventIndex]);
          eventIndex += 1;
        } while (eventIndex < diagnoseEvents.length && diagnoseEvents[eventIndex].type !== COMMUNICATION_EVENT_TYPE.DiagnoseOverviewResent);
      }
    }

    const tempAgreements = tempEvents.find(e => e.agreements)?.agreements || [];

    setEvents(tempEvents);
    setAgreements(tempAgreements);
  }, [tab]);

  const agreedInterventions = events
    .find(e => [COMMUNICATION_EVENT_TYPE.OnlineCheckInOpened, COMMUNICATION_EVENT_TYPE.DiagnoseOverviewOpened].includes(e.type))
    ?.intervention_results?.filter(i => i.communication_result_status === COMMUNICATION_RESULT_STATUS.OkStatus);

  const declinedInterventions = events
    .find(e => COMMUNICATION_EVENT_TYPE.DiagnoseOverviewOpened === e.type)
    ?.intervention_results?.filter(i => i.communication_result_status === COMMUNICATION_RESULT_STATUS.NotOkStatus);

  const contactInterventions = events
    .find(e => COMMUNICATION_EVENT_TYPE.DiagnoseOverviewOpened === e.type)
    ?.intervention_results?.filter(i => i.communication_result_status === COMMUNICATION_RESULT_STATUS.ContactMeStatus);

  const acceptedExtraServices = events.find(e => e.type === COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered)?.diagnose_overview_agreed_results || null;
  const declinedExtraServices = events.find(e => e.type === COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered)?.diagnose_overview_declined_results || null;
  const contactExtraServices = events.find(e => e.type === COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered)?.diagnose_overview_contact_results || null;

  return (
    <>
      <CommunicationEvents events={events} />
      <Services results={agreedInterventions} title={t("v8_agreed_interventions").message || "Agreed interventions"} />
      <Services results={declinedInterventions} title={t("v8_declined_interventions").message || "Declined interventions"} />
      <Services results={contactInterventions} title={t("v8_contact_me_interventions").message || "Contact me interventions"} />
      <Services results={acceptedExtraServices} title={t("v8_accepted_extra_services").message || "Accepted extra services"} />
      <Services results={declinedExtraServices} title={t("v8_declined_extra_services").message || "Declined extra services"} />
      <Services results={contactExtraServices} title={t("v8_contact_extra_services").message || "Contact extra services"} />
      {appointment.customer_communication?.check_in_results && tab === COMMUNICATION_LOG_TAB.OnlineCheckin && (
        <CheckinResults results={appointment.customer_communication.check_in_results} />
      )}
      <Agreements agreements={agreements} onSelectAgreement={onSelectAgreement} />
    </>
  );
};

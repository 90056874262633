import { useState } from "react";

import { Appointment, ChecklistTemplate, QuestionResult } from "models";
import { TyreReplacementModal } from "modules/AppointmentDetails/components/Tyre/components";
import DBBLabelsModal from "modules/AppointmentDetails/components/Tyre/DBBLabelsModal";
import "modules/AppointmentDetails/components/Tyre/Tyre.scss";
import TyreChecklist from "modules/AppointmentDetails/components/Tyre/TyreChecklist";

export const tyrePosition = {
  isOnCar: (position: number) => position > 0 && position < 5,
  isInCar: (position: number) => position > 4 && position < 9,
  isNotTruck: (position: number) => position > 9
};

type TyreProps = {
  data: QuestionResult[];
  template?: ChecklistTemplate | null;
  appointment: Appointment;
};

export type TyreLocationData = {
  onCar: QuestionResult[];
  inStorage: QuestionResult[];
  onTruck: QuestionResult[];
};

export const Tyre = ({ data, template, appointment }: TyreProps) => {
  const [isDBBModalOpen, setIsDBBModalOpen] = useState(false);
  const [isReplacementModalOpen, setIsReplacementModalOpen] = useState(false);

  const [DBBModalData, setModalDBBModalData] = useState<QuestionResult[] | null>(null);
  const [tyreReplacementModalData, setTyreReplacementModalData] = useState<QuestionResult | null>(null);

  const handleModalClose = () => {
    setModalDBBModalData(null);
    setTyreReplacementModalData(null);
    setIsDBBModalOpen(false);
    setIsReplacementModalOpen(false);
  };

  const handleDBBModal = (data: QuestionResult[]) => {
    setModalDBBModalData(data);
    setIsDBBModalOpen(true);
  };

  const handleTyreReplacementModal = (data: QuestionResult) => {
    setTyreReplacementModalData(data);
    setIsReplacementModalOpen(true);
  };

  return (
    <div className="Tyre">
      <TyreChecklist onRequestDBBPreview={handleDBBModal} onRequestReplacement={handleTyreReplacementModal} data={data} template={template} appointment={appointment} />
      <DBBLabelsModal open={isDBBModalOpen} data={DBBModalData} onRequestClose={handleModalClose} appointment={appointment} />
      <TyreReplacementModal
        data={tyreReplacementModalData}
        open={isReplacementModalOpen}
        onRequestClose={handleModalClose}
        appointmentId={appointment.id}
        appointment={appointment}
      />
    </div>
  );
};

import { ReactElement, useEffect, useState } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { PATHS } from "router/paths";
import apiInstance from "util/Api";

export const PublicRoute = (props: RouteProps): ReactElement => {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    if (apiInstance.authToken) {
      setToken(apiInstance.authToken);
    }
  }, []);

  if (token && (props.path === PATHS.LOGIN || props.path === PATHS.HOME || props.path === PATHS.RESET_PASSWORD)) {
    return <Redirect to={PATHS.APPOINTMENTS} />;
  }
  return <Route {...props} />;
};

import { Brand, QuestionGroup, Tag } from "models";
import ApiLoadable from "models/ApiLoadable";

export enum CHECKLIST_TYPE {
  RegularChecklist = 0,
  AannameChecklist,
  QualityChecklist,
  InfoChecklist,
  ExtraPartsChecklist,
  ExtensionList
}

export class Checklist extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  name: string = "";
  active: boolean = false;
  is_active_for_mechanic: boolean = false;
  is_locked: boolean = false;
  include_in_reports: boolean = false;
  role_id: number = 0;
  checklist_type: CHECKLIST_TYPE = CHECKLIST_TYPE.RegularChecklist;
  dealer_id: number | null = null;
  template_id: number | null = null;
  is_deleted: boolean = false;

  display_order: number = 0;
  template?: ChecklistTemplate | null = null;

  private _question_groups?: QuestionGroup[] | null = null;

  get question_groups(): QuestionGroup[] {
    return this._question_groups ? this._question_groups : [];
  }

  set question_groups(question_groups: QuestionGroup[] | null | undefined) {
    this._question_groups = question_groups;
  }

  private _tags?: Tag[] | null = null;

  get tags(): Tag[] {
    return this._tags ? this._tags : [];
  }

  set tags(tags: Tag[] | null | undefined) {
    this._tags = tags;
  }

  private _brands?: Brand[] | null = null;

  get brands(): Brand[] {
    return this._brands ? this._brands : [];
  }

  set brands(brands: Brand[] | null | undefined) {
    this._brands = brands;
  }

  parent_checklist?: Checklist | null = null;
}

export class ChecklistTemplate extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  name: string = "";
  description: string = "";
  primary_color: string = "";
  text_color: string = "";
  icon_color: string = "";
  price_enabled: boolean = false;
  customer_ok_enabled: boolean = false;
  ready_enabled: boolean = false;
  snooze_enabled: boolean = false;
  pin_enabled: boolean = false;
  multi_use_enabled: boolean = false;
  include_in_reports: boolean = false;
  total_pdf_enabled: boolean = false;
  default_only: boolean = false;
  cc_default_selected: boolean = false;
  pdf_layout_id: number | null = null;
  add_purpose: boolean = false;
  show_mechanic_name: boolean = false;
  hide_timestamp: boolean = true;
  is_previewable?: boolean = false;
}

export class ChecklistsDealers extends ApiLoadable {
  checklist_id: number = 0;
  dealer_id: number = 0;
}

export class ChecklistTemplateShortResult {
  private _checklist_templates?: ChecklistTemplateShort[] | null = null;

  get checklist_templates(): ChecklistTemplateShort[] {
    return this._checklist_templates ? this._checklist_templates : [];
  }

  set checklist_templates(checklist_templates: ChecklistTemplateShort[] | null) {
    this._checklist_templates = checklist_templates;
  }
}

export class ChecklistTemplateShort extends ApiLoadable {
  checklist_template_id: number = 0;
  name: string = "";
  default_only: boolean = false;
}

import { faAdjust, faCalendarCheck, faCarAlt, faCheckDouble, faDiagnoses, faTimesCircle, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Label } from "semantic-ui-react";
import { Button, Checkbox, Dropdown, Form, Grid, GridColumn, Icon, Input } from "semantic-ui-react";

import { ChangePasswordModal, UploadProfile } from "components";
import { NotificationElement, useAuth, useDealersLocations, useUser } from "hooks";
import { User } from "models";
import "modules/Auth/components/AccountSettings/AccountSettings.scss";
import { useAccountLandingPages } from "modules/Auth/components/AccountSettings/hooks/useAccountLandingPages";
import { ITranslation } from "util/interfaces";

type GroupNotificationEntity = { title: string; templateId: number; elements: NotificationElement[] };

export enum NOTIFICATION_ENTITY_TEMPLATE {
  Checkboxes = 1,
  DropdownMulti = 2
}

export enum NOTIFICATION_FIELD {
  NotificationFieldCarInShop = 1,
  NotificationFieldCarOutOfShop,
  NotificationFieldMechanicNeedsHelp,
  NotificationFieldIsMoney,
  NotificationFieldIsShop,
  NotificationFieldIsStar,
  NotificationFieldCanceledAppointmentStatus,
  NotificationFieldNewcarAppointmentStatus,
  NotificationFieldCarcheckAppointmentStatus,
  NotificationFieldPricingokAppointmentStatus,
  NotificationFieldCustomerokAppointmentStatus,
  NotificationFieldCarreadyAppointmentStatus,
  NotificationFieldDiagnoseAppointmentStatus,
  NotificationFieldAanameAppointmentStatus,
  NotificationFieldCalculatingAppointmentStatus,
  NotificationFieldContactAppointmentStatus,
  NotificationFieldCarcheckstartedAppointmentStatus,
  NotificationFieldQualitycheckAppointmentStatus,
  NotificationFieldCustomeransweredAppointmentStatus,
  NotificationFieldHandlecheckinAppointmentStatus,
  NotificationFieldCheckindoneAppointmentStatus,
  NotificationFieldCarinshopAppointmentStatus,
  NotificationFieldCaroutofshopAppointmentStatus
}

const AccountSettings = () => {
  const t = useTranslation().t as ITranslation;
  const user = useUser();
  const history = useHistory();
  const { landingPagesList } = useAccountLandingPages();
  const { selectedDealer, locationDropdownOptions } = useDealersLocations();
  const { getNotificationElements, updateUserAccountMutation } = useAuth();

  const [phone, setPhone] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [landingPageId, setLandingPageId] = useState<number>();
  const [profilePicture, setProfilePicture] = useState<string>("");
  const [dealerLocationId, setDealerLocationId] = useState<number>();
  const [useCounterTabetUser, setUseCounterTabetUser] = useState<boolean>(true);
  const [notificationElementIds, setNotificationElementIds] = useState<number[]>([]);
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState<boolean>(false);
  const [pinStatusNotificationEnabled, setPinStatusNotificationEnabled] = useState<boolean>(false);
  const [shopStatusSameDayFilterEnabled, setShopStatusSameDayFilterEnabled] = useState<boolean>(true);
  const [shopStatusNextDayFilterEnabled, setShopStatusNextDayFilterEnabled] = useState<boolean>(true);
  const [shopStatusToOrderFilterEnabled, setShopStatusToOrderFilterEnabled] = useState<boolean>(true);
  const [listAppointmentsByScheduleRange, setListAppointmentsByScheduleRange] = useState<boolean>(false);
  const [shopStatusBackorderFilterEnabled, setShopStatusBackorderFilterEnabled] = useState<boolean>(true);
  const [groupedNotificationElements, setGroupedNotificationElements] = useState<Record<any, GroupNotificationEntity>>({});
  const [isLocationColumnVisible, setIsLocationColumnVisible] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState<number[]>([]);
  const { locations } = useDealersLocations();

  const isLocationsSectionVisible = !!user?.location_ids;
  const userLocations = locations.filter(location => user?.location_ids?.includes(location.id));

  useEffect(() => {
    const phoneNr = user?.phone || "";
    const mobile = user?.mobile || "";
    const shopStatusSameDayFilterEnabled = user?.shop_status_same_day_filter_enabled || false;
    const shopStatusNextDayFilterEnabled = user?.shop_status_next_day_filter_enabled || false;
    const shopStatusToOrderFilterEnabled = user?.shop_status_to_order_filter_enabled || false;
    const shopStatusBackorderFilterEnabled = user?.shop_status_backorder_filter_enabled || false;
    const useCounterTabetUser = user?.is_counter_tablet_user || false;
    const landingPageId = user?.landing_page_id || 0;
    const dealerLocationId = user?.dealer_location_id || 0;
    const pinStatusNotificationEnabled = user?.pin_status_notification_enabled || false;
    const listAppointmentsByScheduleRange = user?.list_appointments_by_scheduled_range || false;
    const notificationElementIds = user?.notification_element_ids || [];
    const locationColumnVisible = !!user?.location_column_visible;
    const userLocations = user?.my_locations || [];

    setNotificationElementIds(notificationElementIds);
    setPinStatusNotificationEnabled(pinStatusNotificationEnabled);
    setListAppointmentsByScheduleRange(listAppointmentsByScheduleRange);
    setUseCounterTabetUser(useCounterTabetUser);
    setLandingPageId(landingPageId);
    setDealerLocationId(dealerLocationId);
    setShopStatusSameDayFilterEnabled(shopStatusSameDayFilterEnabled);
    setShopStatusNextDayFilterEnabled(shopStatusNextDayFilterEnabled);
    setShopStatusToOrderFilterEnabled(shopStatusToOrderFilterEnabled);
    setShopStatusBackorderFilterEnabled(shopStatusBackorderFilterEnabled);
    setProfilePicture(user?.profile_picture || "");
    setPhone(phoneNr);
    setMobile(mobile);
    setIsLocationColumnVisible(locationColumnVisible);
    setSelectedLocations(userLocations);
  }, [user]);

  const fetchNotificationElements = useCallback(async () => {
    try {
      const notificationElements = await getNotificationElements();
      const groupedNotificationElements: Record<any, GroupNotificationEntity> = {};
      notificationElements.forEach(element => {
        if (!groupedNotificationElements[element.notification_entity_id]) {
          groupedNotificationElements[element.notification_entity_id] = { title: element.entity.name, templateId: element.entity.template_id, elements: [] };
        }
        groupedNotificationElements[element.notification_entity_id].elements.push(element);
      });
      setGroupedNotificationElements(groupedNotificationElements);
    } catch (error) {
      console.error(`Error while fetching notification elements`, error);
    }
  }, []);

  useEffect(() => {
    fetchNotificationElements();
  }, [fetchNotificationElements]);

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    switch (evt.target.name) {
      case "phone":
        setPhone(evt.target.value);
        break;
      case "mobile":
        setMobile(evt.target.value);
        break;
      default:
        break;
    }
  };

  const handleDropdownChange = (name: string, value: string | number | boolean | (string | number | boolean)[] | undefined) => {
    switch (name) {
      case "list_appointments_by_scheduled_range": {
        setListAppointmentsByScheduleRange(value as boolean);
        break;
      }
      case "landing_page_id": {
        setLandingPageId(value as number);
        break;
      }
      case "location": {
        setDealerLocationId(value as number);
        break;
      }
      default:
        break;
    }
  };

  const handleCheckboxChange = (name: string | undefined, value: boolean | undefined = false) => {
    switch (name) {
      case "is_location_visible": {
        setIsLocationColumnVisible(curr => !curr);
        break;
      }
      case "shop_status_same_day_filter_enabled": {
        setShopStatusSameDayFilterEnabled(value);
        break;
      }
      case "shop_status_to_order_filter_enabled": {
        setShopStatusToOrderFilterEnabled(value);
        break;
      }
      case "shop_status_next_day_filter_enabled": {
        setShopStatusNextDayFilterEnabled(value);
        break;
      }
      case "shop_status_backorder_filter_enabled": {
        setShopStatusBackorderFilterEnabled(value);
        break;
      }
      case "use_counter_tablets": {
        setUseCounterTabetUser(value);
        break;
      }
      case "pin_status_notification_enabled": {
        setPinStatusNotificationEnabled(value);
        break;
      }
      default:
        break;
    }
  };

  const getElementIcon = (notificationElementId: NOTIFICATION_FIELD) => {
    switch (notificationElementId) {
      case NOTIFICATION_FIELD.NotificationFieldCarInShop:
        return <Icon name="car" />;
      case NOTIFICATION_FIELD.NotificationFieldCarOutOfShop:
        return <Icon name="sign out" />;
      case NOTIFICATION_FIELD.NotificationFieldMechanicNeedsHelp:
        return <Icon name="help circle" />;
      case NOTIFICATION_FIELD.NotificationFieldIsMoney:
        return <Icon name="dollar" />;
      case NOTIFICATION_FIELD.NotificationFieldIsShop:
        return <Icon name="shop" />;
      case NOTIFICATION_FIELD.NotificationFieldIsStar:
        return <Icon name="star" />;
      case NOTIFICATION_FIELD.NotificationFieldCanceledAppointmentStatus:
        return <FontAwesomeIcon icon={faTimesCircle} />;
      case NOTIFICATION_FIELD.NotificationFieldNewcarAppointmentStatus:
        return <Icon name="car" />;
      case NOTIFICATION_FIELD.NotificationFieldCarcheckAppointmentStatus:
        return <Icon name="wrench" />;
      case NOTIFICATION_FIELD.NotificationFieldPricingokAppointmentStatus:
        return <Icon name="tag" />;
      case NOTIFICATION_FIELD.NotificationFieldCustomerokAppointmentStatus:
        return <FontAwesomeIcon icon={faDiagnoses} />;
      case NOTIFICATION_FIELD.NotificationFieldCarreadyAppointmentStatus:
        return <FontAwesomeIcon icon={faCheckDouble} />;
      case NOTIFICATION_FIELD.NotificationFieldDiagnoseAppointmentStatus:
        return <Icon name="warning sign" />;
      case NOTIFICATION_FIELD.NotificationFieldAanameAppointmentStatus:
        return <Icon name="adn" />;
      case NOTIFICATION_FIELD.NotificationFieldCalculatingAppointmentStatus:
        return <Icon name="dollar sign" />;
      case NOTIFICATION_FIELD.NotificationFieldContactAppointmentStatus:
        return <Icon name="mail" />;
      case NOTIFICATION_FIELD.NotificationFieldCarcheckstartedAppointmentStatus:
        return <FontAwesomeIcon icon={faAdjust} />;
      case NOTIFICATION_FIELD.NotificationFieldQualitycheckAppointmentStatus:
        return <Icon name="clipboard check" />;
      case NOTIFICATION_FIELD.NotificationFieldCustomeransweredAppointmentStatus:
        return <FontAwesomeIcon icon={faUserCheck} />;
      case NOTIFICATION_FIELD.NotificationFieldHandlecheckinAppointmentStatus:
        return <i className="material-icons -notification-status-icon">event_available</i>;
      case NOTIFICATION_FIELD.NotificationFieldCheckindoneAppointmentStatus:
        return <FontAwesomeIcon icon={faCalendarCheck} />;
      case NOTIFICATION_FIELD.NotificationFieldCarinshopAppointmentStatus:
        return <Icon name="car" />;
      case NOTIFICATION_FIELD.NotificationFieldCaroutofshopAppointmentStatus:
        return <FontAwesomeIcon icon={faCarAlt} />;
      default:
        return <Icon name="shopping cart" />;
    }
  };

  const handleChangeNotificationsCheckbox = (notificationElementId: number) => {
    const notificationElementIdsCopy = [...notificationElementIds];
    const idx = notificationElementIdsCopy.findIndex(el => el === notificationElementId);
    if (idx > -1) notificationElementIdsCopy.splice(idx, 1);
    else notificationElementIdsCopy.push(notificationElementId);
    setNotificationElementIds(notificationElementIdsCopy);
  };

  const getDesktopNotificationsFormFields = () => {
    const fields: ReactElement[] = [];
    const entityKeys = Object.keys(groupedNotificationElements);

    entityKeys.forEach(entityId => {
      const uiElements: ReactElement[] = [];
      const entity = groupedNotificationElements[entityId];
      entity.elements.forEach(el =>
        uiElements.push(
          <Form.Field key={el.notification_element_id} className="notification-checkbox-toggle single dynamic">
            <label>
              <Label className="circular-icon" circular>
                {getElementIcon(el.notification_element_id)}
              </Label>
              <p>{t(el.field.name).message || el.field.name}</p>
            </label>
            <Checkbox
              toggle
              checked={notificationElementIds.includes(el.notification_element_id)}
              onChange={() => handleChangeNotificationsCheckbox(el.notification_element_id)}
            />
          </Form.Field>
        )
      );
      fields.push(
        <Grid.Column width={Number(entityId) === 1 ? 5 : 10}>
          <div className="DesktopNotifications" key={entityId}>
            <h4>{t(entity.title).message || entity.title}</h4>
            <Form.Group grouped>{uiElements}</Form.Group>
          </div>
        </Grid.Column>
      );
    });

    return fields;
  };

  const handleSaveAccountDetails = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const userData: Partial<User> & { notification_element_ids: number[] } = {
      ...user,
      phone: phone,
      mobile: mobile,
      shop_status_same_day_filter_enabled: shopStatusSameDayFilterEnabled,
      shop_status_next_day_filter_enabled: shopStatusNextDayFilterEnabled,
      shop_status_to_order_filter_enabled: shopStatusToOrderFilterEnabled,
      shop_status_backorder_filter_enabled: shopStatusBackorderFilterEnabled,
      is_counter_tablet_user: useCounterTabetUser,
      landing_page_id: landingPageId,
      dealer_location_id: dealerLocationId,
      list_appointments_by_scheduled_range: listAppointmentsByScheduleRange,
      profile_picture: profilePicture,
      notification_element_ids: notificationElementIds,
      pin_status_notification_enabled: pinStatusNotificationEnabled,
      my_locations: selectedLocations,
      location_column_visible: isLocationColumnVisible
    };
    updateUserAccountMutation.mutateAsync(userData).then(() => {
      toast.success(t("v8_updated").message || "Updated");
    });
  };

  const handleGoBack = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    history.goBack();
  };

  const handleSelectLocation = (id: number) => {
    setSelectedLocations(curr => (curr.includes(id) ? curr.filter(e => e !== id) : [...curr, id]));
  };

  const appointmentListOptions = [
    {
      key: "time_car_app",
      text: t("v8_appointment_date").message || "Appointment date",
      value: false
    },
    {
      key: "scheduled_in_out",
      text: t("v8_scheduled_in_out").message || "Scheduled in/out date",
      value: true
    }
  ];

  const landingPageOptions = landingPagesList?.map(item => {
    return {
      key: item.id,
      value: item.id,
      text: t(item?.name).message || item?.name
    };
  });

  return (
    <div>
      <div className="AccountSetting">
        <div className="setting_section">
          <div className="personal_setting">
            <span>
              <UploadProfile
                formId="user-profile"
                path="/files/upload_profile_picture"
                locationPath={`${selectedDealer?.id}_${selectedDealer?.name}/${moment(user?.created_on).format("YYYY/MM/DD")}/${user?.id}_${user?.last_name}`}
                user={user}
                onSuccess={(url: string) => setProfilePicture(url)}
              />
            </span>
            <h1>{t("v8_my_account").message || "My account"}</h1>
          </div>
          <h2>{t("v8_personal").message || "Personal"}</h2>
          <Grid columns={3}>
            <GridColumn>
              <Form.Field>
                <label className="input-label">{t("v8_first_name").message || "First name"}</label>
                <Input name="firstName" value={user?.first_name} disabled={true} fluid placeholder={t("v8_first_name").message || "First name"} />
              </Form.Field>
            </GridColumn>
            <GridColumn>
              <Form.Field>
                <label className="input-label">{t("v8_last_name").message || "Last Name"}</label>
                <Input name="lastName" value={user?.last_name} disabled={true} fluid placeholder={t("v8_last_name").message || "Last name"} />
              </Form.Field>
            </GridColumn>
            <GridColumn>
              <Form.Field>
                <label className="input-label">{t("v8_username").message || "Username"}</label>
                <Input name="userName" value={user?.username} disabled={true} fluid placeholder={t("v8_username").message || "Username"} />
              </Form.Field>
            </GridColumn>
          </Grid>
          <Grid columns={3}>
            <GridColumn>
              <Form.Field>
                <label className="input-label">{t("v8_email").message || "Email"}</label>
                <Input name="email" value={user?.email} disabled={true} fluid placeholder={t("v8_email").message || "Email"} />
              </Form.Field>
            </GridColumn>
            <GridColumn>
              <Form.Field>
                <label className="input-label">{t("v8_phone").message || "Phone"}</label>
                <Input name="phone" value={phone} fluid onChange={handleInputChange} placeholder={t("v8_phone").message || "Phone"} />
              </Form.Field>
            </GridColumn>
            <GridColumn>
              <Form.Field>
                <label className="input-label">{t("v8_mobile").message || "Mobile"}</label>
                <Input name="mobile" value={mobile} fluid onChange={handleInputChange} placeholder={t("v8_mobile").message || "Mobile"} />
              </Form.Field>
            </GridColumn>
          </Grid>
          <div className="my_role">
            <h2 className="section-title">{t("v8_my_role").message || "My role"}</h2>
            <Form.Group>
              <Form.Field>
                <span className="user-role">{user?.role_name}</span>
              </Form.Field>

              <Form.Field>
                <label>&nbsp;</label>
                <Button size="medium" color="green" fluid className="change-password" onClick={() => setIsChangePasswordVisible(true)}>
                  {t("v8_change_password").message || "Change Password"}
                </Button>
              </Form.Field>
            </Form.Group>
          </div>
        </div>

        <div className="setting_section equal">
          <h2>{t("v8_settings").message || "Settings"}</h2>
          <Grid columns={2}>
            <GridColumn>
              <Form.Field>
                <label className="input-label">{t("v8_location").message || "Location"}</label>
                <Dropdown
                  selection
                  name="location"
                  noResultsMessage={t("v8_no_locations").message || "No locations"}
                  placeholder={t("v8_select_location").message || "Select a location"}
                  options={locationDropdownOptions}
                  value={dealerLocationId}
                  multiple={false}
                  fluid
                  scrolling
                  search
                  onChange={(_, { name, value }) => handleDropdownChange(name, value)}
                />
              </Form.Field>
            </GridColumn>
            <GridColumn>
              <Form.Field>
                <label className="input-label">&nbsp;</label>
                <Checkbox
                  toggle
                  name="use_counter_tablets"
                  label={t("v8_use_counter_tablets").message || "Use counter tablets"}
                  checked={useCounterTabetUser}
                  onChange={(_, { name, checked }) => handleCheckboxChange(name, checked)}
                />
              </Form.Field>
            </GridColumn>
          </Grid>
          <Grid columns={2}>
            <GridColumn>
              <Form.Field>
                <label className="input-label">{t("v8_landing_page").message || "Landing page"}</label>
                <Dropdown
                  selection
                  fluid
                  name="landing_page_id"
                  value={landingPageId}
                  options={landingPageOptions}
                  placeholder="appointments-page"
                  onChange={(_, { name, value }) => handleDropdownChange(name, value)}
                />
              </Form.Field>
            </GridColumn>
            <GridColumn>
              <Form.Field>
                <label className="input-label">{t("v8_list_appointments_by").message || "List appointments by"}</label>
                <Dropdown
                  fluid
                  search
                  floating
                  selection
                  value={listAppointmentsByScheduleRange}
                  name="list_appointments_by_scheduled_range"
                  options={appointmentListOptions}
                  placeholder={t("v8_appointment_date").message || "Appointment date"}
                  onChange={(_, { name, value }) => handleDropdownChange(name, value)}
                />
              </Form.Field>
            </GridColumn>
          </Grid>
        </div>

        {isLocationsSectionVisible && (
          <div className="setting_section equal location">
            <h2>{t("v8_locations").message || "Locations"}</h2>

            <div className="location-visibility-wrapper">
              <span className="input-label">{t("v8_locations_column_visible").message || "Locations column visible"}</span>
              <Checkbox toggle name="is_location_visible" checked={isLocationColumnVisible} onChange={(_, { checked, name }) => handleCheckboxChange(name, checked)} />
            </div>
            <div className="selected-locations-container">
              {userLocations.map(location => (
                <div key={location.id}>
                  <Checkbox checked={selectedLocations.some(item => item === location.id)} onChange={() => handleSelectLocation(location.id)} />
                  <span>{location.name}</span>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="setting_section equal role_order">
          <div>
            <h3 className="section-title">{t("v8_order_status").message || "Order Status"}</h3>
            <div className="FormSection">
              <Form.Group className="ShoppingCart-container">
                <Form.Field>
                  <Icon className="shopping cart same-day" />
                  <label className="input-label">{t("v8_shopping_cart_same_day").message || "Same Day"}</label>
                  <Checkbox
                    toggle
                    name="shop_status_same_day_filter_enabled"
                    checked={shopStatusSameDayFilterEnabled}
                    onChange={(_, { checked, name }) => handleCheckboxChange(name, checked)}
                  />
                </Form.Field>

                <Form.Field>
                  <Icon className="shopping cart to-order" />
                  <label className="input-label">{t("v8_shopping_cart_to_order").message || "To Order"}</label>
                  <Checkbox
                    toggle
                    name="shop_status_to_order_filter_enabled"
                    checked={shopStatusToOrderFilterEnabled}
                    onChange={(_, { checked, name }) => handleCheckboxChange(name, checked)}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group className="ShoppingCart-container">
                <Form.Field>
                  <Icon className="shopping cart tomorrow" />
                  <label className="input-label">{t("v8_shopping_cart_tomorrow").message || "Tomorrow"}</label>
                  <Checkbox
                    toggle
                    name="shop_status_next_day_filter_enabled"
                    checked={shopStatusNextDayFilterEnabled}
                    onChange={(_, { checked, name }) => handleCheckboxChange(name, checked)}
                  />
                </Form.Field>

                <Form.Field>
                  <Icon className="shopping cart backorder" />
                  <label className="input-label">{t("v8_shopping_cart_backorder").message || "Backorder"}</label>
                  <Checkbox
                    toggle
                    name="shop_status_backorder_filter_enabled"
                    checked={shopStatusBackorderFilterEnabled}
                    onChange={(_, { checked, name }) => handleCheckboxChange(name, checked)}
                  />
                </Form.Field>
              </Form.Group>
            </div>
          </div>
        </div>

        <div className="setting_section equal">
          <h3 className="section-title">{t("v8_notitication").message || "Notifications asd"}</h3>
          <p className="section-subtitle ">
            {t("v8_desktop_notification_explanation").message ||
              "You will get a notification in Claire or on your desktop when one of the following actions is triggered"}
          </p>
          <h3 className="section-title mt-20">{t("v8_claire_notification").message || "Claire Notification"}</h3>
          <div className="PinNotifications single">
            <Form.Field className="notification-checkbox-toggle">
              <label>
                <Label className="circular-icon" circular>
                  <Icon name="pin" />
                </Label>
                <p>{t("v8_pin_status_notifications").message || "Pin status notifications"}</p>
              </label>
              <Checkbox
                toggle
                checked={pinStatusNotificationEnabled}
                name="pin_status_notification_enabled"
                onChange={(_, { name, checked }) => handleCheckboxChange(name, checked)}
              />
            </Form.Field>
          </div>
          <h3 className="section-title mt-20">{t("v8_desktop_notifications").message || "Desktop Notifications"}</h3>
          <Grid>
            <Grid.Row className="DesktopNotifications-row">{getDesktopNotificationsFormFields().map(field => field)}</Grid.Row>
          </Grid>
        </div>
      </div>
      <div className="FixedButtonSection">
        <div className="FixedButtonSection-inner">
          <Button size="medium" basic className="cancel-btn" onClick={handleGoBack}>
            {t("v8_cancel").message || "Cancel"}
            <Icon className="cancel" />
          </Button>

          <Button color="green" className="save-btn" onClick={handleSaveAccountDetails}>
            {t("v8_save").message || "Save"}
            <Icon className="check" />
          </Button>
        </div>
      </div>
      <ChangePasswordModal isOpen={isChangePasswordVisible} handleCancel={() => setIsChangePasswordVisible(false)} user={user} />
    </div>
  );
};

export default AccountSettings;

import moment from "moment";
import { withTranslation } from "react-i18next";
import { GridRow, Icon, Label } from "semantic-ui-react";

import { DropdownData, FileDropdown } from "components";
import { SnoozeAttachment, SnoozeItem } from "models";
import { ITranslation } from "util/interfaces";

type LogUpdatesProps = {
  snoozeItem: SnoozeItem;
  prevSnoozeItem: SnoozeItem;
  snoozeTypeData: DropdownData[];
  snoozeStatusData: DropdownData[];
  snoozeDepartmentData: DropdownData[];
  t: ITranslation;
};

const LogUpdates = ({ snoozeItem, prevSnoozeItem, snoozeTypeData, snoozeStatusData, snoozeDepartmentData, t }: LogUpdatesProps) => {
  if (prevSnoozeItem) {
    const attachments = snoozeItem.note_attachments?.map(url => {
      return { url } as SnoozeAttachment;
    });

    return (
      <GridRow className="log_values">
        {!!snoozeItem.snooze_type_id && snoozeItem.snooze_type_id !== prevSnoozeItem.snooze_type_id && (
          <Label>
            <Icon name="time" />
            {snoozeTypeData.find(type => type.key === snoozeItem.snooze_type_id)?.text}
          </Label>
        )}
        {!!snoozeItem.snooze_status_id && snoozeItem.snooze_status_id !== prevSnoozeItem.snooze_status_id && (
          <Label>
            <Icon name="list alternate" />
            {snoozeStatusData.find(type => type.key === snoozeItem.snooze_status_id)?.text}
          </Label>
        )}
        {!!snoozeItem.snooze_date && snoozeItem.snooze_date !== prevSnoozeItem.snooze_date && (
          <Label>
            <Icon name="calendar alternate outline" />
            {moment(snoozeItem.snooze_date).format("DD-MM-YYYY")}
          </Label>
        )}
        {snoozeItem.snooze_department_id !== prevSnoozeItem.snooze_department_id && (
          <Label>
            <Icon name="building" />
            {!snoozeItem.snooze_department_id
              ? t("v8_department_removed").message || "Department removed"
              : snoozeDepartmentData.find(type => type.key === snoozeItem.snooze_department_id)?.text}
          </Label>
        )}
        <FileDropdown attachments={attachments} iconColor="#000000" />
      </GridRow>
    );
  }
  return null;
};

export default withTranslation()(LogUpdates);

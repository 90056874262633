import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionModalSelectorData, DetailsCards, ExtendedInterventionData, MODAL_TYPE, QuestionResultData, TabData, useCan } from "components";
import InterventionModal from "components/InterventionModal";
import { Appointment, CheckInRemark, DiagnoseOverviewRemark, KeylockerRemark } from "models";
import { CheckInOptionalService, CommunicationRemarks } from "modules/AppointmentDetails/components/CustomerActions/components";
import { useCommunicationRemarkMutation } from "modules/AppointmentDetails/components/CustomerActions/components/CommunicationRemarks/useCommunicationRemarkMutation";
import "modules/AppointmentDetails/components/CustomerActions/CustomerActions.scss";
import { InterventionData } from "modules/AppointmentDetails/hooks";
import { ITranslation } from "util/interfaces";

type CustomerActionsProps = {
  isExpanded: boolean;
  appointment: Appointment;
  tabData: TabData;
};

export const CustomerActions = ({ isExpanded, appointment, tabData }: CustomerActionsProps) => {
  const [modalData, setModalData] = useState<InterventionData | QuestionResultData | null>(null);

  const t = useTranslation().t as ITranslation;
  const canCreateIntervention = useCan("add", "interventions");

  const keylockerRemarks = appointment.keylocker_communications?.map(kc => kc.remark).filter(r => !!r) ?? [];
  const { checkInRemarkMutation, diagnoseOverviewRemarkMutation, keylockerRemarkMutation } = useCommunicationRemarkMutation(appointment.id);

  const onRequestCloseModal = () => {
    setModalData(null);
  };

  const handleSelect = (data: ActionModalSelectorData) => {
    if (data.modalType === MODAL_TYPE.Intervention) {
      setModalData(data as ExtendedInterventionData);
    }
  };

  const handleToggleCheckinRemark = (remark: CheckInRemark) => {
    checkInRemarkMutation.mutate({ checkin_remark_id: remark.id, handled: !remark.receptionist_handled });
  };

  const handleToggleDiagnoseRemark = (remark: DiagnoseOverviewRemark) => {
    diagnoseOverviewRemarkMutation.mutate({ diagnose_overview_remark_id: remark.id, handled_remark: !remark.receptionist_handled });
  };

  const handleToggleKeylockerRemark = (remark: KeylockerRemark) => {
    keylockerRemarkMutation(remark.id).mutate({ keylocker_remark_id: remark.id, handled_remark: !remark.receptionist_handled });
  };

  return (
    <>
      <DetailsCards
        id={tabData.id}
        title={`${t("v8_customer_actions").message || "Customer Actions"} ${tabData.data.length > 0 ? `(${tabData.data.length})` : ""}`}
        icon="calendar check regular"
        isExpanded={isExpanded}
      >
        <div className="CustomerActions">
          {appointment.customer_communication?.check_in_results && (
            <CheckInOptionalService
              appointment={appointment}
              title={t("v8_check_in_results").message || "Check-in results"}
              results={appointment.customer_communication.check_in_results}
              onSelect={handleSelect}
              canCreateIntervention={canCreateIntervention}
            />
          )}
          <div className="CCRemarks">
            {appointment.customer_communication?.check_in_remarks && (
              <CommunicationRemarks
                appointment={appointment}
                title={t("v8_check_in_remarks").message || "Check-in remarks"}
                remarks={appointment.customer_communication.check_in_remarks}
                onSelect={handleSelect}
                canCreateIntervention={canCreateIntervention}
                onToggleHandled={remark => handleToggleCheckinRemark(remark)}
              />
            )}
            {appointment.customer_communication?.diagnose_overview_remarks && (
              <CommunicationRemarks
                appointment={appointment}
                title={t("v8_diagnose_overview_remarks").message || "Diagnose overview remarks"}
                remarks={appointment.customer_communication.diagnose_overview_remarks}
                onSelect={handleSelect}
                canCreateIntervention={canCreateIntervention}
                onToggleHandled={remark => handleToggleDiagnoseRemark(remark)}
              />
            )}
            <CommunicationRemarks
              appointment={appointment}
              title={t("v8_keylocker_remarks").message || "Keylocker remarks"}
              remarks={keylockerRemarks}
              onSelect={handleSelect}
              canCreateIntervention={false}
              isKeyLocker
              onToggleHandled={remark => handleToggleKeylockerRemark(remark)}
            />
          </div>
        </div>
      </DetailsCards>

      <InterventionModal size="large" open={!!modalData} onClose={onRequestCloseModal} closeOnDimmerClick data={modalData} />
    </>
  );
};

import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { Icon, Menu, Sidebar } from "semantic-ui-react";

import { Can } from "components";
import "components/MenuView/MenuView.scss";
import { PATHS } from "router/paths";

interface MenuViewProps {
  children: React.ReactNode;
  isSideBarOpen: boolean;
  hideSidebar: () => void;
}

interface MenuViewSectionProps {
  title?: string;
  condition?: boolean;
  children: ReactNode;
  isExpandedByDefault?: boolean;
}

interface MenuItemProps {
  title: string;
  icon: string;
  link: PATHS;
  resource: string | string[];
  activity: string | string[];
  condition?: boolean;
}

export const MenuItem = ({ title, icon, link, resource, activity, condition }: MenuItemProps) => {
  if (condition === false) return;

  return (
    <Can I={activity} the={resource} key={link}>
      <Link to={link}>
        <Menu.Item className="menu-view-item">
          {<Icon className={icon} />}
          {title}
        </Menu.Item>
      </Link>
    </Can>
  );
};

export const MenuView = ({ children, isSideBarOpen, hideSidebar }: MenuViewProps) => {
  const handleSidebarClick = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement | null;
    if (target?.closest("a") && !target.closest("a")?.classList.contains("heading")) hideSidebar();
  };

  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      vertical
      visible={isSideBarOpen}
      onHide={hideSidebar}
      onClick={handleSidebarClick}
      width="thin"
      className="MenuView"
    >
      {children}
    </Sidebar>
  );
};

export const MenuSection = ({ title, condition, children, isExpandedByDefault = true }: MenuViewSectionProps) => {
  const [showItems, setShowItems] = useState(isExpandedByDefault);
  const handleChangeShowItems = () => setShowItems(showItems => !showItems);

  if (condition === false) return;

  return (
    <>
      {title && (
        <Menu.Item onClick={handleChangeShowItems} className="menu-view-item heading">
          {title}
          <Icon name={`chevron ${showItems ? "right" : "down"}`} />
        </Menu.Item>
      )}
      {showItems && children}
    </>
  );
};

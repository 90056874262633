import ENV from "config/Env";
import ApiInstance from "util/Api";

const getChecklistReportUrl = async (check_id: number) => {
  return ApiInstance.post("/checks/report/url", { check_id }, ENV.appointmentBaseURL);
};

const getChecklistReport = async (check_id: number) => {
  return ApiInstance.post(`/checks/report/download`, { check_id }, ENV.appointmentBaseURL, { responseType: "blob" });
};

export { getChecklistReport, getChecklistReportUrl };

import { saveAs } from "file-saver";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Modal } from "semantic-ui-react";

import "components/FilePreview/FilePreview.scss";
import { AppointmentNoteAttachment, SnoozeAttachment } from "models";
import { getExtensionFromURL, getNameFromURL, getPreviewUrl } from "util/common";
import { ITranslation } from "util/interfaces";

enum KEY_CODES {
  LeftArrow = 37,
  RightArrow = 39
}

type FilePreviewProps = {
  attachments?: (AppointmentNoteAttachment | SnoozeAttachment)[];
  open: boolean;
  onClose?: () => void;
  currentIndex: number;
};

export const FilePreview = ({ attachments, open, onClose, currentIndex }: FilePreviewProps) => {
  if (!attachments?.length) return null;

  const t = useTranslation().t as ITranslation;
  const filePreviewContentRef = useRef<HTMLDivElement>(null);
  const [previewModalAttachmentIndex, setPreviewModalAttachmentIndex] = useState(currentIndex);

  useEffect(() => {
    setPreviewModalAttachmentIndex(currentIndex);
  }, [currentIndex]);

  const selectedFile = attachments[previewModalAttachmentIndex];
  const type = "type" in selectedFile ? selectedFile.type : getExtensionFromURL(selectedFile.url);
  const name = "name" in selectedFile ? selectedFile.name : getNameFromURL(selectedFile.url);

  const isFileImage =
    [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/webp",
      "image/gif",
      "image/bmp",
      "image/svg",
      "image/tiff",
      "image/heif",
      "png",
      "jpeg",
      "jpg",
      "webp",
      "gif",
      "bmp",
      "svg",
      "tiff",
      "heif"
    ].includes(type) || /\.(png|jpeg|jpg|webp|gif|bmp|svg|tiff|heif)(\?.*)?$/.test(name || selectedFile.url);
  const shouldRenderIFrame = [
    "text/html",
    "text/css",
    "application/javascript",
    "text/plain",
    "application/xml",
    "application/pdf",
    "video/mp4",
    "video/webm",
    "video/ogg",
    "audio/mpeg",
    "audio/wav",
    "audio/ogg",
    "application/rss+xml",
    "text/csv",
    "pdf",
    "mp4",
    "webm",
    "ogg",
    "wav",
    "csv"
  ].includes(type);

  const handleClosePreviewModal = () => {
    onClose?.();
  };

  const handleShowPreviewModal = (n: number) => {
    setPreviewModalAttachmentIndex(n);
  };

  const handlePreviewUsingKeyboard = (evt: { stopPropagation: () => void; keyCode: number }) => {
    evt.stopPropagation();
    const keyCode = evt.keyCode;

    if (![KEY_CODES.LeftArrow, KEY_CODES.RightArrow].includes(keyCode)) return;

    const previewModalAttachmentIndex = attachments.findIndex(f => f.url === selectedFile.url);

    if (keyCode === KEY_CODES.LeftArrow && previewModalAttachmentIndex > 0) handleShowPreviewModal(previewModalAttachmentIndex - 1);
    else if (keyCode === KEY_CODES.RightArrow && previewModalAttachmentIndex < attachments.length - 1) handleShowPreviewModal(previewModalAttachmentIndex + 1);
  };

  useEffect(() => {
    filePreviewContentRef.current?.focus();
  }, [filePreviewContentRef.current]);

  return (
    <Modal open={open} closeOnDimmerClick={false} className="FilePreviewModal" centered tabIndex="0" onKeyDown={handlePreviewUsingKeyboard} openOnTriggerFocus>
      {attachments.length > 1 && (
        <>
          {previewModalAttachmentIndex > 0 && <Icon className="chevron left left-arrow" onClick={() => handleShowPreviewModal(previewModalAttachmentIndex - 1)} />}
          {previewModalAttachmentIndex < attachments.length - 1 && (
            <Icon className="chevron right right-arrow" onClick={() => handleShowPreviewModal(previewModalAttachmentIndex + 1)} />
          )}
        </>
      )}

      <Modal.Header>
        <div className="FilePreviewHeader">
          <div>
            <div className="FilePreviewTitle">{t("v8_file_preview").message || "File preview"}</div>
            <div className="FilePreviewName">{name}</div>
          </div>

          <div className="Actions">
            <Button color="teal" onClick={() => saveAs(selectedFile.url, name)}>
              {t("v8_download").message || "Download"}
            </Button>

            <Button color="green" onClick={handleClosePreviewModal}>
              {t("v8_close").message || "Close"}
            </Button>
          </div>
        </div>
      </Modal.Header>

      <Modal.Content>
        <div className="FilePreviewContent" tabIndex={0} ref={filePreviewContentRef}>
          {isFileImage ? (
            <img src={selectedFile.url} alt="preview" />
          ) : shouldRenderIFrame ? (
            <iframe src={getPreviewUrl(selectedFile.url)} title={selectedFile.url} width="100%" height="100%" />
          ) : (
            <h2>{t("v8_file_format_is_not_supported_for_preview").message || "File format is not supported for preview"}</h2>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

import ENV from "config/Env";
import { UPDATE_TYPE, backendQueryKey, localQueryKey } from "util/keyFactory";

const component = "AppointmentDetails";

export const AppointmentDetailsKeys = {
  view: (id: number) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.WebSocket,
      component,
      baseUrl: ENV.apiBase,
      endpoint: "/appointments/view",
      params: { id, embed: "appointment_status,customer_owner,customer_driver,car,customer_contract,customcomv2,pin,snooze,deskcom,keycom,kioskcom" }
    })
  ],
  checks: (appointment_id: number) => [
    backendQueryKey({ updateType: UPDATE_TYPE.WebSocket, component, baseUrl: ENV.apiBase, endpoint: "/checks/list", params: { appointment_id, embed: "pin,snooze" } })
  ],
  cancellationReasons: [localQueryKey({ component, data: "cancellationReasons" })]
};

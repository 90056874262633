import { useQueryClient } from "@tanstack/react-query";

import { WebSocketMessageListener } from "components";
import ENV from "config/Env";
import { useRealTimeQuery } from "hooks";
import { SnoozeItem } from "models";
import ApiInstance from "util/Api";
import { queryKeys } from "util/keyFactory";

export const useSnoozeItems = (car_id: number) => {
  const queryClient = useQueryClient();

  const carSnoozeItemsQueryKey = [
    "realtime",
    {
      ...queryKeys.car.snoozeItems,
      car_id
    }
  ];

  const listeners: WebSocketMessageListener[] = [
    {
      model: "snooze",
      action: "delete",
      filter: {
        car_id
      },
      callback: message => {
        const snoozeItems: SnoozeItem[] | undefined = queryClient.getQueryData(carSnoozeItemsQueryKey);

        if (!snoozeItems?.length) {
          return;
        }

        const updatedSnoozeItems = snoozeItems.filter(item => item.question_result_id !== (message.data as SnoozeItem).question_result_id);

        queryClient.setQueryData(carSnoozeItemsQueryKey, updatedSnoozeItems);
      }
    },
    {
      model: "snooze",
      action: "append",
      filter: {
        car_id
      },
      callback: message => {
        const snoozeItems: SnoozeItem[] | undefined = queryClient.getQueryData(carSnoozeItemsQueryKey);

        const updatedSnoozeItems = snoozeItems ? [...snoozeItems, message.data] : [message.data];

        queryClient.setQueryData(carSnoozeItemsQueryKey, updatedSnoozeItems);
      }
    }
  ];

  const query = useRealTimeQuery({
    queryKey: carSnoozeItemsQueryKey,
    queryFn: () => ApiInstance.post("/snoozes/list_by_car_id", { car_id: Number(car_id) }, ENV.appointmentBaseURL),
    listeners
  });
  return { data: query.data?.data?.items, loading: query.isFetching, error: query.error };
};

import moment from "moment";
import "moment-duration-format";

import { COLOR_THRESHOLDS } from "modules/DealerReports/util/parser";

export const isDurationField = (key: string) => /(duration|time)/.test(key.toLowerCase());

export const isPercentageField = (key: string) => /(percent|percentage)/.test(key.toLowerCase());

export const getColor = (value: number, type: keyof typeof COLOR_THRESHOLDS): string => {
  const threshold = COLOR_THRESHOLDS[type].find(t => value >= t.min && value <= t.max);
  return threshold?.color || "";
};

export const formatDuration = (seconds: number): string => {
  return moment.duration(seconds, "seconds").format("HH:mm:ss", { trim: false });
};

export const calculatePercentage = (value: number, total: number): number => {
  return total === 0 ? 0 : Math.round((value / total) * 100 * 100) / 100;
};

export const formatValueWithPercentage = (value: number, percentage: number): string => {
  return `${value || 0} (${percentage || 0}%)`;
};

export const getNumericValue = (field: any): number => {
  if (typeof field === "number") return field;
  if (typeof field === "object" && field?.value !== undefined) return field.value;
  if (typeof field === "string" && !isNaN(Number(field))) return Number(field);
  return 0;
};

import { QueryObserverSuccessResult } from "@tanstack/react-query";
import { Button, Icon } from "semantic-ui-react";

import { DetailsCards } from "components";
import { DealerQualityChecksTable, DealerQualityChecksTabularFilters } from "modules/DealerReports/components/DealerQualityChecksTabular/components";
import "modules/DealerReports/components/DealerQualityChecksTabular/DealerQualityChecksTabular.scss";
import { useDealerQualityChecksTabular } from "modules/DealerReports/components/DealerQualityChecksTabular/hooks";
import { ParsedDealerReport, ParsedReportData } from "modules/DealerReports/util/types";

export type QualityCheckReportData = {
  wo_nr: string;
  wo_date: string;
  location: string;
  mechanic: string;
  qc_status: number;
  check_date: string;
  qc_officer: string;
  mechanic_id: number;
  qc_checklist: string;
  qc_officer_id: number;
  advised_remark: string;
  appointment_id: number;
  critical_remark: string;
  ok_with_note_remark: string;
};

type Props = {
  id: string;
  title: string | undefined;
  isExpanded: boolean;
  report: QueryObserverSuccessResult<ParsedDealerReport, Error>;
  handleExportData: (reportName: string, reportData: ParsedReportData[]) => void;
};

export const DealerQualityChecksTabular = ({ handleExportData, id, isExpanded, report, title }: Props) => {
  const { filteredData, mechanics, locations, qcOfficers, qcStatuses, setMechanics, setLocations, setQcOfficers, setQcStatuses } = useDealerQualityChecksTabular({
    data: report.data.report_data as unknown as QualityCheckReportData[]
  });

  return (
    <div className="dealer-quality-checks-tabular">
      <DetailsCards
        id={id}
        title={title}
        isExpanded={isExpanded}
        rightContent={
          <div className="details-card-container">
            <DealerQualityChecksTabularFilters
              mechanics={mechanics}
              locations={locations}
              qcOfficers={qcOfficers}
              qcStatuses={qcStatuses}
              reportData={report.data.report_data as unknown as QualityCheckReportData[]}
              handleLocationsFilter={setLocations}
              handleMechanicsFilter={setMechanics}
              handleQcOfficersFilter={setQcOfficers}
              handleQcStatusesFilter={setQcStatuses}
            />
            <div className="export-button">
              <Button icon color="green" basic onClick={() => handleExportData(report.data.report_name, report.data.report_data)}>
                <Icon className="file export" />
              </Button>
            </div>
          </div>
        }
      >
        <DealerQualityChecksTable reportData={filteredData} />
      </DetailsCards>
    </div>
  );
};

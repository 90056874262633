import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { ColorKeys } from "components";
import { useUser } from "hooks";
import { IndicatorFilters } from "modules/Appointments/components";
import { queryKeys } from "util/keyFactory";

interface SpecialIndicatorQueryTypes {
  is_star?: string;
  is_money?: string;
  is_shop?: string;
  is_shop_green?: string;
  is_shop_orange?: string;
  is_shop_blue?: string;
  is_recurring?: string;
  is_count?: string;
  is_shop_red?: string;
  pin_count?: string;
}

export const useGetSpecialIndicatorsInitialState = () => {
  const queryClient = useQueryClient();
  const user = useUser();
  const specialIndicatorsQueryData: SpecialIndicatorQueryTypes = queryClient.getQueryData([{ ...queryKeys.appointments.specialIndicators }])!;

  const defaultCartVisible = !(
    user?.shop_status_same_day_filter_enabled ||
    user?.shop_status_next_day_filter_enabled ||
    user?.shop_status_to_order_filter_enabled ||
    user?.shop_status_backorder_filter_enabled
  );

  const specialIndicators: IndicatorFilters[] = [
    { icon: "is_star", active: !!specialIndicatorsQueryData?.is_star, filter: "is_star" },
    { icon: "is_money", active: !!specialIndicatorsQueryData?.is_money, filter: "is_money" },
    ...(defaultCartVisible ? [{ icon: "is_shop" as ColorKeys, active: !!specialIndicatorsQueryData?.is_shop, filter: "is_shop" }] : []),
    ...(user?.shop_status_same_day_filter_enabled
      ? [
          {
            icon: "is_shop" as ColorKeys,
            active: !!specialIndicatorsQueryData?.is_shop_green,
            filter: "is_shop_green",
            borderColor: "green"
          }
        ]
      : []),
    ...(user?.shop_status_next_day_filter_enabled
      ? [{ icon: "is_shop" as ColorKeys, active: !!specialIndicatorsQueryData?.is_shop_orange, filter: "is_shop_orange", borderColor: "orange" }]
      : []),
    ...(user?.shop_status_to_order_filter_enabled
      ? [{ icon: "is_shop" as ColorKeys, active: !!specialIndicatorsQueryData?.is_shop_blue, filter: "is_shop_blue", borderColor: "blue" }]
      : []),
    ...(user?.shop_status_backorder_filter_enabled
      ? [{ icon: "is_shop" as ColorKeys, active: !!specialIndicatorsQueryData?.is_shop_red, filter: "is_shop_red", borderColor: "red" }]
      : []),
    { icon: "pin", active: !!specialIndicatorsQueryData?.pin_count, filter: "pin_count" },
    { icon: "triangle exclamation", active: !!specialIndicatorsQueryData?.is_recurring, filter: "is_recurring" }
  ];
  const [indicatorFilters, setIndicatorFilters] = useState<IndicatorFilters[]>(specialIndicators);

  useEffect(() => {
    const indicatorFiltersObj: Record<string, string> = {};
    indicatorFilters.forEach(item => {
      if (item.active && item.filter) {
        indicatorFiltersObj[item.filter] = item.filter;
      }
    });
    if (!Object.keys(indicatorFiltersObj).length && Object.keys(specialIndicatorsQueryData || {}).length === 0) return;
    queryClient.setQueryData([queryKeys.appointments.specialIndicators], indicatorFiltersObj);
  }, [indicatorFilters]);

  const handleIndicatorFilters = (index: number) => {
    setIndicatorFilters(current => current.map((filter, idx) => (idx === index ? { ...filter, active: !filter.active } : filter)));
  };
  return { indicatorFilters, handleIndicatorFilters };
};

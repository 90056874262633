import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useRef } from "react";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import ApiInstance from "util/Api";

type SearchDropdownProp = {
  onSelect: (id: string) => void;
  onClose: () => void;
};

const useSearchDropdown = ({ onClose, onSelect }: SearchDropdownProp) => {
  const { selectedLocation, isKeyLoopLocation } = useDealersLocations();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const importBaseUrl = isKeyLoopLocation ? ENV.keyloopBaseURL : ENV.nextLaneBaseUrl;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRef.current?.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { mutate: importAppointment, isPending } = useMutation({
    mutationKey: ["appointmentImportMutation"],
    mutationFn: async (id: string) => {
      const response = await ApiInstance.post("/appointments/import", { dealer_location_id: selectedLocation?.id, dms_nr: id }, importBaseUrl);
      return response;
    },
    onSuccess: response => {
      onSelect(response.data.appointment_id);
    }
  });

  return { dropdownRef, importAppointment, isPending };
};

export default useSearchDropdown;

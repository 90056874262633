import { withTranslation } from "react-i18next";

import { AnswerStatusSelector } from "components";
import { useTyreName } from "hooks";
import { Appointment, Intervention } from "models";
import { QuestionResult } from "models/QuestionResult";
import { ImageLetters } from "modules/AppointmentDetails/components/Interventions/components/PinOverview/components";
import "modules/AppointmentDetails/components/Interventions/components/PinOverview/components/ChecklistSection/ChecklistSection.scss";
import { isInStorage, isOnCar } from "modules/AppointmentDetails/components/Interventions/components/PinOverview/components/ChecklistSection/components/useTyreSection";
import useChecklistSection from "modules/AppointmentDetails/components/Interventions/components/PinOverview/components/ChecklistSection/useChecklistSection";
import { ITranslation } from "util/interfaces";

type Props = {
  appointment: Appointment | null;
  intervention: Intervention;
  item: QuestionResult;
  checklistId: number;
  t: ITranslation;
};

const ChecklistSection = ({ appointment, intervention, item, checklistId, t }: Props) => {
  const { questionImages } = useChecklistSection({ appointment, intervention });
  const { getTyreName } = useTyreName();

  return (
    <div className="checklist-item" key={item.id}>
      <div>
        <div className="checklist-item-text-image-letter">
          <ImageLetters images={questionImages} questionItemId={item.id} checklistId={checklistId} />
          <div className="checklist-item-title">
            {item.tyre_position && (
              <>
                {isOnCar(item.tyre_position) && (t("v8_on_car").message || "On car") + " "}
                {isInStorage(item.tyre_position) && (t("v8_in_storage").message || "In storage") + " "}
                {getTyreName(item.tyre_position)}
              </>
            )}
          </div>
        </div>
        <p className="checklist-item-description">
          {item.tyre?.description && <div>{item.tyre.description}</div>}
          {item.mechanic_notes && <div>{item.mechanic_notes.trim()}</div>}
          {item.tyre_replacements &&
            item.tyre_replacements.map((tr, tri) => (
              <div key={tr.tyre_id + "_" + tri}>
                {t("v8_replace_with").message || "Replace with"}: {tr.tyre?.description}
              </div>
            ))}
        </p>
      </div>
      <AnswerStatusSelector data={item} disabled={true} />
    </div>
  );
};

export default withTranslation()(ChecklistSection);

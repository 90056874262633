import { useDroppable } from "@dnd-kit/core";
import { ReactElement } from "react";

export const UnassignedColumn = (props: { children: ReactElement[] }) => {
  const { setNodeRef } = useDroppable({ id: "unassigned-column", data: { unassigned: true, mechanicId: null } });

  return (
    <div ref={setNodeRef} className="Dayplanner-columnContainer">
      {props.children}
    </div>
  );
};

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import ApiInstance from "util/Api";

export const useDetachKioskLabelMutation = (appointment_id: number) => {
  const queryClient = useQueryClient();

  const detachKioskMutationFn = async () => {
    await ApiInstance.post("/dashboard/labels/detach", { appointment_id }, ENV.kiosk);
  };

  return useMutation({
    mutationFn: detachKioskMutationFn,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: AppointmentDetailsKeys.view(appointment_id) }),
    onError: e => toast.error(e.message)
  });
};
